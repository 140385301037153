<div class="d-flex justify-space-between" style="padding-top: 1em" >
    <div class="col-1">
        <a class="navbar-brand"
           routerLink="/"
           (click)="isCollapsed = true">
            <div class="logo"></div>
        </a>
    </div>
    <div class="col-1">
        <nav class="navbar navbar-light" (click)="$event.stopPropagation()">
            <div class="container-fluid" style="position: fixed">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03"
                        aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu($event)">
                    <span class="bi bi-list"></span>
                </button>
                <div class="collapse navbar-collapse p-3 shadow bg-white rounded" id="navbarColor03" [collapse]=isCollapsed>
                    <ul class="navbar-nav">
                        <a class="nav-link-titre" aria-haspopup="true" aria- expanded="true">
                            <i class="fa fa-cog"></i>
                            <i class="bi bi-people-fill"></i>
                            Equipe
                        </a>
                        <li class="nav-item active">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                                [routerLinkActiveOptions]="{exact: true}"
                                aria-current="page"
                                class="nav-link nav-link-entree"
                                routerLink="/mon-equipe"
                                routerLinkActive="active"
                                (click)="isCollapsed = true">
                                Ajouter un collaborateur dans mon équipe
                            </a>
                        </li>
                        <li class="nav-item">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/mon-equipe/equipe-globale"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                               Visualiser mon équipe globale
                            </a>
                        </li>
                        <li *ngIf="canMajManager" role="presentation" class="dropdown-item">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/managers/update"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Mettre à jour le manager (à la place de)
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="hasManager || canMajManager " >
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/valideurs/update"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Mettre à jour les valideurs
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="hasManager || canMajManager ">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/valideurs/consultation"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Visualiser les valideurs
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <a class="nav-link-titre" aria-haspopup="true" aria- expanded="true">
                            <i class="fa fa-cog"></i>
                            <i class="bi bi-people-fill"></i>
                            Organigramme</a>
                        <li class="nav-item">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/organigramme"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Visualiser organigramme
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="canExportDelegations">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/organigramme/exporter-delegations"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Exporter les délégations
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <a class="nav-link-titre" aria-haspopup="true" aria- expanded="true">
                            <i class="fa fa-cog"></i>
                            <i class="bi bi-people-fill"></i>
                            Comités</a>
                        <li class="nav-item">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/comites"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Visualiser les comités
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="canMajComite">
                            <a role="menuitem" tabindex="-1" data-toggle="modal" data-target="#myModal"
                               [routerLinkActiveOptions]="{exact: true}"
                               aria-current="page"
                               class="nav-link nav-link-entree"
                               routerLink="/comites/comites-membres"
                               routerLinkActive="active"
                               (click)="isCollapsed = true">
                                Mettre à jour les membres des comités
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <div class="d-flex justify-space-between">
        <div class="header-lien-direct" style="padding-left: 0em">
            <a
                class="nav-link p-2"
                aria-current="page"
                routerLink="/mon-equipe"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="bi bi-people-fill"></i>
                Mon équipe
            </a>
        </div>
        <div class="header-lien-direct">
            <a
                class="nav-link p-2"
                aria-current="page"
                routerLink= "/organigramme"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="bi bi-people-fill"></i>
                Organigramme
            </a>
        </div>
        <div class="header-lien-direct">
            <a
                class="nav-link p-2"
                aria-current="page"
                routerLink="/comites"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="bi bi-people-fill"></i>
                Comités
            </a>
        </div>
        <div class="header-lien-direct" role="button">
            <a
                class="nav-link p-2"
                aria-current="page"
                (click)="navigateToHelp()">
                <i class="bi bi-question-circle"></i>
                Aide
            </a>
        </div>
    </div>
    <div class="header-lien-direct col-2">
        <div class="environnement-texte">{{envtest}}</div>
    </div>
</div>
