<div>
    <ul class="navbar-nav mb-0 navbar-user position-relative">
      <li
        *ngIf="(user$ | async)?.isAuthenticated"
        class="nav-item nav-item-user dropdown"
        dropdown
      >
        <!-- <a  dropdownToggle class="dropdown-toggle"> -->
        <i class="bi bi-person-circle"></i>
          <b>
            {{
            (user$ | async)?.user.prenomCollaborateur.length +
            (user$ | async)?.user.nomCollaborateur.length >
            25
              ? ((user$ | async)?.user.prenomCollaborateur | slice: 0:1) + "."
              : (user$ | async)?.user.prenomCollaborateur
            }}
            {{ (user$ | async)?.user.nomCollaborateur }}
          </b>
        <!-- <span class="caret"></span> -->
        <p
          *ngIf="
            (user$ | async)?.user?.relation &&
              (user$ | async)?.user?.relation.length > 0;
            else elseNoManager
          "
          class="user-info"
        >
          Manager actuel:
          {{
          (user$ | async)?.user?.relation[0]?.managerHierarchique
            ?.prenomManagerHierarchique.length +
          (user$ | async)?.user?.relation[0]?.managerHierarchique
            ?.nomManagerHierarchique.length >
          25
            ? ((user$ | async)?.user?.relation[0]?.managerHierarchique
            ?.prenomManagerHierarchique | slice: 0:1) + "."
            : (user$ | async)?.user?.relation[0]?.managerHierarchique
              ?.prenomManagerHierarchique
          }}
          {{
          (user$ | async)?.user?.relation[0]?.managerHierarchique
            ?.nomManagerHierarchique
          }}
        </p>
        <ng-template #elseNoManager>
          <p class="user-info">Manager actuel: aucun</p>
        </ng-template>
        <p
          *ngIf="(user$ | async)?.user?.entite; else elseNoEntite"
          class="user-info"
        >
          Entité affectation:
          {{ (user$ | async)?.user?.entite?.nomEntiteAffectation }} (code:
          {{ (user$ | async)?.user?.entite?.codeEntiteAffectation }})
        </p>
        <ng-template #elseNoEntite>
          <p class="user-info">Entité affectation: aucune</p>
        </ng-template>
        <ng-template #elseNoManager>
          <p class="user-info">Manager actuel: aucun</p>
        </ng-template>

        <p class="user-info">
          Fonction:
          {{
          (user$ | async)?.user.missionCollaborateur != null
            ? (user$ | async)?.user.missionCollaborateur
            : (user$ | async)?.user?.emploi?.nomEmploi
          }}

        </p>

        <p
          *ngIf="(user$ | async)?.user?.profilLibelleCollaborateur; else elseNoProfil"
          class="user-info"
        >
          Profil:
          {{ (user$ | async)?.user?.profilLibelleCollaborateur }}
        </p>
        <ng-template #elseNoProfil>
          <p class="user-info"></p>
        </ng-template>

        <!-- </a> -->
        <!-- <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem">
            <a class="dropdown-item" href="#"><i class="bi bi-box-arrow-in-right"></i> Déconnexion</a>
          </li>
        </ul> -->
      </li>
      <li>
        <ngx-spinner [name]="idSpinnerHeaderUser" size="medium" type="la-ball-clip-rotate"></ngx-spinner>
      </li>
    </ul>
</div>
