import { Component, OnInit } from '@angular/core'
import { HeaderMenuComponent } from '../header-menu/header-menu.component'
import {HeaderUserComponent} from "../header-user/header-user.component";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [HeaderMenuComponent, HeaderUserComponent],
})
export class HeaderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
