<div class="container">
    <div class="row portailrh-container-global">
        <div class="notauthenticated col-12">
            <div class="d-flex flex-column align-items-center my-5">
                <h1>Utilisateur non autorisé</h1>
                <h2>L'utilisateur n'est pas autorisé à accéder à cette application. Contactez votre administrateur.</h2>
            </div>
            <div class="bouton">
                <button class="btn btn-primary bouton" (click)="logout()">Changer de compte</button>
            </div>
        </div>
    </div>
</div>
