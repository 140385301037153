import {Injectable} from '@angular/core'
import {EntiteAffectationDto} from "src/app/shared/models/entiteAffectationDto";
import {SalarieComiteDto} from "../../../organigramme/data-access/http/dto/SalarieComiteDto";
import {Store} from "../../../shared/models/store";

export interface DeviceInformations {
    deviceInfo?: any
    isMobile?: boolean
    isTablet?: boolean
    isDesktopDevice?: boolean
}

export interface DataImmuableStateModel {
    loading?: boolean
    deviceInformations?: DeviceInformations
    entiteAffectation?: Array<EntiteAffectationDto>
    comite?: Array<SalarieComiteDto>
}

@Injectable({providedIn: 'root'})
export class DataImmuableState {
    store = new Store<DataImmuableStateModel>({
        loading: false,
        entiteAffectation: undefined,
        comite: undefined,
    })

    static readonly getDataImmuableLoading = (state: DataImmuableStateModel) => state.loading || false
    static readonly getEntiteAffectation = (state: DataImmuableStateModel) => state.entiteAffectation
    static readonly getComite = (state: DataImmuableStateModel) => state.comite
    static readonly getDeviceInformations = (state: DataImmuableStateModel) => state.deviceInformations

}
