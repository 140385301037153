import {Injectable} from '@angular/core'
import {IndividualConfig} from 'ngx-toastr'
import {Store} from "../../../models/store";

export interface ToastStateModel {
    toasterOptions: Partial<IndividualConfig>
}

export enum TypeToast {
    success,
    info,
    warning,
    danger,
}

@Injectable({providedIn: 'root'})
export class ToastState {
    store = new Store<ToastStateModel>({
        toasterOptions: {
            positionClass: 'toast-top-center',
            enableHtml: true,
            tapToDismiss: false,
            closeButton: true,
            progressBar: false,
            progressAnimation: 'decreasing',
            disableTimeOut: false,
            timeOut: 5000,
            easing: 'ease-in',
            easeTime: 300,
        },
    })

    static readonly getToasterOptions = (state: ToastStateModel) => state?.toasterOptions

}
