import {enableProdMode, importProvidersFrom} from '@angular/core'

import {environment} from './environments/environment'
import {AppComponent} from './app/app.component'
import {ManagerModule} from './app/manager/manager.module'
import {ComitesModule} from './app/comites/comites.module'
import {OrganigrammeModule} from './app/organigramme/organigramme.module'
import {MonEquipeModule} from './app/mon-equipe/mon-equipe.module'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {OAuthModule} from 'angular-oauth2-oidc'
import {NgxWebstorageModule} from 'ngx-webstorage'
import {PopoverModule} from 'ngx-bootstrap/popover'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import {provideAnimations} from '@angular/platform-browser/animations'
import {NgxSpinnerModule} from 'ngx-spinner'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {ToastModule} from 'src/app/shared/ui/toast/toast.module'
import {routes} from './app/app.routes'
import {CustomModalModule} from './app/shared/ui/custom-modal/custom-modal.module'
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser'
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http'
import {AuthJWTInterceptor} from './app/core/interceptors/auth-jwt.interceptor'
import {ErrorInterceptor} from './app/core/interceptors/error.interceptor'
import {provideRouter, withComponentInputBinding, withInMemoryScrolling} from '@angular/router'

if (environment.production) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(
            routes,
            withComponentInputBinding(),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'})
        ),
        importProvidersFrom(
            BrowserModule,
            CustomModalModule,
            ToastModule,
            NgxDatatableModule,
            NgxSpinnerModule,
            BsDropdownModule.forRoot(),
            CollapseModule.forRoot(),
            TooltipModule.forRoot(),
            PopoverModule.forRoot(),
            NgxWebstorageModule.forRoot(),
            OAuthModule.forRoot({
                resourceServer: {
                    allowedUrls: [environment.baseUrl],
                    sendAccessToken: false,
                },
            }),
            BreadcrumbsModule.forRoot({
                postProcess: null,
                applyDistinctOn: 'text',
            }),
            MonEquipeModule,
            OrganigrammeModule,
            ComitesModule,
            ManagerModule,
        ),
        {provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ],
}).catch((err) => console.error(err))
