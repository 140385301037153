<ng-container *ngIf="affichage === 'comites-membre'">
  <div class="row">
    <div class="col-md-4 col-xs-6 position-relative">
      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="bi bi-search"></i>
        </span>
          </div>
          <input
            (click)="resetComite()"
            (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadNoResults)="noComiteFound($event)"
            [(ngModel)]="comiteLabel"
            [isAnimated]="true"
            [optionsListTemplate]="customListTemplateComite"
            [typeaheadAsync]="true"
            [typeaheadOptionsInScrollableView]="5"
            [typeaheadScrollable]="true"
            [typeaheadWaitMs]="500"
            [typeahead]="comiteFiltered"
            class="form-control"
            placeholder="Nom du comité"
            typeaheadOptionField="codeComite"

          />
          <a
            (click)="cleanSearch()"
            class="btn like-input-button refresh-button"
            container="body"
            containerClass="tooltipClass"
            tooltip="Réinitialiser la recherche"
          >
            <i class="bi bi-arrow-repeat"></i>
          </a>
        </div>

        <div *ngIf="(loading$ | async) === true" class="spinner-select">
          <ngx-spinner
            [fullScreen]="false"
            [name]="idSpinnerSelect"
            size="small"
            type="la-ball-clip-rotate"
          >
          </ngx-spinner>
        </div>

        <ng-template
          #customListTemplateComite
          let-matches="matches"
          let-query="query"
          let-typeaheadTemplateMethods
        >
          <ul class="custom-list-group">
            <li
              (click)="
                typeaheadTemplateMethods.selectMatch(match, $event);
                setComite(match)
              "
              (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
              *ngFor="let match of matches"
              [class.active]="typeaheadTemplateMethods.isActive(match)"
              class="custom-list-group-item"
            >
              {{ match.item.codeComite }}
            </li>
          </ul>
        </ng-template>
        <div *ngIf="comiteFound === false" class="alert alert-danger">
          Aucun résultat trouvé
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="affichage !== 'comites-membre'">
  <div class="row g-0">
    <div class="mb-3 col-7 col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="bi bi-search"></i>
        </span>
        </div>
        <input
          (ngModelChange)="changenomCollaborateur($event)"
          [(ngModel)]="collaborateur"
          class="form-control"
          placeholder="Nom, prénom du collaborateur"
          type="text"
        />
      </div>
    </div>
    <div class="col-5 col-md-4 align-self-center mb-3">
      <a
        (click)="filtersAreCollapsed = !filtersAreCollapsed"
        [attr.aria-expanded]="!filtersAreCollapsed"
        aria-controls="collapseEvent"
        class="btn like-input-button filters-button"
      >
      <span *ngIf="filtersAreCollapsed">
        <span class="d-inline d-md-none"
        ><i class="bi bi-plus"></i><i class="bi bi-funnel"></i
        ></span>
        <span class="d-none d-md-inline">Plus de filtres</span>
      </span>
        <span *ngIf="!filtersAreCollapsed">
        <span class="d-inline d-md-none"
        >&nbsp;- <i class="bi bi-funnel-fill"></i
        ></span>
        <span class="d-none d-md-inline"> Moins de filtres</span>
      </span>
      </a>
      <a
        (click)="cleanSearch()"
        class="btn like-input-button refresh-button"
        container="body"
        containerClass="tooltipClass"
        tooltip="Réinitialiser la recherche"
      >
        <i class="bi bi-arrow-repeat"></i>
      </a>
    </div>
  </div>
  <div [collapse]="filtersAreCollapsed" [isAnimated]="true" id="collapseEvent">
    <div class="row">
      <div class="col-md-4 col-xs-6 position-relative">
        <div class="mb-3">
          <input
            (click)="resetAffectation()"
            (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadNoResults)="noAffectationFound($event)"
            [(ngModel)]="entiteAffectationLabel"
            [isAnimated]="true"
            [optionsListTemplate]="customListTemplateEntite"
            [typeaheadAsync]="true"
            [typeaheadOptionsInScrollableView]="5"
            [typeaheadScrollable]="true"
            [typeaheadWaitMs]="500"
            [typeahead]="entiteAffectationFiltered"
            class="form-control"
            placeholder="Direction / agence / service"
            typeaheadOptionField="nomEntiteAffectation"
          />

          <div *ngIf="(loading$ | async) === true" class="spinner-select">
            <ngx-spinner
              [fullScreen]="false"
              [name]="idSpinnerSelect"
              size="small"
              type="la-ball-clip-rotate"
            >
            </ngx-spinner>
          </div>

          <ng-template
            #customListTemplateEntite
            let-matches="matches"
            let-query="query"
            let-typeaheadTemplateMethods
          >
            <ul class="custom-list-group">
              <li
                (click)="
                typeaheadTemplateMethods.selectMatch(match, $event);
                setEntiteAffectation(match)
              "
                (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
                *ngFor="let match of matches"
                [class.active]="typeaheadTemplateMethods.isActive(match)"
                class="custom-list-group-item"
              >
                {{ match.item.nomEntiteAffectation }}
              </li>
            </ul>
          </ng-template>
          <div *ngIf="affectationFound === false" class="alert alert-danger">
            Aucun résultat trouvé
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-6">
        <div class="mb-3">
          <input
            (ngModelChange)="changeManagerName($event)"
            [(ngModel)]="managerHierarchique"
            class="form-control"
            placeholder="Manager actuel"
            type="text"
          />
        </div>
      </div>
      <div
        *ngIf="affichage !== 'equipe-globale'"
        class="col-md-4 col-xs-6 align-self-center"
      >
        <div class="mb-3">
          <app-nice-checkbox
            (ngModelChange)="changeWithoutManager($event)"
            [(ngModel)]="withoutManager"
            label="Sans manager"
          ></app-nice-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4 mt-2 separator"></div>
</ng-container>
