<ng-container *ngIf="totalPages > 1">
  <ul>
    Page
    <li class="button-prev">
      <a (click)="selectPage('PREV')"> Préc </a>
    </li>
    <li
      *ngFor="let item of [].constructor(pagination?.totalPages); let i = index"
    >
      <a
        (click)="selectPage(i)"
        [ngClass]="{ selected: i === pagination?.pageNumber }"
      >
        {{ i + 1 }}
      </a>
    </li>
    <li class="button-next">
      <a (click)="selectPage('NEXT')"> Suiv </a>
    </li>
  </ul>
</ng-container>
