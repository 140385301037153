import {inject, Injectable} from '@angular/core'
import {map, tap} from "rxjs/operators";
import {CollaborateurGlobalDto} from "../../shared/models/collaborateursDto";
import {SortOrSearchObject} from "../data-access/state/collaborateurs.state";
import {Observable, of} from "rxjs";
import * as moment from "moment/moment";
import {UserDomainService} from "../../core/domain/user.domain";
import {CollaborateursService} from "../../shared/data-access/http/collaborateurs.service";
import {CollaborateurGlobalBackDto} from "../../shared/models/equipeGlobaleBackDto";
import {EquipeGlobaleState, PAGE_SIZE} from "../data-access/state/equipeglobale.state";
import {CommonService} from "../../shared/data-access/http/common.service";

@Injectable({providedIn: 'root'})
export class EquipeGlobaleDomainService {
    private readonly equipeGlobaleState = inject(EquipeGlobaleState)
    equipeGlobaleSortingParams$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getEquipeGlobaleSortingParams)
    equipeGlobaleStateLoading$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getEquipeGlobaleStateLoading)
    equipeGlobaleSearchResults$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getEquipeGlobaleSearchResults)
    equipeGlobalNumber$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getEquipeGlobalNumber)
    search$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getSearch)
    filters$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getFilters)
    errorSystem$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getErrorSystem)
    searchResultPagination$ = this.equipeGlobaleState.store.select(EquipeGlobaleState.getSearchResultPagination)
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private collabService = inject(CollaborateursService)
    private commonService = inject(CommonService)

    equipeGlobaleSortingParams() {
        return this.equipeGlobaleState.store.selectSnapshot(EquipeGlobaleState.getEquipeGlobaleSortingParams)
    }

    search() {
        return this.equipeGlobaleState.store.selectSnapshot(EquipeGlobaleState.getSearch)
    }

    setLoading(loading: boolean) {
        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            loading: loading,
        }))
    }

    setErrorSystem(inError: boolean) {
        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            errorSystem: inError,
        }))
    }

    loadEquipeGlobale() {
        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            loading: true,
        }))

        return this.collabService.getEquipeGlobale().pipe(
            // Reformattage des données back en données pour le front(simplification et recherche de la relation actuelle)
            // => Passage du format back au format CollaborateurGlobalDto
            map((collabsContent): Array<any> => {
                const collabsBack = this.miseAPlat(collabsContent)
                return collabsBack.map((collabB: CollaborateurGlobalBackDto) =>
                    this.fromBackToFront(collabB)
                )
            }),
            tap((collabs: any) => {
                const pagination = {
                    totalPages: Math.ceil(collabs.length / PAGE_SIZE),
                    totalElements: collabs.length,
                    pageSize: PAGE_SIZE,
                    pageNumber: 0,
                }

                this.equipeGlobaleState.store.update((state) => ({
                    ...state,
                    loading: false,
                    equipeGlobale: {
                        initialList: collabs,
                        searchResult: collabs.slice(pagination.pageNumber, pagination.pageSize),
                        pagination: pagination,
                    },
                }))

                if (this.equipeGlobaleSortingParams()) {
                    this.sortSearch(this.equipeGlobaleSortingParams())
                }
            })
        )
    }

    setFilters(searchField: string, searchValue: string | boolean) {
        const equipeGlobale = this.search()

        const newFilter = {field: searchField, value: searchValue}
        const oldArr =
            equipeGlobale?.searchFields && (equipeGlobale?.searchFields).length > 0 ? equipeGlobale?.searchFields : []
        let updatedSearchFields: any
        if (searchValue !== '') {
            updatedSearchFields = this.mergeArrayOfObjects(oldArr, [newFilter], 'field')
        } else {
            updatedSearchFields = oldArr.filter((item) => item.field !== searchField)
        }
        updatedSearchFields = updatedSearchFields.filter(
            (search: any) =>
                search.field !== 'pasDeManager' || (search.field === 'pasDeManager' && search.value === true)
        )

        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            equipeGlobale: {...equipeGlobale, searchFields: updatedSearchFields},
        }))

        return this.searchEquipeGlobale()
    }

    searchEquipeGlobale() {
        const equipeGlobale = this.search()
        const searchFieldsToSend = equipeGlobale?.searchFields

        if (searchFieldsToSend && searchFieldsToSend.length > 0) {
            this.equipeGlobaleState.store.update((state) => ({
                ...state,
                loading: true,
            }))
        }

        const data: Array<CollaborateurGlobalDto> | undefined = this.searchCollabs(
            equipeGlobale?.initialList,
            searchFieldsToSend
        )
        let dataSorted: Array<CollaborateurGlobalDto> = []
        if (data?.length) {
            dataSorted = this.commonService.sortList(
                data,
                equipeGlobale.sort?.field ? equipeGlobale.sort?.field : '',
                equipeGlobale.sort?.value,
                'nomCollaborateur',
                'DESC'
            )
        }

        const newEquipeGlobale = {
            ...equipeGlobale,
            searchResult: dataSorted,
            pagination: {
                pageNumber: 0,
                pageSize: PAGE_SIZE,
                totalPages: dataSorted?.length ? Math.ceil(dataSorted.length / PAGE_SIZE) : 0,
                totalElements: dataSorted?.length ? dataSorted.length : 0,
            },
        }


        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            equipeGlobale: newEquipeGlobale,
        }))

        return of(void 0)
    }

    searchReset() {
        const equipe = this.search()
        const newTeam = {...equipe, searchFields: undefined as any}

        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            equipeGlobale: newTeam,
        }))
        this.searchEquipeGlobale()
    }

    sortSearch(sortObject: { field: string; value: string }) {
        const equipeGlobale = this.search()
        if (
            !sortObject?.field ||
            (sortObject?.value?.toLowerCase() !== 'asc' && sortObject?.value?.toLowerCase() !== 'desc')
        ) {
            return void 0
        }
        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            equipeGlobale: {...equipeGlobale, sort: sortObject},
        }))
        return this.searchEquipeGlobale()
    }

    sortResetSearch() {
        const equipeGlobale = this.search()
        const newEquipeGlobale = {...equipeGlobale, sort: undefined as any}

        this.equipeGlobaleState.store.update((state) => ({
            ...state,
            equipeGlobale: newEquipeGlobale,
        }))
    }

    paginate(pageNumber: number) {
        const equipeGlobale = this.search()
        const pagination = equipeGlobale?.pagination
        if (pagination) {
            this.equipeGlobaleState.store.update((state) => ({
                ...state,
                equipeGlobale: {
                    ...equipeGlobale,
                    pagination: {...pagination, pageNumber: pageNumber},
                },
            }))
        }
    }

    public flatten(treeObj: any, idAttr?: any) {
        if (!idAttr) {
            idAttr = 'id'
        }

        function flattenChild(
            childObj: any,
            level: any,
            dateDebut?: any,
            dateFin?: any,
            dateModif?: any,
            managerHierarchique?: any
        ) {
            let array = []

            const childCopy = {...childObj}
            childCopy.dateDebut = dateDebut
            childCopy.dateFin = dateFin
            childCopy.dateModif = dateModif
            childCopy.managerHierarchique = managerHierarchique
            delete childCopy?.relation
            array.push(childCopy)

            array = array.concat(processChildren(childObj, level))

            return array
        }

        function relationIsActive(collabB: any): boolean {
            const today = moment(new Date().setHours(0, 0, 0, 0))
            const dateDebut = moment(collabB['dateDebut'])
            const dateFin = collabB['dateFin'] != null ? moment(collabB['dateFin']) : null
            return (dateFin == null || dateFin >= today) && dateDebut <= today
        }

        function processChildren(obj: any, level?: any) {
            if (!level) {
                level = 0
            }
            let array: any = []
            if (obj?.relation) {
                obj.relation.forEach(function (childObj: any) {
                    if (relationIsActive(childObj)) {
                        const manager = {
                            nomManagerHierarchique: obj.nomCollaborateur,
                            prenomManagerHierarchique: obj.prenomCollaborateur,
                            matriculeManagerHierarchique: obj.matriculeCollaborateur,
                        }

                        array = array.concat(
                            flattenChild(
                                childObj['collaborateurEnfant'],
                                level + 1,
                                childObj['dateDebut'],
                                childObj['dateFin'],
                                childObj['dateModif'],
                                manager
                            )
                        )
                    }
                })
            }

            return array
        }

        return processChildren(treeObj)
    }

    /**
     * Merge 2 arrays of object on property p
     * @param a : array of objects A (some items could be overwritten by array B)
     * @param b : array of objects B
     * @param p : field name on which filter is applied
     * @returns array merged
     */
    private mergeArrayOfObjects = (a: Array<any>, b: Array<any>, p: string) =>
        a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b)

    /**
     * From back to front : collab is mapped to "easier handling" format :
     *   - searching in relations history : relation with this user as a manager with no "dateFin" or a dateFin in the future
     * @param collabB from Back <CollaborateurGlobalBackDto>
     * @returns : collab for store <CollaborateurGlobalDto>.
     */
    private fromBackToFront(collabB: any): CollaborateurGlobalDto {
        return {
            matriculeCollaborateur: collabB.matriculeCollaborateur,
            nomCollaborateur: collabB.nomCollaborateur,
            prenomCollaborateur: collabB.prenomCollaborateur,
            nomEtPrenomCollaborateur: collabB.nomCollaborateur + ' ' + collabB.prenomCollaborateur,
            codeEntiteAffectation: collabB.entite?.codeEntiteAffectation ? collabB.entite.codeEntiteAffectation : '',
            nomEntiteAffectation: collabB.entite?.nomEntiteAffectation ? collabB.entite.nomEntiteAffectation : '',
            codeEmploi: collabB.emploi?.codeEmploi ? collabB.emploi.codeEmploi : '',
            nomEmploi: collabB.emploi?.nomEmploi ? collabB.emploi.nomEmploi : '',
            dateDebutEquipe: moment(collabB.dateDebut).format('DD/MM/YYYY'),
            dateDebutEquipeAffichage: moment(collabB.dateDebut).format('DD/MM/YYYY'),
            dateFinEquipe: moment(collabB.dateFin).format('DD/MM/YYYY'),
            matriculeSuperieurHierarchique: collabB.managerHierarchique
                ? collabB.managerHierarchique.matriculeManagerHierarchique
                : '',
            nomSuperieurHierarchique: collabB.managerHierarchique
                ? collabB.managerHierarchique.nomManagerHierarchique
                : 'Sans manager',
            prenomSuperieurHierarchique: collabB.managerHierarchique
                ? collabB.managerHierarchique.prenomManagerHierarchique
                : '',
            nomEtPrenomSuperieurHierarchique: collabB.managerHierarchique
                ? collabB.managerHierarchique.nomManagerHierarchique +
                ' ' +
                collabB.managerHierarchique.prenomManagerHierarchique
                : 'Sans manager',
            dateModification: new Date(),
            mouvementFutur: undefined,
            hasFuturManager: false,
            manager: collabB.manager,
        }
    }

    private searchCollabs(
        list: Array<CollaborateurGlobalDto> | undefined,
        searchFields: Array<SortOrSearchObject> | undefined
    ): Array<CollaborateurGlobalDto> | undefined {
        if (!list || list.length === 0 || !searchFields || searchFields.length === 0) {
            return list
        }
        let results: any = []
        //recherche si nom manager ou nom collab, on recherche sur Nom+prenom.
        //let newSearchFields : Array<SortOrSearchObject> = [];
        searchFields.forEach((item, idx) => {
            let newItem!: SortOrSearchObject
            switch (item.field) {
                case 'collaborateur':
                    newItem = {field: 'nomEtPrenomCollaborateur', value: item.value}
                    break
                case 'managerHierarchique':
                    newItem = {
                        field: 'nomEtPrenomSuperieurHierarchique',
                        value: item.value,
                    }
                    break
                case 'pasDeManager':
                    newItem = {
                        field: 'nomSuperieurHierarchique',
                        value: 'Sans manager',
                    }
                    break
                case 'entite':
                    newItem = {field: 'codeEntiteAffectation', value: item.value}
                    break
                default:
                    newItem = {field: item.field, value: item.value}
                    break
            }
            const resultOnOneField = this.commonService.searchInList(list, newItem)
            if (idx === 0) {
                results = resultOnOneField
            } else {
                if (resultOnOneField) {
                    results = resultOnOneField.filter((a: any) =>
                        results.some((b: any) => a.matriculeCollaborateur === b.matriculeCollaborateur)
                    )
                }
            }
        })

        return results
    }

    /**
     * Le back retourne un arbre allant du user jusqu-aux collabs.
     * On retourne un tableau de tous les collabs (lecture de chaque noeud de l'arbre)
     * Une relation est concidérée comme active si
     *  - sa date de début est inférieure ou égale à la date du jour
     *  - sa date de fin est nulle ou supérieure ou égale à la date du jour
     * @param {*} data
     * @return {*}
     * @memberof EquipeGlobaleState
     */
    private miseAPlat(data: any) {
        return this.flatten(data)
    }

}

