import { take } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { DelegationDto } from 'src/app/shared/models/delegationDto'

@Injectable({
    providedIn: 'root',
})
export class DelegationService {
    constructor(private http: HttpClient) {}

    public listerDelegations(dateDelegation: string): Observable<Array<DelegationDto>> {
        const path = `${environment.baseUrl}${environment.delegationApi}/lister`
        return this.http.get<DelegationDto[]>(path, { params: { date: dateDelegation } }).pipe(take(1))
    }

    public getCsv(dateDelegation: string): Observable<HttpResponse<Blob>> {
        const path = `${environment.baseUrl}${environment.delegationApi}/exporter/csv`

        return this.http.get(path, { params: { date: dateDelegation }, observe: 'response', responseType: 'blob' })
    }
}
