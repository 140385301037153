import {filter, switchMap, takeUntil, tap} from "rxjs/operators";
import {Observable, of, Subject} from "rxjs";
import {environment} from "src/environments/environment";
import {AfterContentChecked, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, Renderer2} from "@angular/core";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import * as moment from "moment";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AsyncPipe, NgClass, NgFor, NgIf} from "@angular/common";
import {DeviceInformations} from "src/app/core/data-access/state/data-immuables.state";
import {DataImmuablesDomainService} from "../../../core/domain/data-immuables.domain";

/**
 * Options for using datatable component
 * @param showSorting : true/ false. If true (and if sortField is not empty), buttons for sort are applied in the head part.
 * @param showHead : true/false : if true, head is shown
 * @param headClassName : to apply a different style for table head. Actually, 1 style :  light-head-table
 * @param containerClassName : class applied on the container div of the table (to fix  table's height, overflow essentially)
 * @param noData : sentence to show if table is empty
 * @param rows : optional. some actions are defined on rows (remove for example) row.field and row.value are triggering the property of row.
 * @param cols : description of cols properties (with or without action)
 * @param swipeTo : for mobile : show fieldname {{swipeTo}} next to first col on init.
 */
export interface DataTableOptions {
    showSorting?: boolean
    showHead?: boolean
    headClassName?: string
    containerClassName?: string
    tableId?: string
    noData?: string
    rows?: DataTableRowsDescription
    cols?: Array<DataTableColsDescription>
    swipeTo?: string
}

/**
 * Structure of data passed to the component. It's an array of objects
 * @param name : label of the field. ie "Nom du collaborateur"
 * @param fields : array of fields given in the data stream. Il several fields, they will be separated by " "
 * @param sortField : field (if several) on which the sort will be done.
 * @param action : object describing wich action placing in the cell after value and which field to send back onclick
 * @param className : Class to apply to the cell
 */
export interface DataTableColsDescription {
    name?: string
    fields?: Array<string>
    sortField?: string
    action?: string
    className?: string
    thClassName?: string
    tooltip?: string
}

export interface DataTableRowsDescription {
    action: string
    className?: string
    tooltip?: string
    conditionField?: string
    conditionValue?: any
    fieldForAction?: string
    valueForAction?: string
}

/**
 * @param field : name of the sorted field (or field to be sorted)
 * @param value : ASC or DESC.
 */
export interface DataTableSortInit {
    field?: string
    value?: string
}

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor, TooltipModule, ActionButtonComponent, NgxSpinnerComponent, AsyncPipe],
})
export class DataTableComponent implements OnInit, OnDestroy, AfterContentChecked {
    @Input() data$!: Observable<any>
    @Input() tableSortInit!: DataTableSortInit
    @Input() tableOptions!: DataTableOptions
    @Input() tableLoading$!: Observable<boolean>
    @Input() scrollTo!: string
    @Input() provenance!: string
    @Output() sortEvent = new EventEmitter<any>()
    @Output() rowActionEvent = new EventEmitter<any>() //event emitted on click if action is present;
    // graphic elements
    public swipeIndex: number = 0
    public hiddenColumn: Array<boolean> = []
    public selectedColumn: number = -1
    public colNames!: Array<DataTableColsDescription> | undefined
    public sortFieldSelected!: DataTableSortInit
    public sortField!: string | undefined
    public sortValue!: string | undefined
    public formattedData$!: Observable<
        {
            id?: string
            rows?: DataTableRowsDescription | undefined
            cols?: any
        }[]
    >
    public idSpinnerDataTable = 'idSpinnerDataTable'
    public rowClassName: string = ''
    public tableOptionsParams: DataTableOptions = {
        showSorting: true,
        showHead: true,
        headClassName: 'light-head-table',
        containerClassName: '',
        tableId: undefined,
        rows: undefined,
        cols: undefined,
        swipeTo: undefined,
    }
    public isMobile!: boolean | undefined
    public isDesktop!: boolean | undefined
    public swipeIdx: number = 0
    public tableId!: string | undefined
    private dataImmuablesDomain = inject(DataImmuablesDomainService)
    deviceInformations$: Observable<DeviceInformations> = this.dataImmuablesDomain.deviceInformations$
    private unsubscribe$ = new Subject<void>()

    constructor(private spinner: NgxSpinnerService, private renderer: Renderer2, private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        // Receiving sort params in case of reloading
        this.sortField = this.tableSortInit?.field
        this.sortValue = this.tableSortInit?.value

        // 1 - Loading device informations
        this.formattedData$ = this.deviceInformations$.pipe(
            takeUntil(this.unsubscribe$),
            switchMap((device) => {
                this.isMobile = device.isMobile
                return of(null)
            }),
            // 2 - wait while table is loading
            switchMap((_) => this.tableLoading$),
            switchMap((loading) => {
                loading === true
                    ? this.spinner.show(this.idSpinnerDataTable, environment.ngxSpinnerOptions)
                    : this.spinner.hide(this.idSpinnerDataTable)
                return this.data$
            }),
            filter((loading) => loading !== true),
            // 3 - Data is available.
            switchMap((_) => this.data$),
            switchMap((data: Array<any>) => {
                this.tableOptionsParams = {
                    ...this.tableOptionsParams,
                    ...this.tableOptions,
                }
                this.colNames = this.tableOptionsParams.cols
                this.tableId = this.tableOptionsParams?.tableId
                const formattedData: Array<any> = []
                if (!!data && data.length > 0) {
                    data.forEach((item: any, index: number) => {
                        const newItem = {...item}
                        const itemId = newItem.matriculeCollaborateur
                            ? 'm' + newItem.matriculeCollaborateur
                            : 'm' + index

                        // Formatting row object
                        let rows: DataTableRowsDescription = this.initRow()

                        if (this.tableOptionsParams?.rows) {
                            rows = {...rows, ...this.tableOptionsParams?.rows}
                            switch (rows.action) {
                                // Si remove demandé : on recherche dans la ligne si la condition est respectée pour proposer la
                                // suppression. On alimente le champ fieldForAction avec la valeur valueForAction
                                case 'removeItem':
                                    if (
                                        rows.conditionField !== '' &&
                                        rows.conditionField !== undefined &&
                                        rows.conditionValue !== '' &&
                                        item[rows.conditionField] === rows.conditionValue
                                    ) {
                                        rows.valueForAction = rows.fieldForAction ? item[rows.fieldForAction] : ''
                                    } else {
                                        rows = this.initRow()
                                    }
                                    break

                                // click sur la ligne : application d'un style sur la row (si les champs respectent les conditions)
                                case 'clickOnRow':
                                    if (
                                        rows.conditionField !== '' &&
                                        rows.conditionField !== undefined &&
                                        rows.conditionValue !== '' &&
                                        item[rows.conditionField] === rows.conditionValue
                                    ) {
                                        this.rowClassName = rows?.className ? rows?.className : ''
                                    } else {
                                        //rows = this.initRow();
                                        if (item?.mouvementFutureRelation !== undefined) {
                                            rows.tooltip = `<i class="bi bi-exclamation-triangle"></i> Ce collaborateur sera affecté prochainement à \
                                      ${item.mouvementFutureRelation.managerHierarchique.prenomManagerHierarchique} \
                                      ${item.mouvementFutureRelation.managerHierarchique.nomManagerHierarchique} \
                                      `
                                        } else {
                                            rows.tooltip = `<i class="bi bi-exclamation-triangle"></i> Ce collaborateur est affecté prochainement`
                                        }
                                        rows.className += ' disabled'
                                    }
                                    break
                                case 'clickOnRow2': // Permet de selectionner une ligne sans avoir les tooltips
                                    if (
                                        rows.conditionField !== '' &&
                                        rows.conditionField !== undefined &&
                                        rows.conditionValue !== '' &&
                                        item[rows.conditionField] === rows.conditionValue
                                    ) {
                                        this.rowClassName = rows?.className ? rows?.className : ''
                                    }
                                    break
                                default:
                                    rows = this.initRow()
                                    break
                            }
                        }

                        const columns: Array<any> = []

                        // Formatting cells of row
                        this.colNames?.forEach((col: any, idx) => {
                            const className = col.className

                            // Si l'option swipeTo est précisée, on glisse vers la colonne demandée (en mode mobile)
                            if (
                                this.isMobile &&
                                !!this.tableOptionsParams.swipeTo &&
                                col?.fields?.length > 0 &&
                                this.tableOptionsParams.swipeTo === col?.fields[0]
                            ) {
                                this.swipeIdx = idx
                                this.swipeTo(this.swipeIdx)
                            }
                            const action = col.action ? col.action : 'show'

                            let newField: any = ''

                            switch (action) {
                                case 'checkbox':
                                case 'datePickerDate':
                                    // init value of field
                                    item[col.fields[0]]
                                        ? (newItem[col.fields[0]] = item[col.fields[0]])
                                        : (newItem[col.fields[0]] = false)

                                    // if init value to show (i.e. in checkbox) : add this value to item object
                                    newField = {
                                        object: newItem,
                                        action: action,
                                        actionFieldName: col.fields[0],
                                        actionFieldValue: newItem[col.fields[0]],
                                        className: className,
                                        error: undefined,
                                    }

                                    // dans le cas d'une ligne désactivée (row.className = disabled) on supprime l'action
                                    // if (rows.className == 'disabled') {
                                    //   newField = {...newField, action: 'show', actionFieldValue:''}
                                    // }
                                    break

                                default:
                                case 'show':
                                    let cell: any = ''
                                    col.fields.forEach((field: any) => {
                                        col.fields.length > 1
                                            ? (cell += this.getItem(item, field) + ' ')
                                            : (cell = this.getItem(item, field))
                                    })

                                    newField = {
                                        object: newItem,
                                        action: action,
                                        actionFieldName: col.fields,
                                        actionFieldValue: cell,
                                        className: className,
                                    }
                                    break
                            }

                            // if className contains added-recently : test dateModification :
                            //  If older than 1 day : remove class (recent added items are showed in bold font)
                            if (
                                newField.className.indexOf('added-recently') !== -1 &&
                                (!newField?.object?.dateModification ||
                                    moment()
                                        .startOf('day')
                                        .diff(moment(newField.object.dateModification).startOf('day'), 'day') > 0)
                            ) {
                                const newClass = newField.className.replace('added-recently', '')
                                newField = {...newField, className: newClass}
                            }

                            columns.push(newField)
                        })
                        formattedData.push({id: itemId, rows: rows, cols: columns})
                    })
                }
                return of(formattedData)
            }),
            tap((_) => {
                if (!!this.scrollTo && this.scrollTo !== '') {
                    this.scrollToElement(this.scrollTo)
                } else {
                    this.scrollTo = ''
                }
                // init datas for mobile screen
                this.hiddenColumn = new Array(this.colNames?.length)
                this.hiddenColumn.fill(false)

                // repositionning bullets for mobile (bullet for navigating between columns)
                if (this.selectedColumn !== -1) {
                    this.swipeTo(this.selectedColumn)
                }
            })
        )
    }

    getItem(item: any, str: string): any {
        if (str.indexOf('.') > -1) {
            const firstPart = str.substring(0, str.indexOf('.'))
            const secondPart = str.substring(str.indexOf('.') + 1)
            return this.getItem(item[firstPart], secondPart)
        } else {
            return item[str]
        }
    }

    ngAfterContentChecked(): void {
        this.tableOptionsParams = {
            ...this.tableOptionsParams,
            ...this.tableOptions,
        }
    }

    public swipeTo(index: number) {
        this.selectedColumn = index
        this.hiddenColumn.forEach((col: boolean, idxCol: number) => {
            this.hiddenColumn[idxCol] = false
            if (index > 1 && index > idxCol && idxCol > 0) {
                this.hiddenColumn[idxCol] = true
            }
            return col
        })
    }

    public actionOnRow(item: any) {
        if (item.rows && (item.rows?.action === 'clickOnRow' || item.rows?.action === 'clickOnRow2')) {
            let cellAction = item.cols.find((cell: any) => cell.action !== 'show')

            // Traitement spécifique pour checkbox (valeurs true/false) en cliquant sur la row, on inverse al valeur de la checkbox.
            if (!!cellAction && cellAction.action === 'checkbox') {
                const actionFieldValue = !cellAction.object[cellAction.actionFieldName]
                cellAction = {...cellAction, actionFieldValue: actionFieldValue}
                this.clickToAction(cellAction)
            }
        }
    }

    public sortOn(sortField: any, sens: string) {
        const obj = {field: sortField, value: sens}
        this.sortEvent.emit(obj)
        this.sortField = sortField
        this.sortValue = sens
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    /**
     * @returns className if headClassName
     */
    getClassOptions() {
        if (this.tableOptions.headClassName) {
            return this.tableOptions.headClassName
        }
        return 'default'
    }

    /**
     * click to action on object : resent result to parent
     */
    public clickToAction(event: any) {
        this.rowActionEvent.emit(event)
    }

    public scrollToElement(elementId: any): void {
        setTimeout(() => {
            const elt = this.elementRef.nativeElement.querySelector('#' + elementId)
            if (elt) {
                elt.scrollIntoView(false, {behavior: 'smooth'})
            }
        }, 0)
    }

    public initRow() {
        return {
            action: '',
            className: '',
            tooltip: '',
            conditionField: '',
            conditionValue: '',
            fieldForAction: '',
            valueForAction: '',
        }
    }
}
