<div
  [ngClass]="[
    (tableLoading$ | async) === false
      ? tableOptionsParams.containerClassName
      : ''
  ]"
  class="container container-table-fix mb-2"
>
  <div [ngClass]="{'scrollable': provenance === 'comites-membre'}">
    <ng-container *ngIf="!!tableOptionsParams">
      <table
        [id]="tableOptionsParams.tableId"
        class="table table-fix-head table-hover"
        data-sticky-header="true"
      >
        <ng-container *ngIf="tableOptionsParams.showHead === true">
          <thead [ngClass]="getClassOptions()">
          <tr>
            <th
              *ngFor="let colName of colNames; let idx = index"
              [ngClass]="{
                  hidden: hiddenColumn[idx] === true,
                  selected: idx === selectedColumn
                }"
              class="column-{{ idx }} {{ colName?.thClassName }}"
              id="th_{{ idx }}"
            >
              <div class="th-container">
                {{ colName.name }}
                <ng-container *ngIf="colName?.tooltip !== ''">
                    <span class="px-1 d-none d-md-inline">
                      <i
                        [tooltip]="idTooltip"
                        class="bi bi-info-circle-fill"
                        container="body"
                        containerClass="tooltipClass"
                        placement="auto"
                      >
                      </i>
                    </span>
                  <ng-template #idTooltip let-message="message"
                  >
                    <div [innerHtml]="colName?.tooltip"></div
                    >
                  </ng-template>
                </ng-container>

                <ng-container
                  *ngIf="
                      tableOptionsParams.showSorting === true &&
                      !!colName.sortField
                    "
                >
                    <span class="sort-container">
                      <span
                        (click)="sortOn(colName.sortField, 'ASC')"
                        [class.active]="
                          sortField === colName.sortField && sortValue === 'ASC'
                        "
                        class="sortable asc"
                      >
                        <i class="bi bi-caret-up-fill"></i>
                      </span>
                      <span
                        (click)="sortOn(colName.sortField, 'DESC')"
                        [class.active]="
                          sortField === colName.sortField && sortValue === 'DESC'
                        "
                        class="sortable desc"
                      >
                        <i class="bi bi-caret-down-fill"></i
                        ></span>
                    </span>
                </ng-container>
              </div>
            </th>
            <th *ngIf="provenance === 'comites-membre'">
            </th>
          </tr>
          </thead>
        </ng-container>
        <tbody>
        <tr
          (click)="actionOnRow(formattedItem)"
          *ngFor="let formattedItem of formattedData$ | async; let idx = index"
          [id]="formattedItem.id"
          [ngClass]="[rowClassName, formattedItem?.rows?.className]"
          [tooltip]="formattedItem?.rows?.tooltip ? rowTooltip : ''"
          class="position-relative"
          container="body"
          containerClass="tooltipClass tooltip-row"
          placement="bottom"
        >
          <ng-template #rowTooltip let-message="message">
            <div [innerHtml]="formattedItem?.rows?.tooltip || ''"></div>
          </ng-template>

          <td
            *ngFor="let cell of formattedItem.cols; let idx = index"
            [ngClass]="{
                hidden: hiddenColumn[idx] === true,
                selected: idx === selectedColumn
              }"
            class="column-{{ idx }} {{ cell.className }}"
          >
            <div class="td-container">
              <ng-container *ngIf="cell.action === 'show'">
                <ng-container
                  *ngIf="
                      cell.actionFieldValue === 'In' ||
                        cell.actionFieldValue === 'Out';
                      else cellValue
                    "
                >
                  <i
                    *ngIf="cell.actionFieldValue === 'Out'"
                    class="bi bi-box-arrow-right out-color"
                  ></i>
                  <i
                    *ngIf="cell.actionFieldValue === 'In'"
                    class="bi bi-box-arrow-in-left in-color"
                  ></i>
                </ng-container>
                <ng-template #cellValue>
                  <span>{{ cell.actionFieldValue }}</span>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="cell.action !== 'show'">
                <app-action-button
                  (onActionButtonEvent)="clickToAction($event)"
                  [data]="cell"
                ></app-action-button>
              </ng-container>

              <!-- derniere cellule de ligne + row.action = removeItem : ajout bouton supprimer-->
              <ng-container
                *ngIf="
                    idx === formattedItem.cols.length - 1 &&
                    formattedItem?.rows?.action === 'removeItem'
                  "
              >
                <div [ngClass]="{'container-remove-button': provenance !== 'comites-membre',
                                   'container-remove-button-without-border': provenance === 'comites-membre'}">
                  <app-action-button
                    (onActionButtonEvent)="clickToAction($event)"
                    *ngIf="formattedItem?.rows?.action === 'removeItem'"
                    [data]="formattedItem.rows"
                  ></app-action-button>
                </div>
              </ng-container>
            </div>
          </td>
        </tr>

        <!-- No data  -->
        <tr
          *ngIf="
              (!(formattedData$ | async) || !(formattedData$ | async)?.length) &&
              (tableLoading$ | async) === false &&
              tableOptionsParams.noData
            "
        >
          <td [attr.colspan]="colNames?.length" class="text-center">
              <span class="text-info">
                <i aria-hidden="true" class="bi bi-info-circle-fill"></i>
                {{ tableOptionsParams.noData }}
              </span>
          </td>
        </tr>

        <ngx-spinner
          [fullScreen]="false"
          [name]="idSpinnerDataTable"
          size="medium"
          type="la-ball-clip-rotate"
        >
        </ngx-spinner>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="(tableLoading$ | async) === false">
  <div class="container d-block d-md-none">
    <div class="row">
      <div class="col text-center mt-1 mb-2">
        <span
          (click)="swipeTo(idx)"
          *ngFor="let colName of colNames; let idx = index"
          [ngClass]="{ selected: idx === selectedColumn }"
          class="bullet"
        >
          <i class="bi bi-circle-fill"></i>
        </span>
      </div>
    </div>
  </div>
</ng-container>
