<header class="container-fluid header">
    <div class="row">
        <div class="col-8">
            <app-header-menu></app-header-menu>
        </div>
        <div class="col-4">
            <app-header-user></app-header-user>
        </div>
    </div>
</header>
