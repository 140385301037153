<div class="content">
    <app-header></app-header>
    <div *ngIf="(errorStore$ | async) === true" class="row">
        <div class="col-md-10 offset-md-1 col-12 offset-0 mb-3">
            <div class="alert alert-danger my-2 py-3 text-center" role="alert">
                <i class="bi bi-exclamation-triangle"></i> Une erreur système ne permet pas d'utiliser correctement le site.
            </div>
        </div>
    </div>
    <div class="portailrh-container-global">
        <div breadCrumbs class="breadcrumb-container col-12">
            <lib-breadcrumbs class="separator"></lib-breadcrumbs>
        </div>
        <div class="container-fluid container-fluid-layout">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer [versionBack]="versionBack$|async"></app-footer>
</div>
