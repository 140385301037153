import {inject, Injectable} from '@angular/core'
import {tap} from "rxjs/operators";
import {DataImmuableState} from "../data-access/state/data-immuables.state";
import {DeviceDetectorService} from "ngx-device-detector";
import {CommonDataService} from "../../shared/data-access/http/common-data.service";

@Injectable({providedIn: 'root'})
export class DataImmuablesDomainService {
    private readonly dataImmuableState = inject(DataImmuableState)
    dataImmuableLoading$ = this.dataImmuableState.store.select(DataImmuableState.getDataImmuableLoading)
    entiteAffectation$ = this.dataImmuableState.store.select(DataImmuableState.getEntiteAffectation)
    deviceInformations$ = this.dataImmuableState.store.select(DataImmuableState.getDeviceInformations)
    comite$ = this.dataImmuableState.store.select(DataImmuableState.getComite)
    private readonly deviceService = inject(DeviceDetectorService)
    private readonly commonDataService = inject(CommonDataService)

    setDeviceInformations() {
        const device = {
            deviceInfo: this.deviceService.getDeviceInfo(),
            isMobile: this.deviceService.isMobile(),
            isTablet: this.deviceService.isTablet(),
            isDesktopDevice: this.deviceService.isDesktop(),
        }

        return this.dataImmuableState.store.update((state) => ({
            ...state,
            deviceInformations: device,
        }))
    }

    setEntiteAffectation() {

        return this.commonDataService.getEntiteAffectation()
            .pipe(
                tap((result) =>
                    this.dataImmuableState.store.update((state) => ({
                        ...state,
                        entiteAffectation: result,
                    }))
                )
            )
    }

    setComite() {
        return this.commonDataService.getComite()
            .pipe(
                tap((result) =>
                    this.dataImmuableState.store.update((state) => ({
                        ...state,
                        comite: result,
                    }))
                )
            )
    }

}
