<div class="container">
    <div class="row portailrh-container-global">
        <div class="notfound col-12">
            <div class="d-flex flex-column align-items-center my-5">
                <h1>Page non trouvée</h1>
                <h2>La page que vous recherchez semble introuvable.</h2>
            </div>
            <div class="bouton">
                <button class="btn btn-primary bouton" routerLink="/">Retourner à l'accueil</button>
            </div>
        </div>
    </div>
</div>
