import {Component, inject} from '@angular/core'
import {ComiteListComponent} from '../comite-list/comite-list.component'
import {FiltersComponent} from 'src/app/shared/ui/filters/filters.component'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {DataImmuablesDomainService} from "../../../core/domain/data-immuables.domain";

@Component({
    selector: 'app-comites',
    templateUrl: './comites.component.html',
    styleUrls: ['./comites.component.scss'],
    standalone: true,
    imports: [
        BreadcrumbsModule,
        FiltersComponent,
        ComiteListComponent,
    ],
})
export class ComitesComponent {
    private dataImmuablesDomain = inject(DataImmuablesDomainService)

    constructor() {
        this.dataImmuablesDomain.setComite().subscribe()
    }

}
