import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { EntiteAffectationDto } from 'src/app/shared/models/entiteAffectationDto'
import { SalarieComiteDto } from "src/app/organigramme/data-access/http/dto/SalarieComiteDto";

@Injectable({
    providedIn: 'root',
})
export class CommonDataService {
    constructor(private http: HttpClient) {}

    public getEntiteAffectation(): Observable<Array<EntiteAffectationDto>> {
        return this.http.get<any>(environment.baseUrl + '/reference/entite').pipe(take(1))
    }

    public getComite(): Observable<Array<SalarieComiteDto>> {
        return this.http.get<any>(environment.baseUrl + '/comite').pipe(take(1))
    }
}
