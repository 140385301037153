import { CustomModalComponent } from './custom-modal.component'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ModalModule } from 'ngx-bootstrap/modal'

@NgModule({
    imports: [CommonModule, ModalModule.forRoot(), CustomModalComponent],
    exports: [CustomModalComponent],
})
export class CustomModalModule {}
