import { Component, OnInit } from '@angular/core'
import { ListComponent } from 'src/app/shared/ui/list/list.component'
import { FiltersComponent } from 'src/app/shared/ui/filters/filters.component'

@Component({
    selector: 'app-add-collaborateur',
    templateUrl: './add-collaborateur.component.html',
    styleUrls: ['./add-collaborateur.component.scss'],
    standalone: true,
    imports: [FiltersComponent, ListComponent],
})
export class AddCollaborateurComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
