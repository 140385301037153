import {environment} from 'src/environments/environment'
import {Component, HostListener, inject, OnInit} from '@angular/core'
import {HeaderUserComponent} from '../header-user/header-user.component'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {RouterLink, RouterLinkActive} from '@angular/router'
import {NgIf} from "@angular/common";
import {filter, takeUntil, tap} from "rxjs/operators";
import {UserStateModel} from "../../data-access/state/user.state";
import {DroitDto} from "../../../shared/models/droitDto";
import {UserDomainService} from "../../domain/user.domain";
import {Observable, Subject} from "rxjs";
import {AccordionModule} from 'ngx-bootstrap/accordion';


@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        CollapseModule,
        RouterLinkActive,
        BsDropdownModule,
        HeaderUserComponent,
        AccordionModule,
        NgIf,
    ],
})
export class HeaderMenuComponent implements OnInit {
    isCollapsed: boolean

    public canMajManager = false
    public canMajComite = false
    public canExportDelegations = false
    public hasManager: boolean

    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private unsubscribe$ = new Subject<void>()

    envtest:string

    constructor() {
        this.isCollapsed = true
    }

    @HostListener('body:click')
    closeMenu(){
        this.isCollapsed = true
    }

    ngOnInit(): void {
        this.envtest = environment.name.toUpperCase() === 'PROD' ? '' : environment.name.toUpperCase();

        this.user$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((user: UserStateModel) => user.loading === false),
                tap((user: UserStateModel) => {

                    this.hasManager = user?.user?.manager

                    if (user?.user?.droits?.some((droit: DroitDto) => droit?.nomDroit === 'maj_manager')) {
                        this.canMajManager = true
                    }

                    if (
                        user?.user?.droits?.some(
                            (droit: DroitDto) =>
                                droit?.nomDroit === 'add_comite_membre' || droit?.nomDroit === 'remove_comite_membre'
                        )
                    ) {
                        this.canMajComite = true
                    }

                    if (user?.user?.droits?.some((droit: DroitDto) => droit?.nomDroit === 'exporter_delegations')) {
                        this.canExportDelegations = true
                    }

                })
            )
            .subscribe()
    }

    navigateToHelp() {
        this.isCollapsed = true
        window.open(environment.helpUrl, '_blank')
    }

    toggleMenu(clickEvent: MouseEvent){
        this.isCollapsed = !this.isCollapsed
        clickEvent.stopPropagation()
    }
}
