<div
  #modal="bs-modal"
  (onHide)="sendToParent($event)"
  aria-labelledby="dialog-events-name"
  bsModal
  class="modal fade"
  role="dialog"
  tabindex="-1"
>
  <div [ngClass]="modalSize" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
          <div class="col-11">
              <h4
                  [innerHTML]="modalTitle"
                  class="modal-title pull-left"
                  id="dialog-events-name">
              </h4>
        </div>
        <div class="col-1 button-align-right">
            <button
                (click)="onClicClose()"
                aria-label="Close"
                type="button"
                class="button-close">
                <i class="bi bi-x-lg"></i>
            </button>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
