import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs'
import { ManagerComponent } from './feature/manager/manager.component'
import { MonEquipeModule } from '../mon-equipe/mon-equipe.module'
import { ListModule } from 'src/app/shared/ui/list/list.module'

@NgModule({
    imports: [
        CommonModule,
        BreadcrumbsModule.forRoot({
            postProcess: null,
            applyDistinctOn: 'text',
        }),
        MonEquipeModule,
        ListModule,
        ManagerComponent,
    ],
    providers: [],
    exports: [],
})
export class ManagerModule {}
