<ng-container *ngIf="(collaborateursFilters$ | async)?.length > 0">
  <div class="row mb-2">
    <div class="col-xs-12 col-md-6">
      <h2 class="position-relative">
        Résultats de recherche
        <ng-container *ngIf="(collaborateursLoading$ | async) === false">
          <ng-container *ngIf="resultsNumber === 0">
            (aucun collaborateur trouvé)
          </ng-container>
          <ng-container *ngIf="resultsNumber === 1">
            (1 collaborateur trouvé)
          </ng-container>
          <ng-container *ngIf="resultsNumber > 1">
            ({{ resultsNumber }} collaborateurs trouvés)
          </ng-container>
        </ng-container>
      </h2>
    </div>
    <div *ngIf="provenance !== 'comites-membre-collabs'" class="col-md-6 d-none d-md-block add-to-my-team upper">
      <button
        (click)="updateMyTeam()"
        *ngIf="(selectedCollaborateurs$ | async)?.length"
        class="btn btn-info"
        type="button"
      >
        {{'Ajouter dans ' + (majManager ? 'l\'équipe de' : 'mon équipe')}}
      </button>
    </div>
    <div *ngIf="provenance === 'comites-membre-collabs'" class="col-md-6 d-none d-md-block add-to-my-team upper">
      <button
        (click)="updateMyComite()"
        *ngIf="(selectedCollaborateurs$ | async)?.length"
        class="btn btn-info"
        type="button"
      >
        Ajouter dans le comité
      </button>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12">
      <div class="container-table">
        <app-data-table
          (rowActionEvent)="addOrRemoveCollaborateur($event)"
          (sortEvent)="sortDataOn($event)"
          [data$]="resultsToShow$"
          [tableLoading$]="collaborateursLoading$"
          [tableOptions]="provenance === 'comites-membre-collabs' ? tableOptionsComite : tableOptionsResults"
          [tableSortInit]="searchResultsSortingParams$ | async"
        >
        </app-data-table>
      </div>
    </div>

    <div class="col-12 text-center">
      <app-pagination
        (backFromPagination)="changePage($event)"
        [pagination$]="pagination$"
      ></app-pagination>
    </div>
  </div>
  <!--  -->
  <div class="row">
    <div *ngIf="provenance !== 'comites-membre-collabs'" class="col-md-6 offset-md-6 col-12 add-to-my-team">
      <button
        (click)="updateMyTeam()"
        *ngIf="(selectedCollaborateurs$ | async)?.length"
        class="btn btn-info"
        type="button"
      >
        {{'Ajouter dans ' + (majManager ? 'l\'équipe de' : 'mon équipe')}}
      </button>
    </div>
  </div>

  <app-custom-modal (modalStatusBack)="backFromModaleEnTantQueManager($event)"
                    *ngIf="provenance !== 'comites-membre-collabs'"
                    [modalConfig]="modalConfig"
                    [modalIsVisible]="modalIsVisible"
                    [modalTitle]="modalTitle"
                    modalSize="modal-lg"
  >
    <div *ngIf="majManager" class="container mt-3">
      <form (ngSubmit)="onSubmitManagers()" [formGroup]="managerForm" autocomplete="off">
        <div class="row">
          <div class="col-6 my-3 mb-3">
            <label class="form-label" for="searchCollaborateur">Nouveau manager</label>
            <ng-select (change)=onChangeManagers($event)
                       (search)=onSearchManagers($event)
                       [ngClass]="managerForm.get('searchCollaborateur')?.invalid
                       && (managerForm.get('searchCollaborateur')?.dirty || managerForm.get('searchCollaborateur')?.touched) ? 'is-invalid' : ''"
                       formControlName="searchCollaborateur"
                       id="searchCollaborateur"
                       notFoundText="Aucun manager trouvé"
                       placeholder="Nom, Prénom du manager">
              <ng-option *ngFor="let manager of managers"
                         [value]="manager">{{manager.prenomCollaborateur + ' ' + manager.nomCollaborateur + (manager.entite ? ' (' + manager.entite.nomEntiteAffectation + ') ' : '')}}</ng-option>
            </ng-select>
            <div class="invalid-feedback">
              <div *ngIf="managerForm.get('searchCollaborateur')?.invalid
                       && (managerForm.get('searchCollaborateur')?.dirty || managerForm.get('searchCollaborateur')?.touched)">
                Le manager est obligatoire
              </div>
            </div>
          </div>
          <div class="col-3 my-3">
              <label class="form-label" for="dateMajManager">Date de rattachement</label>
              <div class="input-group">
                <input
                      id="dateMajManager"
                      formControlName="dateMajManager"
                      #dp
                      [bsConfig]="{
                        isAnimated: true,
                        adaptivePosition: true,
                        showWeekNumbers: false,
                        containerClass: 'theme-blue',
                        dateInputFormat: 'DD/MM/YYYY'
                      }"
                      [maxDate]="endManagerCalendar"
                      [minDate]="startManagerCalendar"
                      [ngClass]="managerForm.get('dateMajManager')?.invalid
                                        && (managerForm.get('dateMajManager')?.dirty || managerForm.get('dateMajManager')?.touched) ? 'is-invalid' : ''"
                      [outsideClick]="true"
                      bsDatepicker
                      class="form-control select-date"
                      placeholder="Date d'effet"
                  />
                  <div class="input-group-prepend d-inline-block">
                      <span (click)="openDatepicker()" class="btn btn-picker btn-info datepicker-calendar">
                        <i class="bi bi-calendar-date"></i>
                      </span>
                  </div>
                  <div class="invalid-feedback">
                      <div *ngIf="managerForm.get('dateMajManager')?.invalid
                               && (managerForm.get('dateMajManager')?.dirty || managerForm.get('dateMajManager')?.touched)">La
                          date de mise à jour est obligatoire
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-3 my-3">
            <button [disabled]="managerFormIsSubmitting || managerForm.invalid" class="btn btn-primary py-3 my-3"
                    type="submit">
              <span *ngIf="managerFormIsSubmitting" class="spinner-border"></span>
              <span *ngIf="!managerFormIsSubmitting">Enregistrer</span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <app-add-collab
      (backFromAddCollab)="backFromAddCollab($event)"
      *ngIf="modalIsVisible"
    ></app-add-collab>
  </app-custom-modal>
  <app-custom-modal (modalStatusBack)="backFromModale($event)"
                    *ngIf="provenance === 'comites-membre-collabs'"
                    [modalConfig]="modalConfig"
                    [modalIsVisible]="modalIsVisible"
                    [modalTitle]="modalTitle"
                    modalSize="modal-lg"
  >
    <div class="container-table">
      <app-data-table
        [data$]="selectedCollaborateurs$"
        [tableLoading$]="comiteMembreLoading$"
        [tableOptions]="tableOptionsAddComite"
      >
      </app-data-table>
      <div class="col-3 my-3">
        <button (click)="onSubmitComite()" class="btn btn-primary py-3 my-3" type="submit">
          <span>Enregistrer</span>
        </button>
      </div>
    </div>
  </app-custom-modal>
</ng-container>
