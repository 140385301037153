import {environment} from 'src/environments/environment'

import {Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {EMPTY, Observable, Subject, Subscriber} from 'rxjs'
import {debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap} from 'rxjs/operators'
import {EntiteAffectationDto} from 'src/app/shared/models/entiteAffectationDto'
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner'
import {NiceCheckboxComponent} from '../nice-checkbox/nice-checkbox.component'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {TypeaheadModule} from 'ngx-bootstrap/typeahead'
import {FormsModule} from '@angular/forms'
import {AsyncPipe, NgFor, NgIf} from '@angular/common'
import {ComiteListComponent} from "../../../comites/feature/comite-list/comite-list.component";
import {SalarieComiteDto} from "../../../organigramme/data-access/http/dto/SalarieComiteDto";
import {DataImmuablesDomainService} from "../../../core/domain/data-immuables.domain";
import {CollaborateurDomainService} from "../../../mon-equipe/domain/collaborateur.domain";
import {EquipeGlobaleDomainService} from "../../../mon-equipe/domain/equipeglobale.domain";

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        TypeaheadModule,
        TooltipModule,
        NgxSpinnerComponent,
        NgFor,
        CollapseModule,
        NiceCheckboxComponent,
        AsyncPipe,
    ],
})
export class FiltersComponent implements OnInit, OnDestroy, OnChanges {

    @Input() comiteListComponent!: ComiteListComponent
    @Input() provenance!: string
    public affichage: string = 'mes-collaborateurs-directs'
    public search$!: Observable<any>
    public affectationFound: boolean = true
    public comiteFound: boolean = true
    public collaborateur: string
    public collaborateurChanged$: Subject<string> = new Subject<string>()
    public withoutManager: boolean
    public withoutManagerChanged$: Subject<boolean> = new Subject<boolean>()
    public managerHierarchique: string
    public managerHierarchiqueChanged$: Subject<string> = new Subject<string>()
    public entiteAffectationLabel: string
    public entiteAffectationCode: string
    public comiteLabel: string
    public comiteCode: string
    public entiteAffectationFiltered!: Observable<EntiteAffectationDto[]>
    public comiteFiltered!: Observable<SalarieComiteDto[]>
    public typeaheadLoading!: boolean
    public filtersAreCollapsed: boolean = true
    public idSpinnerSelect = 'idSpinnerSelect'
    private equipeGlobaleDomainService = inject(EquipeGlobaleDomainService)
    searchEquipeGlobale$: Observable<any> = this.equipeGlobaleDomainService.search$
    private collaborateurDomainService = inject(CollaborateurDomainService)
    searchMesCollabs$: Observable<any> = this.collaborateurDomainService.searchCollabs$
    private dataImmuablesDomain = inject(DataImmuablesDomainService)
    entiteAffectationList$: Observable<any> = this.dataImmuablesDomain.entiteAffectation$
    comiteList$: Observable<any> = this.dataImmuablesDomain.comite$
    loading$: Observable<boolean> = this.dataImmuablesDomain.dataImmuableLoading$
    private unsubscribe$ = new Subject<void>()

    constructor(private spinner: NgxSpinnerService) {
        //Init
        this.collaborateur = ''
        this.withoutManager = false
        this.managerHierarchique = ''
        this.entiteAffectationLabel = ''
        this.entiteAffectationCode = ''
        this.comiteLabel = ''
        this.comiteCode = ''
    }

    ngOnInit(): void {
        // Listen to entiteAffectation search
        this.entiteAffectationFiltered = new Observable((observer: Subscriber<string>) => {
            observer.next(this.entiteAffectationLabel)
        }).pipe(
            takeUntil(this.unsubscribe$),
            mergeMap((entiteToken: string) => this.filterEntiteAffectation(entiteToken))
        )

        // Listen to comite search
        this.comiteFiltered = new Observable((observer: Subscriber<string>) => {
            observer.next(this.comiteLabel)
        }).pipe(
            takeUntil(this.unsubscribe$),
            mergeMap((comiteToken: string) => this.filterComite(comiteToken))
        )

        // listen to collaborateur change (debouncetime to limit bdd call)
        this.collaborateurChanged$
            .pipe(
                debounceTime(300), // wait 300ms after the last event before emitting last event
                takeUntil(this.unsubscribe$),
                switchMap((collab: string) => {
                    if (
                        this.affichage === 'mes-collaborateurs-directs' ||
                        this.affichage === 'maj-manager' ||
                        this.affichage === 'comites-membre-collabs'
                    ) {
                        return this.collaborateurDomainService.setFilters('collaborateur', collab)
                    } else if (this.affichage === 'equipe-globale') {
                        return this.equipeGlobaleDomainService.setFilters('collaborateur', collab)
                    } else {
                        return EMPTY
                    }
                })
            )
            .subscribe()

        // listen to managerHierarchique change (debouncetime to limit bdd call)
        this.managerHierarchiqueChanged$
            .pipe(
                debounceTime(300),
                takeUntil(this.unsubscribe$),
                switchMap((manager: string) => {
                    if (
                        this.affichage === 'mes-collaborateurs-directs' ||
                        this.affichage === 'maj-manager' ||
                        this.affichage === 'comites-membre-collabs'
                    ) {
                        return this.collaborateurDomainService.setFilters('managerHierarchique', manager)
                    } else if (this.affichage === 'equipe-globale') {
                        return this.equipeGlobaleDomainService.setFilters('managerHierarchique', manager)
                    } else {
                        return EMPTY
                    }
                })
            )
            .subscribe()

        // listen to pasDeManager change (debouncetime to limit bdd call)
        this.withoutManagerChanged$
            .pipe(
                debounceTime(300),
                takeUntil(this.unsubscribe$),
                switchMap((withoutManager: boolean) => {
                    if (
                        this.affichage === 'mes-collaborateurs-directs' ||
                        this.affichage === 'maj-manager' ||
                        this.affichage === 'comites-membre-collabs'
                    ) {
                        return this.collaborateurDomainService.setFilters('pasDeManager', withoutManager)
                    } else if (this.affichage === 'equipe-globale') {
                        return this.equipeGlobaleDomainService.setFilters('pasDeManager', withoutManager)
                    } else {
                        return EMPTY
                    }
                })
            )
            .subscribe()

        // Initialize filters form with values set in store.
        this.search$
            .pipe(
                take(1),
                tap((search) => {
                    this.collaborateur = search?.searchFields?.find((x: any) => x.field === 'collaborateur')?.value
                    this.managerHierarchique = search?.searchFields?.find(
                        (x: any) => x.field === 'managerHierarchique'
                    )?.value
                    this.withoutManager = search?.searchFields?.find((x: any) => x.field === 'pasDeManager')?.value
                    this.entiteAffectationCode = search?.searchFields?.find((x: any) => x.field === 'entite')?.value
                    this.comiteCode = search?.searchFields?.find((x: any) => x.field === 'comite')?.value
                    if (!!this.entiteAffectationCode) {
                        this.spinner.show(this.idSpinnerSelect, environment.ngxSpinnerOptions)
                        this.loading$
                            .pipe(
                                filter((loading) => !loading),
                                switchMap((_) => this.entiteAffectationList$),
                                filter((list) => list.length > 0),
                                tap((list) => {
                                    this.spinner.hide(this.idSpinnerSelect)
                                    const item = list.find(
                                        (item: any) => item.codeEntiteAffectation === this.entiteAffectationCode
                                    )
                                    this.entiteAffectationLabel = item?.nomEntiteAffectation
                                })
                            )
                            .subscribe()
                    }

                    if (!!this.comiteCode) {
                        this.spinner.show(this.idSpinnerSelect, environment.ngxSpinnerOptions)
                        this.loading$
                            .pipe(
                                filter((loading) => !loading),
                                switchMap((_) => this.comiteList$),
                                filter((list) => list.length > 0),
                                tap((list) => {
                                    this.spinner.hide(this.idSpinnerSelect)
                                    const item = list.find((item: any) => item.codeComite === this.comiteCode)
                                    this.comiteLabel = item?.codeComite
                                })
                            )
                            .subscribe()
                    }
                    if (
                        this.managerHierarchique !== '' &&
                        this.managerHierarchique !== undefined &&
                        this.entiteAffectationCode !== '' &&
                        this.entiteAffectationCode !== undefined &&
                        this.comiteCode !== '' &&
                        this.comiteCode !== undefined &&
                        this.withoutManager
                    ) {
                        this.filtersAreCollapsed = false
                    }
                })
            )
            .subscribe()

        // Init search filters
        this.collaborateurDomainService.searchReset()

        // Init search filters
        this.collaborateurDomainService.resetSelectedCollabs()
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if (propName === 'provenance') {
                    this.affichage = changes[propName].currentValue
                    if (
                        this.affichage === 'mes-collaborateurs-directs' ||
                        this.affichage === 'maj-manager' ||
                        this.affichage === 'comites-membre' ||
                        this.affichage === 'comites-membre-collabs'
                    ) {
                        this.search$ = this.searchMesCollabs$
                        if (this.affichage === 'maj-manager' || this.affichage === 'comites-membre-collabs') {
                            localStorage.setItem('majManager', 'true')
                        } else {
                            localStorage.setItem('majManager', 'false')
                        }
                    } else if (this.affichage === 'equipe-globale') {
                        this.search$ = this.searchEquipeGlobale$
                        localStorage.setItem('majManager', 'false')
                    } else {
                        localStorage.setItem('majManager', 'false')
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    public filterEntiteAffectation(entiteToken: string): Observable<EntiteAffectationDto[]> {
        const query = new RegExp(entiteToken, 'i')
        this.spinner.show(this.idSpinnerSelect, environment.ngxSpinnerOptions)
        return this.loading$.pipe(
            filter((loading) => !loading),
            switchMap((_) => this.spinner.hide(this.idSpinnerSelect)),
            switchMap((_) => this.entiteAffectationList$),
            takeUntil(this.unsubscribe$),
            map((entites) => entites.filter((entity: any) => query.test(entity.nomEntiteAffectation)))
        )
    }

    public filterComite(comiteToken: string): Observable<SalarieComiteDto[]> {
        const query = new RegExp(comiteToken, 'i')
        this.spinner.show(this.idSpinnerSelect, environment.ngxSpinnerOptions)
        return this.loading$.pipe(
            filter((loading) => !loading),
            switchMap((_) => this.spinner.hide(this.idSpinnerSelect)),
            switchMap((_) => this.comiteList$),
            takeUntil(this.unsubscribe$),
            map((comites) => {
                return comites.filter((comite: any) => query.test(comite.codeComite))
            })
        )
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e
    }

    // collaborateur en sélection
    public changenomCollaborateur(text: string) {
        this.collaborateurChanged$.next(text)
    }

    // manager en sélection
    public changeManagerName(text: string) {
        // si recherche sur manager Field, on désactive la checkbox "sans manager"
        if (this.withoutManager) {
            this.withoutManager = false
            this.withoutManagerChanged$.next(false)
        }
        this.managerHierarchiqueChanged$.next(text)
    }

    // change avec/sans manager
    public changeWithoutManager(bool: boolean) {
        this.withoutManagerChanged$.next(bool)
        // si recherche "sans Manager", on désactive le champ manager Field
        if (this.managerHierarchique !== '') {
            this.managerHierarchique = ''
            this.managerHierarchiqueChanged$.next('')
        }
    }

    // entité sélectionnée : set in store.
    public setEntiteAffectation(match: any) {
        if (
            this.affichage === 'mes-collaborateurs-directs' ||
            this.affichage === 'maj-manager' ||
            this.affichage === 'comites-membre-collabs'
        ) {
            this.collaborateurDomainService.setFilters('entite', match.item.codeEntiteAffectation).subscribe()
        } else if (this.affichage === 'equipe-globale') {
            this.equipeGlobaleDomainService.setFilters('entite', match.item.codeEntiteAffectation).subscribe()
        }
    }

    // entité sélectionnée : set in store.
    public setComite(match: any) {
        this.comiteListComponent.getMembresComite(match.item.codeComite)
    }

    public noAffectationFound(event: boolean) {
        this.affectationFound = !event
    }

    public noComiteFound(event: boolean) {
        this.comiteFound = !event
    }

    public resetAffectation() {
        this.entiteAffectationLabel = ''
        if (
            this.affichage === 'mes-collaborateurs-directs' ||
            this.affichage === 'maj-manager' ||
            this.affichage === 'comites-membre-collabs'
        ) {
            this.collaborateurDomainService.setFilters('entite', '')
        } else if (this.affichage === 'equipe-globale') {
            this.equipeGlobaleDomainService.setFilters('entite', '').subscribe()
        }
    }

    public resetComite() {
        this.comiteLabel = ''
        this.comiteCode = ''
        this.collaborateurDomainService.setFilters('comite', '')
        if (this.comiteListComponent) {
            this.comiteListComponent.codeComite = ''
        }
    }

    // Clean search : ré-init filters, empty store search.
    public cleanSearch() {
        this.collaborateur = ''
        this.managerHierarchique = ''
        this.entiteAffectationCode = ''
        this.entiteAffectationLabel = ''
        this.comiteCode = ''
        this.comiteLabel = ''
        this.withoutManager = false

        if (
            this.affichage === 'mes-collaborateurs-directs' ||
            this.affichage === 'maj-manager' ||
            this.affichage === 'comites-membre' ||
            this.affichage === 'comites-membre-collabs'
        ) {
            this.resetComite()
            this.collaborateurDomainService.searchReset()
        } else if (this.affichage === 'equipe-globale') {
            this.equipeGlobaleDomainService.searchReset()
        }
    }
}
