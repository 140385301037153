<ng-container *ngIf="codeComite">
    <div class="row mb-2">
        <div class="col-xs-12 col-md-6">
          <h2 class="position-relative">
            Résultats de recherche
            <ng-container *ngIf="(comiteMembreLoading$ | async) === false">
              <ng-container *ngIf="resultsNumber === 0">
                (aucun collaborateur trouvé)
              </ng-container>
              <ng-container *ngIf="resultsNumber === 1">
                (1 collaborateur trouvé)
              </ng-container>
              <ng-container *ngIf="resultsNumber > 1">
                ({{ resultsNumber }} collaborateurs trouvés)
              </ng-container>
            </ng-container>
          </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-10"></div>
        <div class="col-2">
            <div *ngIf="resultsNumber != 0">
                <button (click)="exporterCSV(codeComite)" *ngIf="resultsNumber != 0" class="btn btn-info py-2 my-2" type="button">
                    <span *ngIf="exportIsSubmitting" class="spinner-border"></span>
                    <span *ngIf="!exportIsSubmitting">Exporter le Résultat</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-12">
          <div class="container-table">
            <app-data-table #dataTableComponent
                            (rowActionEvent)="removeCollaborateur($event)"
                            [data$]="resultsToShow$"
                            [provenance]="provenance"
                            [tableLoading$]="comiteMembreLoading$"
                            [tableOptions]="tableOptionsResults"
            >
            </app-data-table>
          </div>
        </div>
    </div>
</ng-container>
