import {UserDto} from 'src/app/shared/models/userDto'
import {CollaborateurDto} from 'src/app/shared/models/collaborateursDto'
import {CommonService} from 'src/app/shared/data-access/http/common.service'
import {DataTableColsDescription, DataTableComponent, DataTableOptions} from '../data-table/data-table.component'
import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core'

import {Observable, of, Subject} from 'rxjs'
import {map, switchMap, take, takeUntil, tap} from 'rxjs/operators'
import {TypeToast} from 'src/app/shared/ui/toast/state/toast.state'

import * as moment from 'moment'
import {AsyncPipe, NgIf} from '@angular/common'
import {UserDomainService} from "../../../core/domain/user.domain";
import {CollaborateurDomainService} from "../../../mon-equipe/domain/collaborateur.domain";
import {ToastDomainService} from "../toast/domain/toast.domain";

@Component({
    selector: 'app-add-collab',
    templateUrl: './add-collab.component.html',
    styleUrls: ['./add-collab.component.scss'],
    standalone: true,
    imports: [NgIf, DataTableComponent, AsyncPipe],
})
export class AddCollabComponent implements OnInit, OnDestroy {
    public validateButtonIsDisabled: boolean = false
    public validateButtonIsDisabledLabel: string = ''
    public updatedCollabs: Array<CollaborateurDto> = []
    public majManager = false

    public tableOptionsAddCollab: DataTableOptions
    @Output() backFromAddCollab = new EventEmitter<any>()
    private collaborateurDomainService = inject(CollaborateurDomainService)
    collaborateursLoading$: Observable<boolean> = this.collaborateurDomainService.collaborateurStateLoading$
    selectedCollaborateurs$: Observable<any> = this.collaborateurDomainService.selectedCollaborateurs$
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private toastDomainService = inject(ToastDomainService)
    private unsubscribe$ = new Subject<void>()

    constructor(private commonService: CommonService) {
        this.majManager = localStorage.getItem('majManager') === 'true'
        // Array to send to app-data-table : no sort
        const colNamesAddCollabsArray: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: undefined,
                className: 'align-middle',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: undefined,
                className: 'align-middle',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Direction / agence / service',
                fields: ['nomEntiteAffectation'],
                sortField: undefined,
                className: 'align-middle',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Manager actuel',
                fields: ['nomSuperieurHierarchique', 'prenomSuperieurHierarchique'],
                sortField: undefined,
                className: 'align-middle',
                tooltip: '',
            },
        ]
        if (!this.majManager) {
            colNamesAddCollabsArray.push({
                action: 'datePickerDate',
                name: "Date d'effet",
                fields: ['dateDebutEquipe'], // for action's cell : only one field in array.
                sortField: undefined,
                className: 'align-middle',
                tooltip: '',
            })
        }
        this.tableOptionsAddCollab = {
            showSorting: false,
            showHead: true,
            headClassName: 'light-head-table',
            cols: colNamesAddCollabsArray,
            swipeTo: 'dateDebutEquipe',
        }
    }

    ngOnInit(): void {
        this.selectedCollaborateurs$
            .pipe(
                takeUntil(this.unsubscribe$),
                map((collabs) => collabs.filter((item: CollaborateurDto) => item.selected === true && !item.datefilled))
            )
            .subscribe()
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    /**
     * Back from "Selecting a date" in list of collabs :
     * add the returned object (event.object) to array of objects which will be updated onclick (function onValidateDates)
     * @param event : object which contains object : <CollaborateurDto> with new date "dateDebutEquipe"
     */
    public updateCollabsDate(event: any) {
        let collabSelected = event.object
        collabSelected[event.actionFieldName] = event.actionFieldValue
        //Error : show error & disable validate button
        if (event?.error?.status) {
            this.validateButtonIsDisabled = true
            this.validateButtonIsDisabledLabel = event?.error?.label
            collabSelected = {...collabSelected, datefilled: false}
        } else {
            let mouvementType = 'aVenir'
            if (moment(collabSelected.dateDebutEquipe, 'DD/MM/YYYY').isSameOrBefore(moment().startOf('day'))) {
                mouvementType = 'actuel'
            }
            collabSelected = {
                ...collabSelected,
                datefilled: true,
                mouvementType: mouvementType,
                mouvementDateEffet: collabSelected.dateDebutEquipe,
            }
        }

        this.updatedCollabs = this.commonService.mergeArrayOfObjects(
            this.updatedCollabs,
            [collabSelected],
            'matriculeCollaborateur'
        )

        // Check if errors (several dates)
        if (this.updatedCollabs.find((collab) => !collab.datefilled) === undefined) {
            this.validateButtonIsDisabled = false
            this.validateButtonIsDisabledLabel = ''
        }
    }

    /**
     * Validation des dates :
     * - no error
     * - update
     */
    public onValidateDates() {
        this.user$
            .pipe(
                take(1),
                switchMap((userStore) => {
                    const user: UserDto = userStore.user
                    const newCollabs = this.updatedCollabs.map((newCollab: CollaborateurDto) => {
                        newCollab.matriculeSuperieurHierarchique = user.matriculeCollaborateur
                        newCollab.nomSuperieurHierarchique = user.nomCollaborateur
                        newCollab.prenomSuperieurHierarchique = user.prenomCollaborateur
                        newCollab.mouvement = 'In'
                        return newCollab
                    })
                    return of(newCollabs)
                }),
                switchMap((newCollabs: Array<CollaborateurDto>) =>
                    this.collaborateurDomainService.setSelectedCollabs(newCollabs)
                ),
                switchMap((_: any) => {
                    this.backFromAddCollab.emit(false)
                    return of(null)
                }),
                switchMap((_: any) => this.collaborateurDomainService.loadingMonEquipe()),
                tap((_: any) => {
                    const label1 =
                        this.updatedCollabs.length > 1
                            ? `${this.updatedCollabs.length} nouveaux collaborateurs sélectionnés`
                            : 'Un Nouveau collaborateur sélectionné'
                    const label2 =
                        this.updatedCollabs.length > 1
                            ? 'Les collaborateurs que vous avez choisi sont ajoutés à votre équipe.'
                            : 'Le collaborateur que vous avez choisi est ajouté à votre équipe.'

                    this.toastDomainService.addToastMessage(
                        TypeToast.success,
                        label1,
                        `${label2}  <br /> Il ne vous reste plus qu\'à valider l\'opération. `
                    )
                    this.updatedCollabs = []
                })
            )
            .subscribe()
    }
}
