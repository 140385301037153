<div class="container position-relative portailrh-container-page">
  <div class="row mb-3">
    <div class="col-8 align-self-center">
      <h2>
        Mon équipe globale
        <ng-container *ngIf="(equipeGlobaleLoading$ | async) === false">
          <ng-container *ngIf="resultsNumber === 0">
            (personne actuellement)
          </ng-container>
          <ng-container *ngIf="resultsNumber === 1">
            (1 personne)
          </ng-container>
          <ng-container *ngIf="resultsNumber > 1">
            ({{ resultsNumber }} personnes)
          </ng-container>
          :
        </ng-container>
      </h2>
    </div>
    <div class="col-4 text-end align-self-center"></div>
  </div>

  <app-filters provenance="equipe-globale"></app-filters>

  <div class="row mb-4">
    <div class="col-12">
      <div class="container-table">
        <app-data-table
          (sortEvent)="sortDataOn($event)"
          [data$]="equipeGlobalePaginated$"
          [tableLoading$]="equipeGlobaleLoading$"
          [tableOptions]="tableOptionsEquipeGlobale"
          [tableSortInit]="equipeGlobaleSortingParams$ | async"
        >
        </app-data-table>
      </div>
    </div>
    <div class="col-12 text-center">
      <app-pagination
        (backFromPagination)="changePage($event)"
        [pagination$]="pagination$"
      ></app-pagination>
    </div>
  </div>
</div>
