<!-- data.type === 'ajouter'-->
<ng-container *ngIf="data.type && data.type === 'add'">
  <button
    (click)="clickButton(data)"
    [ngClass]="[data.type]"
    class="btn btn-outline-success"
    type="button"
  ></button>
</ng-container>

<!-- data.type === 'checkbox'-->
<ng-container *ngIf="data.action && data.action === 'checkbox'">
  <app-nice-checkbox
    (change)="clickCheckBox($event)"
    [isChecked]="data?.object?.selected === true"
    label=""
  ></app-nice-checkbox>
</ng-container>

<!-- data.type === 'datepicker'-->
<ng-container *ngIf="data.action && data.action === 'datePickerDate'">
  <div class="input-group">
    <!-- WARNING ! 2 inputs for the same thing (due to bs(datepicker bug : no possibility to initialize bsValue to null)) -->
    <!-- if bsValue initialized (not null) : we show this input -->
    <input
      #dp
      (bsValueChange)="sendSelectedDate($event)"
      *ngIf="dateValue !== null"
      [bsConfig]="{
        isAnimated: true,
        adaptivePosition: true,
        showWeekNumbers: false,
        containerClass: 'theme-blue',
        dateInputFormat: 'DD/MM/YYYY'
      }"
      [bsValue]="dateValue"
      [isDisabled]="datePickerIsDisabled"
      [maxDate]="maxDateDatePicker"
      [minDate]="minDateDatePicker"
      [ngClass]="{ 'is-invalid': dateError === true }"
      [outsideClick]="true"
      bsDatepicker
      class="form-control select-date"
      placeholder="Date d'effet"
      type="text"
    />

    <!-- if bsValue not initialized (== null) : we show this input -->
    <input
      #dp
      (bsValueChange)="sendSelectedDate($event)"
      *ngIf="dateValue === null"
      [bsConfig]="{
        isAnimated: true,
        adaptivePosition: true,
        showWeekNumbers: false,
        containerClass: 'theme-blue',
        dateInputFormat: 'DD/MM/YYYY'
      }"
      [isDisabled]="datePickerIsDisabled"
      [maxDate]="maxDateDatePicker"
      [minDate]="minDateDatePicker"
      [ngClass]="{ 'is-invalid': dateError === true }"
      [outsideClick]="true"
      bsDatepicker
      class="form-control select-date"
      placeholder="Date d'effet"
      type="text"
    />
    <div class="input-group-prepend d-inline-block">
      <span (click)="openDatepicker()" class="btn btn-info datepicker-calendar">
        <i class="bi bi-calendar-date"></i>
      </span>
    </div>
  </div>
</ng-container>

<!-- data.type === 'removeItem'-->
<ng-container *ngIf="data.action && data.action === 'removeItem'">
  <button (click)="clickButton(data)" class="btn remove-item">
    <i class="bi-trash"></i>
  </button>
</ng-container>
