import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {combineLatest, Observable, Subject} from 'rxjs'
import {DataTableColsDescription, DataTableComponent, DataTableOptions,} from 'src/app/shared/ui/data-table/data-table.component'
import {ComiteMembreService} from 'src/app/shared/data-access/http/comite-membre.service'
import {map, mapTo} from 'rxjs/operators'
import {saveAs} from 'file-saver'
import {ToastrService} from 'ngx-toastr'
import * as moment from 'moment'
import {AsyncPipe, NgIf} from '@angular/common'

@Component({
    selector: 'app-comite-list',
    templateUrl: './comite-list.component.html',
    styleUrls: ['./comite-list.component.scss'],
    standalone: true,
    imports: [NgIf, DataTableComponent, AsyncPipe],
})
export class ComiteListComponent implements OnInit, OnDestroy {
    @Input() withSuppression = false
    @ViewChild('dataTableComponent')
    dataTableComponent!: DataTableComponent
    @Input() provenance!: string
    public codeComite = ''
    public resultsNumber = 0
    comiteMembreLoading$!: Observable<boolean>
    public resultsToShow$!: Observable<any>
    public tableOptionsResults!: DataTableOptions
    public exportIsSubmitting: boolean = false
    private unsubscribe$ = new Subject<void>()

    constructor(
        private comiteMembreService: ComiteMembreService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        const colNamesSearchResults: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Entité',
                fields: ['entite.nomEntiteAffectation'],
                sortField: 'entite.nomEntiteAffectation',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['emploi.nomEmploi'],
                sortField: 'emploi.nomEmploi',
                className: '',
                tooltip: '',
            },
        ]
        if (this.withSuppression) {
            this.tableOptionsResults = {
                showSorting: false,
                showHead: true,
                headClassName: 'light-head-table',
                containerClassName: 'container-fixed-height2',
                rows: {
                    action: 'removeItem',
                    tooltip: '',
                    conditionField: 'azerty',
                    conditionValue: undefined,
                    fieldForAction: 'matriculeCollaborateur',
                },
                cols: colNamesSearchResults,
            }
        } else {
            this.tableOptionsResults = {
                showSorting: false,
                showHead: true,
                headClassName: 'light-head-table',
                containerClassName: 'container-fixed-height2',
                rows: undefined,
                cols: colNamesSearchResults,
            }
        }
    }

    getMembresComite(codeComite: string): void {
        this.codeComite = codeComite
        this.resultsToShow$ = this.comiteMembreService.getByCodeComite(codeComite)
        this.comiteMembreLoading$ = combineLatest([
            this.resultsToShow$.pipe(
                map((results: any[]) => (this.resultsNumber = results.length)),
                map(() => true), // Emit true for each value from this.resultsToShow$
                mapTo(false) // Emit false when this.resultsToShow$ completes
            ),
        ]).pipe(map(([isRunning]) => isRunning))

        if (this.dataTableComponent !== undefined) {
            this.dataTableComponent.ngOnInit()
        }
    }

    public removeCollaborateur(event: any) {
        this.comiteMembreService
            .deleteMembre(this.codeComite, event?.valueForAction)
            .subscribe(() => this.getMembresComite(this.codeComite))
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    exporterCSV(codeComite: string): void {
        this.exportIsSubmitting = true
        if (this.codeComite !== undefined) {
            this.comiteMembreService.exportCsvByCodeComite(this.codeComite).subscribe({
                next: (response) => {
                    if (response && response.headers) {
                        const today = moment(new Date().setHours(0, 0, 0, 0)).format('DD/MM/YYYY')
                        const filename = 'export-comites_' + this.codeComite + '_' + today + '.csv'
                        const body: Blob | null = response.body
                        if (body != null) {
                            saveAs(body, filename)
                            this.toastr.success('Comités Exportés au format CSV')
                        } else {
                            this.toastr.error("Erreur lors de l'export CSV des comités")
                        }
                        this.exportIsSubmitting = false
                    }
                },
                error: () => {
                    this.toastr.error("Erreur lors de l'export CSV des comités")
                    this.exportIsSubmitting = false
                },
            })
        }
    }
}
