import {
    AfterContentChecked,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal'
import { NgClass } from '@angular/common'

export interface modalConfigModel {
    keyboard?: boolean
    backdrop?: boolean
    ignoreBackdropClick?: boolean
    animated?: boolean
}

@Component({
    selector: 'app-custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss'],
    standalone: true,
    imports: [ModalModule, NgClass],
})
export class CustomModalComponent implements OnInit, AfterContentChecked {
    public modalRef!: BsModalRef
    @ViewChild(ModalDirective, { static: false }) modal!: ModalDirective

    defaultConfig = {
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: false,
        animated: true,
        class: 'modal-lg',
    }
    public config: any
    public initializedLifeCycle: boolean = false

    @Input() modalIsVisible: boolean
    @Input() modalConfig!: modalConfigModel
    @Input() modalSize: string
    @Input() modalTitle: string
    @Output() modalStatusBack = new EventEmitter<any>()
    @Output() modalClose = new EventEmitter<void>()

    constructor() {
        this.modalIsVisible = false
        this.modalSize = 'modal-lg'
        this.modalTitle = 'Titre modal'
    }

    ngOnInit(): void {}

    ngAfterContentChecked(): void {
        this.initializedLifeCycle = true
        this.config = { ...this.defaultConfig, ...this.modalConfig }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.initializedLifeCycle) {
            if (changes['modalIsVisible'] && changes['modalIsVisible'].currentValue === true) {
                this.modal.show()
            }
            if (changes['modalIsVisible'] && changes['modalIsVisible'].currentValue === false) {
                this.modal.hide()
            }
        }
    }

    public sendToParent(event: any) {
        this.modalStatusBack.emit(false)
    }

    public onClicClose(){
        this.modal.hide()
        this.modalClose.emit()
    }

}
