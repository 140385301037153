<div class="container position-relative portailrh-container-page">
  <div class="row">
    <div class="col-12">
      <h2 class="mb-4">Sélectionner un comité</h2>
    </div>
  </div>
  <app-filters [comiteListComponent]="comiteList" provenance="comites-membre"></app-filters>
  <app-comite-list #comiteList></app-comite-list>
</div>

