import {inject, Injectable} from '@angular/core'
import {switchMap, tap} from "rxjs/operators";
import {from, Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {authConfig} from "../configuration/auth-config";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {TypeToast} from "../../shared/ui/toast/state/toast.state";
import {UserState} from "../data-access/state/user.state";
import {UserDto} from "../../shared/models/userDto";
import {OAuthService} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import {UserService} from "../../shared/data-access/http/user.service";
import {ToastDomainService} from "../../shared/ui/toast/domain/toast.domain";

@Injectable({providedIn: 'root'})
export class UserDomainService {
    private readonly userState = inject(UserState)
    user$ = this.userState.store.select(UserState.getUser)
    private readonly toastDomainService = inject(ToastDomainService)
    private readonly oauthService = inject(OAuthService)
    private readonly router = inject(Router)
    private readonly userService = inject(UserService)

    setUser(user: UserDto) {


        this.userState.store.update((state) => ({
            ...state,
            user: user,
            loading: false,
            isAuthenticated: true
        }))

        return of(void 0)
    }

    loadingUser() {
        this.userState.store.update((state) => ({
            ...state,
            loading: true,
        }))

        return of(void 0)
    }

    userLoaded() {
        return this.userState.store.update((state) => ({
            ...state,
            loading: false,
        }))
    }

    initUser() {
        return this.authentication().pipe(
            tap(() => {
                const tokenTimeStamp = localStorage.getItem('access_token_stored_at') || ''
                if (tokenTimeStamp !== null && new Date().getTime() - parseInt(tokenTimeStamp) < 1000) {
                    this.toastDomainService.addToastMessage(TypeToast.success, 'Vous êtes connecté', '')
                }
            })
        )
    }

    /**
     * Gestion de l'authentification OAuth2 :
     * - Récupération du code d'authorisation (initImplicitFlow)
     * - Récupération de la token (tryLogin)
     * - Redirection vers la page précédente
     * Le rafraichissement de la token est gérer par la classe HttpRequestInterceptor.
     */
    private authentication(): Observable<any> {
        this.oauthService.clientId = environment.clientId
        this.oauthService.configure(authConfig)
        this.oauthService.tokenValidationHandler = new JwksValidationHandler()
        this.oauthService.clientId = environment.clientId
        this.oauthService.loginUrl = environment.loginUrl
        this.oauthService.issuer = environment.issuer
        this.oauthService.logoutUrl = environment.logoutUrl
        this.oauthService.tokenEndpoint = environment.tokenEndpoint
        this.oauthService.userinfoEndpoint = environment.userinfoEndpoint
        this.oauthService.jwks = environment.jwks

        this.oauthService.setStorage(localStorage)
        return from(this.oauthService.tryLogin()).pipe(
            switchMap((_) => {
                if (!this.oauthService.hasValidIdToken()) {
                    // Init Flow
                    localStorage.setItem('redirectUrl', this.router.url)
                    this.oauthService.initCodeFlow()
                    return of(null)
                } else {
                    // OAuth2 Token OK, récupération de la session utilisateur à partir du token
                    return this.loadUser(this.oauthService.getIdToken())
                }
            })
        )
    }

    private loadUser(token: string) {

        return this.loadingUser().pipe(
            switchMap((_) => this.userService.getConnectedUser(token)),
            switchMap((utilisateur: any) => this.setUser(utilisateur)),
            switchMap((_) => {
                const redirectUrl = localStorage.getItem('redirectUrl')
                if (redirectUrl && redirectUrl !== 'undefined' && redirectUrl !== this.router.url) {
                    localStorage.setItem('redirectUrl', '')
                    this.router.navigateByUrl(redirectUrl)
                }
                return of(null)
            })
        )
    }
}
