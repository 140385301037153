import {Component, inject, OnInit} from '@angular/core'
import OrgChart from '@balkangraph/orgchart.js'
import {Observable, Subject} from 'rxjs'
import {NgxSpinnerService} from 'ngx-spinner'
import {filter, takeUntil, tap} from 'rxjs/operators'
import {RouterOutlet} from '@angular/router'
import {AsyncPipe, NgClass, NgFor, NgStyle} from '@angular/common'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {UserStateModel} from "src/app/core/data-access/state/user.state";
import {SalarieOrganigrammeDto} from "src/app/organigramme/data-access/http/dto/SalarieOrganigrammeDto";
import {ComiteOrganigrammeDto} from "src/app/organigramme/data-access/http/dto/ComiteOrganigrammeDto";
import {SalarieComiteDto} from "src/app/organigramme/data-access/http/dto/SalarieComiteDto";
import {OrganigrammeService} from "../../data-access/http/organigramme.service";
import {UserDomainService} from "../../../core/domain/user.domain";

@Component({
    selector: 'app-organigramme',
    templateUrl: './organigramme.component.html',
    styleUrls: ['./organigramme.component.scss'],
    standalone: true,
    imports: [
        BreadcrumbsModule,
        NgFor,
        NgStyle,
        NgClass,
        RouterOutlet,
        AsyncPipe,
    ],
})
export class OrganigrammeComponent implements OnInit {
    public idSpinnerHeaderUser = 'idSpinnerHeaderUser'
    salarieOrganigramme: SalarieOrganigrammeDto[] = []
    comiteOrganigramme: ComiteOrganigrammeDto[] = []
    comite: ComiteOrganigrammeDto[] = []
    comiteSalarie: SalarieComiteDto[] = []
    salarieParDefaut: number[] = []
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private unsubscribe$ = new Subject<void>()

    constructor(private organigrammeService: OrganigrammeService, private spinner: NgxSpinnerService) {
        console.log("ORGANIGRAMME")
    }

    ngOnInit() {
        const salariepost = this

        const editForm = function (this: any) {
            this.nodeId = null
        }

        editForm.prototype.init = function (obj: any) {
            const that = this
            this.obj = obj

            this.editForm = document.getElementById('editForm')

            this.idInput = document.getElementById('id')
            this.idUtilisateurEnCoursInput = document.getElementById('idUtilisateurEnCours')

            this.prenomNomInput = document.getElementById('prenomNom')
            this.titleInput = document.getElementById('title')
            this.entiteInput = document.getElementById('entite')

            this.emploiInput = document.getElementById('emploi')
            this.missionCollaborateurInput = document.getElementById('missionCollaborateur')
            this.perimetreCollaborateurInput = document.getElementById('perimetreCollaborateur')

            this.abscisseInput = document.getElementById('abscisse')
            this.abscisseemploiInput = document.getElementById('abscisseemploi')
            this.abscisseCollaborateurInput = document.getElementById('abscisseCollaborateur')

            this.afficheboutonsaveInput = document.getElementById('afficheboutonsave')
            this.afficheabscisseInput = document.getElementById('afficheabscisse')
            this.modifcationcomiteInput = document.getElementById('modifcationcomite')
            this.urlphotoInput = document.getElementById('urlphoto')

            this.delegataireInput = document.getElementById('delegataire')

            this.delegataireOuiInput = document.getElementById('delegataire_oui')
            this.delegataireNonInput = document.getElementById('delegataire_non')

            this.urlDelegataireInput = document.getElementById('urlDelegataire')

            const classUrlDelegataireElement = <HTMLFormElement>document.getElementById('classUrlDelegataire')
            const formElementperimetreCollaborateur = <HTMLFormElement>document.getElementById('perimetreCollaborateur')

            this.delegataireOuiInput.addEventListener('click', () => {
                const formElementMessageErreur = <HTMLFormElement>document.getElementById('messageObligatoire')
                formElementMessageErreur.style.display = 'none'
                const delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked) {
                    classUrlDelegataireElement.style.display = 'block'
                    formElementperimetreCollaborateur.setAttribute('placeholder', 'Obligatoire : saisir son périmètre')
                } else {
                    classUrlDelegataireElement.style.display = 'none'
                }
            })

            this.delegataireNonInput.addEventListener('click', () => {
                const formElementMessageErreur = <HTMLFormElement>document.getElementById('messageObligatoire')
                formElementMessageErreur.style.display = 'none'
                const delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked) {
                    classUrlDelegataireElement.style.display = 'block'
                } else {
                    classUrlDelegataireElement.style.display = 'none'
                    formElementperimetreCollaborateur.setAttribute('placeholder', 'Facultatif : saisir son périmètre')
                }
            })

            this.libelleDelegataireInput = document.getElementById('libelleDelegataire')
            this.clickUrlDelegataireInput = document.getElementById('clickUrlDelegataire')

            this.clickUrlDelegataireInput.addEventListener('click', () => {
                const delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked && this.urlDelegataireInput.value) {
                    window.open(this.urlDelegataireInput.value, '_blank')?.focus()
                }
            })

            this.cancelButton = document.getElementById('cancel')
            if (this.cancelButton != null) {
                this.cancelButton.addEventListener('click', function () {
                    that.hide()
                })
            }

            this.saveButton = document.getElementById('save')
            const formElement = <HTMLFormElement>document.getElementById('save')
            formElement.style.display = 'block'

            this.saveButton.addEventListener('click', () => {
                let node: any
                let bSave = true

                node = chart.get(that.nodeId)
                node.missionCollaborateur = that.missionCollaborateurInput.value
                node.perimetreCollaborateur = that.perimetreCollaborateurInput.value
                node.abscisseCollaborateur = that.abscisseCollaborateurInput.value
                node.title = node.missionCollaborateur != '' ? node.missionCollaborateur : that.emploiInput.value
                node.abscisse =
                    node.abscisseCollaborateur != '' ? node.abscisseCollaborateur : that.abscisseemploiInput.value

                const formElementMessageErreur = <HTMLFormElement>document.getElementById('messageObligatoire')
                formElementMessageErreur.style.display = 'block'

                const elementDelegataire = <HTMLInputElement>document.getElementById('delegataire_oui')
                node.delegataire = elementDelegataire.checked ? true : false
                node.libelleDelegataire = elementDelegataire.checked ? '(D)' : ''

                if (node.delegataire) {
                    node.urlDelegataire = that.urlDelegataireInput.value
                    if (node.perimetreCollaborateur == '') {
                        bSave = false
                    }
                } else {
                    node.urlDelegataire = ''
                    that.urlDelegataireInput.value = ''
                }

                for (var index in node.comites) {
                    const nom = 'comite' + index
                    const element = <HTMLInputElement>document.getElementById(nom)
                    node.comites[index].checkboxComite = element.checked

                    const couleur = node.comites[index].codeCouleur
                    const ordre = node.comites[index].ordreAffichage
                    const check = node.comites[index].checkboxComite
                    const hierarchie = node.comites[index].hierachieOrganigramme
                    const checkComite0 = node.comites[0].checkboxComite

                    if (node.comites[index].afficheOrganigramme) {
                        majComiteOrganigramme(node, check, couleur, ordre, hierarchie, checkComite0, index)
                    }
                }

                for (var index in node.tags) {
                    if (!isNumber(node.tags[index].value)) {
                        node.tags[index] = subTreeCollaborateur(node.abscisse, node.abscissemanager, node.pdgmanager)
                    }
                }

                if (bSave) {
                    formElementMessageErreur.style.display = 'none'
                    chart.updateNode(node)
                    chart.draw()
                    salariepost.organigrammeService
                        .saveFiche(node)
                        .subscribe({next: (r) => (this.result = r), error: (e) => (this.error = e)})
                    that.hide()
                }
            })
        }

        editForm.prototype.show = function (nodeId: number) {
            let node: any
            node = chart.get(nodeId)

            const formElementMessageErreur = <HTMLFormElement>document.getElementById('messageObligatoire')
            formElementMessageErreur.style.display = 'none'

            const formElementPhoto = <HTMLImageElement>document.getElementById('urlphoto')
            formElementPhoto.src = node.urlphoto

            var formClassUrlDelegataire = <HTMLFormElement>document.getElementById('classUrlDelegataire')
            formClassUrlDelegataire.className = 'boc-form-field'

            var formElementUrlDelegataire = <HTMLFormElement>document.getElementById('urlDelegataire')
            formElementUrlDelegataire.removeAttribute('readonly')
            formElementUrlDelegataire.setAttribute('placeholder', 'Facultatif : saisir son url')
            formElementUrlDelegataire.style.backgroundColor = '#FFFFFF'
            formElementUrlDelegataire.style.color = '#B5B5B5'
            formElementUrlDelegataire.className = ''

            const formElementUrlDelegatairelabel = <HTMLFormElement>document.getElementById('labelUrlDelegataire')
            formElementUrlDelegatairelabel.style.color = '#B5B5B5'
            if (node.delegataire) {
                var formElementDelegataireOui = <HTMLFormElement>document.getElementById('delegataire_oui')
                formElementDelegataireOui['checked'] = true

                var formElementDelegataireNon = <HTMLFormElement>document.getElementById('delegataire_non')
                formElementDelegataireNon['checked'] = false

                var formElementperimetreCollaborateur = <HTMLFormElement>(
                    document.getElementById('perimetreCollaborateur')
                )

                var classUrlDelegataireElement = <HTMLFormElement>document.getElementById('classUrlDelegataire')
                var delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked && nodeId == node.idUtilisateurEnCours) {
                    classUrlDelegataireElement.style.display = 'block'
                } else {
                    classUrlDelegataireElement.style.display = 'none'
                }
            } else {
                var formElementDelegataireOui = <HTMLFormElement>document.getElementById('delegataire_oui')
                formElementDelegataireOui['checked'] = false

                var formElementDelegataireNon = <HTMLFormElement>document.getElementById('delegataire_non')
                formElementDelegataireNon['checked'] = true

                var classUrlDelegataireElement = <HTMLFormElement>document.getElementById('classUrlDelegataire')
                classUrlDelegataireElement.style.display = 'none'
            }

            if (node.missionsave) {
                var formClassMissionCollaborateur = <HTMLFormElement>document.getElementById('classMission')
                formClassMissionCollaborateur.className = 'boc-form-field'

                var formElementMision = <HTMLFormElement>document.getElementById('missionCollaborateur')
                formElementMision.removeAttribute('readonly')
                formElementMision.setAttribute('placeholder', 'Ou : Saisir sa fonction')
                formElementMision.style.backgroundColor = '#FFFFFF'
                formElementMision.style.color = '#B5B5B5'
                formElementMision.className = ''

                var formElementMisionlabel = <HTMLFormElement>document.getElementById('labelmissionCollaborateur')
                formElementMisionlabel.style.color = '#B5B5B5'

                var formClassDelegataireCollaborateur = <HTMLFormElement>document.getElementById('classDelegataire')
                formClassDelegataireCollaborateur.className = 'boc-form-field'

                var formBlocDelegataireCollaborateur = <HTMLFormElement>document.getElementById('blocDelegataire')
                formBlocDelegataireCollaborateur.className = 'boc-input-delegataire'

                var formElementdelegataireCollaborateur = <HTMLFormElement>document.getElementById('delegataire_oui')
                formElementdelegataireCollaborateur.removeAttribute('readonly')
                formElementdelegataireCollaborateur.className = 'radiomark'

                var formElementdelegataireCollaborateur = <HTMLFormElement>(
                    document.getElementById('labelDelegataire_oui')
                )
                formElementdelegataireCollaborateur.className = 'radio-text-delegataire'

                var formElementdelegataireCollaborateur = <HTMLFormElement>document.getElementById('delegataire_non')
                formElementdelegataireCollaborateur.removeAttribute('readonly')
                formElementdelegataireCollaborateur.className = 'radiomark'

                var formElementdelegataireCollaborateur = <HTMLFormElement>(
                    document.getElementById('labelDelegataire_non')
                )
                formElementdelegataireCollaborateur.className = 'radio-text-delegataire'

                var formElementDelegataireCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labeldelegataireCollaborateur')
                )
                formElementDelegataireCollaborateurlabel.style.color = '#B5B5B5'

                var classUrlDelegataireElement = <HTMLFormElement>document.getElementById('classUrlDelegataire')
                var delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked) {
                    classUrlDelegataireElement.style.display = 'block'
                } else {
                    classUrlDelegataireElement.style.display = 'none'
                }
            } else {
                var formClassMissionCollaborateur = <HTMLFormElement>document.getElementById('classMission')
                formClassMissionCollaborateur.className = 'boc-form-field-nm'

                var formElementMision = <HTMLFormElement>document.getElementById('missionCollaborateur')
                formElementMision.setAttribute('readonly', 'readonly')
                formElementMision.setAttribute('placeholder', '')
                formElementMision.style.backgroundColor = '#999999'
                formElementMision.style.color = '#FFFFFF'
                formElementMision.className = 'textnm-petit'

                var formElementMisionlabel = <HTMLFormElement>document.getElementById('labelmissionCollaborateur')
                formElementMisionlabel.style.color = '#FFFFFF'

                var formClassDelegataireCollaborateur = <HTMLFormElement>document.getElementById('classDelegataire')
                formClassDelegataireCollaborateur.className = 'boc-form-field-nm'

                var formClassUrlDelegataire = <HTMLFormElement>document.getElementById('classUrlDelegataire')
                formClassUrlDelegataire.className = 'boc-form-field-nm'

                var formElementUrlDelegataire = <HTMLFormElement>document.getElementById('urlDelegataire')
                formElementUrlDelegataire.setAttribute('readonly', 'readonly')
                formElementUrlDelegataire.setAttribute('placeholder', '')
                formElementUrlDelegataire.style.backgroundColor = '#999999'
                formElementUrlDelegataire.style.color = '#FFFFFF'
                formElementUrlDelegataire.className = 'textnm-petit'

                const formElementLabelUrlDelegataire = <HTMLFormElement>document.getElementById('labelUrlDelegataire')
                formElementLabelUrlDelegataire.style.color = '#FFFFFF'

                var formBlocDelegataireCollaborateur = <HTMLFormElement>document.getElementById('blocDelegataire')
                formBlocDelegataireCollaborateur.className = ''

                var formElementDelegataireCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labeldelegataireCollaborateur')
                )
                formElementDelegataireCollaborateurlabel.style.backgroundColor = '#999999'
                formElementDelegataireCollaborateurlabel.style.color = '#FFFFFF'

                var formElementdelegataireCollaborateur = <HTMLFormElement>(
                    document.getElementById('labelDelegataire_oui')
                )
                formElementdelegataireCollaborateur.className = 'radio-text-delegataire-disabled'

                var formElementdelegataireCollaborateur = <HTMLFormElement>document.getElementById('delegataire_oui')
                formElementdelegataireCollaborateur.setAttribute('readonly', 'readonly')
                formElementdelegataireCollaborateur.className = 'radiomark-disabled'

                var formElementdelegataireCollaborateur = <HTMLFormElement>document.getElementById('delegataire_non')
                formElementdelegataireCollaborateur.setAttribute('readonly', 'readonly')
                formElementdelegataireCollaborateur.className = 'radiomark-disabled'

                var formElementdelegataireCollaborateur = <HTMLFormElement>(
                    document.getElementById('labelDelegataire_non')
                )
                formElementdelegataireCollaborateur.className = 'radio-text-delegataire-disabled'

                var classUrlDelegataireElement = <HTMLFormElement>document.getElementById('classUrlDelegataire')
                var delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked && nodeId == node.idUtilisateurEnCours) {
                    classUrlDelegataireElement.style.display = 'block'
                } else {
                    classUrlDelegataireElement.style.display = 'none'
                }
            }

            if (node.afficheboutonsave) {
                var formClassPerimetreCollaborateur = <HTMLFormElement>document.getElementById('classPerimetre')
                formClassPerimetreCollaborateur.className = 'boc-form-field'

                var formElementperimetreCollaborateur = <HTMLFormElement>(
                    document.getElementById('perimetreCollaborateur')
                )
                formElementperimetreCollaborateur.removeAttribute('readonly')
                var delegataireChecked = <HTMLInputElement>this.delegataireOuiInput.checked ? true : false
                if (delegataireChecked) {
                    formElementperimetreCollaborateur.setAttribute('placeholder', 'Obligatoire : saisir son périmètre')
                } else {
                    formElementperimetreCollaborateur.setAttribute('placeholder', 'Facultatif : saisir son périmètre')
                }
                formElementperimetreCollaborateur.style.backgroundColor = '#FFFFFF'
                formElementperimetreCollaborateur.style.color = '#B5B5B5'
                formElementperimetreCollaborateur.className = ''

                var formElementperimetreCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labelperimetreCollaborateur')
                )
                formElementperimetreCollaborateurlabel.style.color = '#B5B5B5'

                var formElementabscisseCollaborateur = <HTMLFormElement>document.getElementById('abscisseCollaborateur')
                formElementabscisseCollaborateur.removeAttribute('readonly')
                formElementabscisseCollaborateur.style.backgroundColor = '#FFFFFF'
                formElementabscisseCollaborateur.style.color = '#B5B5B5'

                var formElementabscisseCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labelabscisseCollaborateur')
                )
                formElementabscisseCollaborateurlabel.style.color = '#B5B5B5'

                const formElementSave = <HTMLFormElement>document.getElementById('save')
                formElementSave.style.display = 'block'
            } else {
                const formElement = <HTMLFormElement>document.getElementById('save')
                formElement.style.display = 'none'

                var formClassPerimetreCollaborateur = <HTMLFormElement>document.getElementById('classPerimetre')
                formClassPerimetreCollaborateur.className = 'boc-form-field-nm'

                var formElementperimetreCollaborateur = <HTMLFormElement>(
                    document.getElementById('perimetreCollaborateur')
                )
                formElementperimetreCollaborateur.setAttribute('readonly', 'readonly')
                formElementperimetreCollaborateur.setAttribute('placeholder', '')
                formElementperimetreCollaborateur.style.backgroundColor = '#999999'
                formElementperimetreCollaborateur.style.color = '#FFFFFF'
                formElementperimetreCollaborateur.className = 'textnm-petit'

                var formElementperimetreCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labelperimetreCollaborateur')
                )
                formElementperimetreCollaborateurlabel.style.color = '#FFFFFF'

                var formElementabscisseCollaborateur = <HTMLFormElement>document.getElementById('abscisseCollaborateur')
                formElementabscisseCollaborateur.setAttribute('readonly', 'readonly')
                formElementabscisseCollaborateur.style.backgroundColor = '#999999'
                formElementabscisseCollaborateur.style.color = '#FFFFFF'

                var formElementabscisseCollaborateurlabel = <HTMLFormElement>(
                    document.getElementById('labelabscisseCollaborateur')
                )
                formElementabscisseCollaborateurlabel.style.color = '#FFFFFF'
            }

            const that = this
            this.hide()
            this.nodeId = nodeId

            const left = document.body.offsetWidth / 2 - 150

            this.editForm.style.display = 'block'
            this.editForm.style.left = left + 'px'
            this.editForm.style.height = '790px'

            this.afficheboutonsaveInput.value = node.afficheboutonsave
            this.idInput.value = nodeId
            this.prenomNomInput.value = node.prenomNom
            this.titleInput.value = node.title
            this.entiteInput.value = node.entite
            this.emploiInput.value = node.emploi
            this.missionCollaborateurInput.value = node.missionCollaborateur
            this.perimetreCollaborateurInput.value = node.perimetreCollaborateur
            this.abscisseemploiInput.value = node.abscisseemploi
            this.abscisseCollaborateurInput.value = node.abscisseCollaborateur

            this.abscisseInput.value = node.abscisse
            this.idUtilisateurEnCoursInput.value = node.idUtilisateurEnCours
            this.afficheabscisseInput.value = node.afficheabscisse
            this.modifcationcomiteInput.value = node.modifcationcomite

            this.libelleDelegataireInput.value = node.libelleDelegataire
            this.urlDelegataireInput.value = node.urlDelegataire

            this.comiteSalarie = node.comites

            for (const index in node.comites) {
                const nom = 'comite' + index
                const element = <HTMLInputElement>document.getElementById(nom)
                element.checked = node.comites[index].checkboxComite
            }
        }

        editForm.prototype.hide = function () {
            this.editForm.style.display = 'none'
        }

        OrgChart.templates['myTeam'] = Object.assign({}, OrgChart.templates['olivia'])
        OrgChart.templates['myTeam'].size = [350, 130]

        OrgChart.templates['myTeam'].node =
            '<rect fill="url(#myteam_gradient)" x="0" y="0" height="130" width="350" stroke-width="1" stroke="#D2D2D2" rx="7" ry="7"></rect>'
        //OrgChart.templates.myTeam.node = '<rect fill="#F0ECE4" x="0" y="0" height="130" width="350" stroke-width="1" stroke="#FFFFFF" rx="7" ry="7"></rect>';

        OrgChart.templates['myTeam'].menuButton =
            '<div style="position:absolute;right:30px;top:34px; width:30px;height:50px;cursor:pointer;" data-ctrl-menu="">' +
            '<div class="orga_menu" style="background-color: #7A7A7A; height: 3px; border: none;"></div>' +
            '<div class="orga_menu" style="background-color: #7A7A7A; height: 3px; border: none;"></div>' +
            '<div class="orga_menu" style="background-color: #7A7A7A; height: 3px; border: none;"></div>' +
            '</div>'

        OrgChart.templates['myTeam'].plus =
            '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>' +
            '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>'

        OrgChart.templates['myTeam'].img_0 =
            '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="12" y="10"  width="80" height="110"></image>' +
            '<rect fill="none" stroke="#FFFFFF" stroke-width="2" x="12" y="10" rx="3" ry="3" width="80" height="110"></rect>'

        OrgChart.templates['myTeam']['field_0'] =
            '<text class="orga_field0" data-width="250" data-text-overflow="ellipsis" x="220" y="30" text-anchor="middle">{val}</text>'

        OrgChart.templates['myTeam']['field_1'] =
            '<text class="orga_field1" data-width="250" data-text-overflow="ellipsis" x="220" y="50" text-anchor="middle">{val}</text>'

        OrgChart.templates['myTeam']['field_2'] =
            '<text class="orga_field2" data-width="250" data-text-overflow="ellipsis" x="220" y="90" text-anchor="middle">{val}</text>'

        OrgChart.templates['myTeam']['field_3'] =
            '<text class="orga_field3" data-width="250" data-text-overflow="ellipsis" x="220" y="115" text-anchor="middle">{val}</text>'

        OrgChart.templates['myTeam']['field_6'] =
            '<text class="orga_field6" data-width="250" data-text-overflow="ellipsis" x="340" y="15" text-anchor="middle">{val}</text>'

        OrgChart.templates['myTeam']['pastille_1'] = '<circle cx="0" cy="0" r="10"  fill="{val}"></circle>'
        OrgChart.templates['myTeam']['pastille_2'] = '<circle cx="0" cy="22" r="10" fill="{val}" ></circle>'
        OrgChart.templates['myTeam']['pastille_3'] = '<circle cx="0" cy="44" r="10" fill="{val}"></circle>'
        OrgChart.templates['myTeam']['pastille_4'] = '<circle cx="0" cy="66" r="10" fill="{val}"></circle>'
        OrgChart.templates['myTeam']['pastille_5'] = '<circle cx="0" cy="88" r="10" fill="{val}"></circle>'
        OrgChart.templates['myTeam']['pastille_6'] = '<circle cx="0" cy="110" r="10" fill="{val}"></circle>'
        OrgChart.templates['myTeam']['pastille_7'] = '<circle cx="350" cy="130" r="10" fill="{val}"></circle>'
        ;(OrgChart.LAZY_LOADING = true), (OrgChart.RES.IT_IS_LONELY_HERE_LINK = "Préparation de l'organigramme...")
        OrgChart.SEARCH_PLACEHOLDER = 'Rechercher un collaborateur'

        const tree = document.getElementById('tree')

        if (tree) {
            var chart: any

            chart = new OrgChart(tree, {
                anim: {func: OrgChart.anim.outCirc, duration: 500},
                zoom: {speed: 100, smooth: 5},
                levelSeparation: 100,
                showXScroll: true,
                showYScroll: true,

                /*
menu: {
    pdf: { text: "Export PDF" }
},
*/

                //filterBy: ['entite'],

                scaleInitial: OrgChart.match.boundary,
                align: OrgChart.align.orientation,
                lazyLoading: true,
                template: 'myTeam',
                enableDragDrop: false,
                enableSearch: true,
                orientation: OrgChart.orientation.left_top,
                mouseScrool: OrgChart.action.zoom,
                editUI: new (editForm as any)(),
                searchFields: ['namesearch', 'prenomNom'],
                toolbar: {
                    //fullScreen: true,
                    zoom: true,
                    fit: true,
                    // expandAll: true
                },
                nodeBinding: {
                    img_0: 'urlphoto',
                    field_0: 'prenomNom',
                    field_1: 'title',
                    field_2: 'perimetreCollaborateur',
                    field_3: 'entite',
                    field_4: 'abscisse',
                    field_5: 'subtree',
                    field_6: 'libelleDelegataire',
                    field_7: 'urlDelegataire',
                    pastille_1: 'comite1',
                    pastille_2: 'comite2',
                    pastille_3: 'comite3',
                    pastille_4: 'comite4',
                    pastille_5: 'comite5',
                    pastille_6: 'comite6',
                    pastille_7: 'comite7',
                },
                tags: {
                    '0': {
                        subLevels: 0,
                    },
                    '1': {
                        subLevels: 1,
                    },
                    '2': {
                        subLevels: 2,
                    },
                    '3': {
                        subLevels: 3,
                    },
                    '4': {
                        subLevels: 4,
                    },
                    '5': {
                        subLevels: 5,
                    },
                    '6': {
                        subLevels: 6,
                    },
                    '7': {
                        subLevels: 7,
                    },
                    '8': {
                        subLevels: 8,
                    },
                    '9': {
                        subLevels: 9,
                    },
                    '10': {
                        subLevels: 10,
                    },
                    '11': {
                        subLevels: 11,
                    },
                    '12': {
                        subLevels: 12,
                    },
                    '13': {
                        subLevels: 13,
                    },
                    '14': {
                        subLevels: 14,
                    },
                    '15': {
                        subLevels: 15,
                    },
                },

                collapse: {
                    level: 1,
                    allChildren: true,
                },
            })

            const comiteOrganigrammeObservable = this.organigrammeService.getComiteOrganigramme()

            comiteOrganigrammeObservable.subscribe((comite) => {
                this.comiteOrganigramme = comite as ComiteOrganigrammeDto[]
            })

            const comiteObservable = this.organigrammeService.getComite()
            comiteObservable.subscribe((comite) => {
                this.comite = comite as ComiteOrganigrammeDto[]
            })

            this.user$
                .pipe(
                    takeUntil(this.unsubscribe$),
                    filter((user: UserStateModel) => user.loading === false),
                    tap((_) => this.spinner.hide(this.idSpinnerHeaderUser))
                )
                .subscribe()
        }

        const salarieParDefautObservable = this.organigrammeService.getComiteSalarieParDefaut()
        salarieParDefautObservable.subscribe((salarieParDefaut) => {
            this.salarieParDefaut = salarieParDefaut as number[]
            const organigrammeObservable = this.organigrammeService.getOrganigramme()
            organigrammeObservable.subscribe((salarie) => {
                this.salarieOrganigramme = salarie as SalarieOrganigrammeDto[]
                chart.load(this.salarieOrganigramme)
                chart.expand(null, this.salarieParDefaut)
                chart.fit()
            })
        })
    }
}

function majComiteOrganigramme(
    node: any,
    check: boolean,
    couleur: string,
    ordre: number,
    hierarchie: boolean,
    checkComite0: boolean,
    index: any
) {
    switch (ordre) {
        case 1: {
            node.comite1 = check ? couleur : null
            break
        }
        case 2: {
            node.comite2 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite2 = couleur
                node.comites[index].checkboxComite = true
            }
            break
        }
        case 3: {
            node.comite3 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite3 = couleur
                node.comites[index].checkboxComite = true
            }
            break
        }
        case 4: {
            node.comite4 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite4 = couleur
                node.comites[index].checkboxComite = true
            }
            break
        }
        case 5: {
            node.comite5 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite5 = couleur
                node.comites[index].checkboxComite = true
            }
            break
        }
        case 6: {
            node.comite6 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite6 = couleur
                node.comites[index].checkboxComite = true
            }
            break
        }
        case 7: {
            node.comite7 = check ? couleur : null
            if (hierarchie && checkComite0) {
                node.comite7 = couleur
                node.comites[index].checkboxComite = true
                node.nametri = 'a' + node.nametrisansassistante
            } else {
                node.nametri = 'b' + node.nametrisansassistante
            }
            break
        }

        default: {
            //statements;
            break
        }
    }
}

function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

function subTreeCollaborateur(abscisseCollaborateur: number, abscisseManager: number, pdg: boolean) {
    let level = null
    level = '0'
    let abscisse
    if (abscisseCollaborateur != null && abscisseManager != null) {
        abscisse = abscisseCollaborateur >= 60 ? (abscisseManager <= 20 ? 70 : 60) : abscisseCollaborateur
        if (pdg) {
            if (abscisse == 0) {
                level = '0'
            } else {
                if (abscisse == 1) {
                    level = '1'
                } else {
                    level = (abscisse - (abscisseManager == 1 ? 0 : abscisseManager) + 10) / 10
                }
            }
        } else {
            level = (abscisse - (abscisseManager == 1 ? 10 : abscisseManager)) / 10
        }
    }

    return level
}
