import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { map, take } from 'rxjs/operators'
import { CollaborateurBackDto } from 'src/app/shared/models/collaborateursBackDto'
import { LogsBackDto } from 'src/app/shared/models/LogsBackDto'

@Injectable({
    providedIn: 'root',
})
export class ComiteMembreService {
    constructor(private http: HttpClient) {}

    public deleteMembre(codeComite: string, matricule: string): Observable<boolean> {
        const path = `${environment.baseUrl}${environment.comiteMembreApi}/${codeComite}/salarie/${matricule}`
        return this.http.delete<boolean>(path).pipe(take(1))
    }

    public addMembre(codeComite: string, matricules: Array<string>): Observable<LogsBackDto> {
        const path = `${environment.baseUrl}${environment.comiteMembreApi}/${codeComite}`
        return this.http.post<LogsBackDto>(path, matricules).pipe(take(1))
    }

    public getByCodeComite(codeComite: string): Observable<Array<CollaborateurBackDto>> {
        const path = `${environment.baseUrl}${environment.comiteMembreApi}/${codeComite}/collaborateurs`
        return this.http.get<Array<CollaborateurBackDto>>(path).pipe(
            take(1),
            map((collaborateurs) => collaborateurs.sort((a, b) => a.nomCollaborateur.localeCompare(b.nomCollaborateur)))
        )
    }

    public exportCsvByCodeComite(codeComite: string): Observable<HttpResponse<Blob>> {
        const path = `${environment.baseUrl}${environment.comiteMembreApi}/exporter/csv`
        return this.http.get(path, { params: { codeComite }, observe: 'response', responseType: 'blob' })
    }
}
