import {EquipeGlobaleBackDto} from 'src/app/shared/models/equipeGlobaleBackDto'
import {SortOrSearchObject} from 'src/app/mon-equipe/data-access/state/collaborateurs.state'
import {PaginationDto} from 'src/app/shared/models/paginationDto'
import {CollaborateurBackFullDto} from 'src/app/shared/models/collaborateursBackDto'
import {take} from 'rxjs/operators'
import {environment} from 'src/environments/environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {Injectable} from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class CollaborateursService {
    constructor(private http: HttpClient) {
    }

    /**
     * Get list of all collaborateurs.
     * @returns : Array<CollaborateurDto>
     */
    public getMonEquipe(): Observable<CollaborateurBackFullDto> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.utilisateurApi}/equipe`
        return this.http.get<CollaborateurBackFullDto>(path).pipe(take(1))
    }

    /**
     * TODO: remove dataJson
     * Get equipe globale.
     * @returns : Array<CollaborateurDto>
     */
    public getEquipeGlobale(): Observable<EquipeGlobaleBackDto> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.equipeGlobaleApi}`
        return this.http.get<EquipeGlobaleBackDto>(path).pipe(take(1))
    }

    /**
     * Add collaborateur to DB.
     * @param : object:  relation{
                                  "matriculeCollaborateur": "string",
                                  "matriculeManagerHierarchique": "string",
                                  "dateDebut": "2021-06-02T12:34:42.356Z"
                                }
     * @returns : return relation
     */
    public addCollaborateur(relation: any): Observable<any> {
        return this.http.post<any>(environment.baseUrl + environment.relationApi, relation).pipe(take(1))
    }

    /**
     * searchCollaborateurs : Search in database on criteria
     * @param url : piece of path distinct (environment.collaborateurApi or environment.equipeGlobaleApi)
     * @param searchFields: Array<SortOrSearchObject>|undefined
     *
     * @returns array of results or array empty.
     */
    public searchCollaborateurs(
        url: string,
        searchFields: Array<SortOrSearchObject> | undefined,
        pagination: PaginationDto | undefined,
        sortObject: SortOrSearchObject | undefined
    ): Observable<any> {
        // collaborateur?collaborateur=dar&managerHierarchique=der&entite=dede&pasDeManager=false&page=0&size=20
        let path = ''
        if (searchFields?.length) {
            searchFields.forEach((obj) => {
                path += obj.field + '=' + obj.value + '&'
            })
        }

        pagination?.pageNumber ? (path += 'page=' + pagination?.pageNumber + '&') : (path += 'page=0&')
        pagination?.pageSize ? (path += 'size=' + pagination?.pageSize + '&') : ''

        if (!!sortObject) {
            path += 'sort=' + sortObject.field + ',' + sortObject.value + '&'
        }

        path = path.substring(0, path.length - 1)

        const finalPath = `${environment.baseUrl}${url}?${path}`
        return this.http.get<any>(finalPath).pipe(take(1))
    }
}
