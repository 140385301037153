import { AfterContentChecked, Component, forwardRef, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgStyle } from '@angular/common'

@Component({
    selector: 'app-nice-checkbox',
    templateUrl: './nice-checkbox.component.html',
    styleUrls: ['./nice-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NiceCheckboxComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgStyle],
})
export class NiceCheckboxComponent implements OnInit, AfterContentChecked {
    @Input() label: string = ''
    @Input() checkedColor: string = 'rgb(92,204,0)'
    @Input() isChecked: boolean = false
    @Input() disabled: boolean = false

    public checkedStyle: any

    constructor() {
        this.checkedStyle = { fill: this.checkedColor }
    }

    ngOnInit(): void {}

    ngAfterContentChecked() {
        !!this.checkedColor ? (this.checkedStyle = { fill: this.checkedColor }) : this.checkedStyle
    }

    onChanged(event: any) {
        this.isChecked = event && event.target && event.target.checked
        this.onChange(this.isChecked)
    }

    writeValue(obj: boolean): void {
        this.isChecked = obj
    }

    onChange = (_: any) => {} // No-op

    registerOnChange(fn: any): void {
        // Replace internal function with the one provided
        this.onChange = fn
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    onBlur = (_: any) => {} // No-op

    registerOnTouched(fn: any): void {
        // Call the parent's registered onTouched function, if any.
        // This lets it know that the checkbox lost focus.
        this.onBlur = fn
    }
}
