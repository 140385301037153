import packageJson from '../../../../../package.json'
import {Component, Input} from '@angular/core'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
})
export class FooterComponent {

    version = packageJson.version

    @Input({required:true})
    versionBack: string

}
