import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { SalarieOrganigrammeDto } from "./dto/SalarieOrganigrammeDto";
import { ComiteOrganigrammeDto } from "./dto/ComiteOrganigrammeDto";

@Injectable({
    providedIn: 'root',
})
export class OrganigrammeService {
    private newOrganigramme$: Observable<SalarieOrganigrammeDto[]> | undefined
    private newComiteOrganigramme$: Observable<ComiteOrganigrammeDto[]> | undefined
    private salariesOrganigrammeParDefaut$: Observable<number[]> | undefined

    constructor(private http: HttpClient) {}

    /**
     * Get organigramme.
     * @returns : Array<SalarieOrganigrammeDto>
     */
    public getOrganigramme(): Observable<SalarieOrganigrammeDto[]> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.organigrammeApi}`
        this.newOrganigramme$ = this.http.get<SalarieOrganigrammeDto[]>(path).pipe(take(1))
        return this.newOrganigramme$
    }

    /**
     * Get comite organigramme.
     * @returns : Array<ComiteOrganigrammeDto>
     */
    public getComiteOrganigramme(): Observable<ComiteOrganigrammeDto[]> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.comiteOrganigrammeApi}`
        this.newComiteOrganigramme$ = this.http.get<ComiteOrganigrammeDto[]>(path).pipe(take(1))
        return this.newComiteOrganigramme$
    }

    /**
     * Get comités.
     * @returns : Array<ComiteOrganigrammeDto>
     */
    public getComite(): Observable<ComiteOrganigrammeDto[]> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.comiteApi}`
        this.newComiteOrganigramme$ = this.http.get<ComiteOrganigrammeDto[]>(path).pipe(take(1))
        return this.newComiteOrganigramme$
    }

    /**
     * Get organigramme salaries par defaut à partir d'un comité.
     * @returns : Array<number>
     */
    public getComiteSalarieParDefaut(): Observable<number[]> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const path = `${environment.baseUrl}${environment.organigrammeApi}` + '/comitesalariepardefaut'
        this.salariesOrganigrammeParDefaut$ = this.http.get<number[]>(path).pipe(take(1))
        return this.salariesOrganigrammeParDefaut$
    }

    /**
     *
     * @param salarieOrganigramme
     * @returns
     */
    public saveFiche(postd: SalarieOrganigrammeDto): Observable<any> {
        return this.http.post<any>(environment.baseUrl + environment.collaborateurApi, postd).pipe(take(1))
    }
}
