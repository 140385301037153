import { environment } from 'src/environments/environment'
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { OAuthService } from 'angular-oauth2-oidc'

@Injectable()
export class AuthJWTInterceptor implements HttpInterceptor {
    constructor(private oauthService: OAuthService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (req.url.match(environment.baseUrl)) {
            // Rafrachissement de la token si elle a expiré
            this.refreshToken()
            if (this.oauthService.hasValidIdToken()) {
                const idToken: string | null = localStorage.getItem('id_token')
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ` + idToken,
                    },
                    withCredentials: true,
                })
            } else {
                req = req.clone({
                    withCredentials: true,
                })
            }
        }
        return next.handle(req)
    }

    private async refreshToken(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (+new Date() >= this.oauthService.getAccessTokenExpiration() && this.oauthService.getRefreshToken()) {
                this.oauthService.refreshToken().then((oauthEvent: any) => {
                    resolve()
                })
            } else {
                resolve()
            }
        })
    }
}
