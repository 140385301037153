import {PopoverModule} from 'ngx-bootstrap/popover'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {EquipeDirecteComponent} from 'src/app/mon-equipe/feature/equipe-directe/equipe-directe.component'
import {EquipeGlobaleComponent} from 'src/app/mon-equipe/feature/equipe-globale/equipe-globale.component'
import {NgxSpinnerModule} from 'ngx-spinner'
import {AddCollaborateurComponent} from 'src/app/mon-equipe/feature/add-collaborateur/add-collaborateur.component'
import {TypeaheadModule} from 'ngx-bootstrap/typeahead'
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker'
import {FormsModule} from '@angular/forms'
import {CollapseModule} from 'ngx-bootstrap/collapse'
import {CustomModalModule} from "../shared/ui/custom-modal/custom-modal.module";
import {ListModule} from "../shared/ui/list/list.module";

@NgModule({
    imports: [
        CommonModule,
        CustomModalModule,
        BreadcrumbsModule.forRoot({
            postProcess: null,
            applyDistinctOn: 'text',
        }),
        CollapseModule.forRoot(),
        TypeaheadModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        NgxDatatableModule,
        NgxSpinnerModule,
        FormsModule,
        ListModule,
        EquipeDirecteComponent,
        EquipeGlobaleComponent,
        AddCollaborateurComponent,
    ],
    providers: [],
    exports: [],
})
export class MonEquipeModule {
}
