import {UserDto} from "src/app/shared/models/userDto";
import {Injectable} from "@angular/core";
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "src/environments/environment";

import {catchError, map, take} from "rxjs/operators";
import {RelationDto} from "src/app/shared/models/relationDto";
import * as moment from "moment";
import {ComiteBackDto} from "../../models/comiteBackDto";
import {Router} from "@angular/router";

/**
 * Utilisateur Service.
 */
@Injectable({
    providedIn: 'root',
})
export class UserService {
    public selectedUtilisateurSubject: BehaviorSubject<UserDto | undefined> = new BehaviorSubject<UserDto | undefined>(
        undefined
    )

    constructor(private http: HttpClient,
                private router: Router) {

    }

    public getConnectedUser(accessToken: string): Observable<UserDto> {
        // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + accessToken,
            }),
        }

         return this.http.get<UserDto>(environment.baseUrl + environment.utilisateurApi, httpOptions).pipe(
            catchError(
                err => {
                    this.router.navigate(['/user-not-authenticated'])
                    return EMPTY
                }
            ),

            take(1),
            map((utilisateur: UserDto) => {
                this.selectedUtilisateurSubject.next(this.filterForActiveRelations(utilisateur))
                utilisateur.coman = false
                if (utilisateur.comite?.some((comite: ComiteBackDto) => comite?.codeComite === 'COMAN')) {
                    utilisateur.coman = true
                }

                utilisateur.assistante = false
                if (utilisateur.comite?.some((comite: ComiteBackDto) => comite?.codeComite === 'Assistante')) {
                    utilisateur.assistante = true
                }

                return utilisateur
            })

        )
    }

    /**
     * Filtre des relations de l'utilisateur courant. Seules les relations actives sont conservées
     */
    public filterForActiveRelations(user: UserDto): UserDto {
        user.relation = user.relation?.filter((rel) => this.relationIsActive(rel))
        return user
    }

    /**
     * Une relation est concidérée comme active si
     *  - sa date de début est inférieure ou égale à la date du jour
     *  - sa date de fin est nulle ou supérieure ou égale à la date du jour
     */
    public relationIsActive(relation: RelationDto): boolean {
        if (relation == null) {
            return false
        }

        const today = moment(new Date().setHours(0, 0, 0, 0))
        const dateDebut = moment(relation.dateDebut)
        const dateFin = relation.dateFin != null ? moment(relation.dateFin) : null
        return (dateFin == null || dateFin >= today) && dateDebut <= today
    }
}
