<div class="container position-relative">
    <div class="row">
        <div class="col-12">
            <div class="page-information text-center">
                <div class="page-information-commentaire">
                    <i class="bi bi-info-circle-fill"></i>
                    Vos supérieurs hiérarchiques ne sont pas sélectionnables ! Ils n'apparaitront pas dans vos résultats de recherche.
                </div>
            </div>
            <h2 class="mb-4">Rechercher un ou plusieurs collaborateurs</h2>
        </div>
    </div>
    <app-filters provenance="mes-collaborateurs-directs"></app-filters>
    <app-list></app-list>
</div>
