<div class="container position-relative portailrh-container-page">
  <div class="row">
    <div class="col-12 mx-3 mb-3">
      <h2>Sélectionner une date pour afficher les délégations en vigueur</h2>
      <form [formGroup]="dateDelegationForm" autocomplete="off">
        <div class="row">
              <div class="col-3 my-3">
                  <label class="form-label" for="formDateDelegation">Date de délégation</label>
                  <div class="input-group">
                      <input
                          id="formDateDelegation"
                          formControlName="dateDelegation"
                          #dp
                          [bsConfig]="{
                            isAnimated: true,
                            adaptivePosition: true,
                            showWeekNumbers: false,
                            containerClass: 'theme-blue',
                            dateInputFormat: 'DD/MM/YYYY'
                          }"
                              [maxDate]="endManagerCalendar"
                              [minDate]="startManagerCalendar"
                              [ngClass]="dateDelegationForm.get('dateDelegation')?.invalid
                                            && (dateDelegationForm.get('dateDelegation')?.dirty || dateDelegationForm.get('dateDelegation')?.touched) ? 'is-invalid' : ''"
                              [outsideClick]="true"
                              bsDatepicker
                              class="form-control select-date"
                              placeholder="jj/MM/aaaa"
                      />
                      <div class="input-group-prepend d-inline-block">
                      <span (click)="openDatepicker()" class="btn btn-picker btn-info datepicker-calendar">
                        <i class="bi bi-calendar-date"></i>
                      </span>
                      </div>
                      <div class="invalid-feedback">
                          <div *ngIf="dateDelegationForm.get('dateDelegation')?.invalid
                               && (dateDelegationForm.get('dateDelegation')?.dirty || dateDelegationForm.get('dateDelegation')?.touched)">La
                              date de mise à jour est obligatoire
                          </div>
                      </div>
                  </div>
              </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <div class="col-6 my-2 px-0">
              <button (click)="validerDateDelegation()" [disabled]="dateDelegationFormIsSubmitting || exportIsSubmitting || dateDelegationForm.invalid" class="btn btn-info"
                      type="button">
                <span *ngIf="dateDelegationFormIsSubmitting" class="spinner-border"></span>
                <span *ngIf="!dateDelegationFormIsSubmitting">Valider</span>
              </button>
              &nbsp;&nbsp;
              <button (click)="exporterCSV()" *ngIf="delegations && delegations.length != 0" [disabled]="dateDelegationFormIsSubmitting || exportIsSubmitting || dateDelegationForm.invalid" class="btn btn-info"
                      type="button">
                <span *ngIf="exportIsSubmitting" class="spinner-border"></span>
                <span *ngIf="!exportIsSubmitting">Exporter le Résultat</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="delegations && delegations.length != 0" class="row container-table-fix">
          <div class="col-12 my-3">
            <table class="table table-fix-head table-hover" id="table-delegation">
              <thead class="light-head-table">
              <tr>
                <th class="align-middle">Collaborateur délégataire</th>
                <th class="align-middle">Date de début<br> de délégation</th>
                <th class="align-middle" style="width: 300px;">Périmètre</th>
                <th class="align-middle">Date de début<br> de périmètre</th>
                <ng-container *ngFor="let i of additionalColumns">
                  <th class="align-middle">Manager N+{{ i }}</th>
                </ng-container>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let delegationDto of delegations">
                <td class="align-middle">
                  {{delegationDto.delegataire}}
                </td>
                <td class="align-middle">
                  {{delegationDto.dateDebutDelegation | date: 'dd/MM/yyyy'}}
                </td>
                <td class="align-middle text-wrap">
                  {{delegationDto.perimetreDelegation}}
                </td>
                <td class="align-middle">
                  {{delegationDto.dateDebutPerimetre | date: 'dd/MM/yyyy'}}
                </td>
                <ng-container *ngFor="let i of additionalColumns">
                  <td class="align-middle">
                    <ng-container *ngIf="delegationDto.managersHierarchique">
                      {{ delegationDto.managersHierarchique[i - 1] }}
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

