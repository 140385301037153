import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ComitesComponent} from './feature/comites/comites.component'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {ListModule} from 'src/app/shared/ui/list/list.module'
import {ComitesMembreComponent} from './feature/comites-membre/comites-membre.component'
import {ComiteListComponent} from './feature/comite-list/comite-list.component'
import {CustomModalModule} from 'src/app/shared/ui/custom-modal/custom-modal.module'
import {NgSelectModule} from '@ng-select/ng-select'
import {ReactiveFormsModule} from '@angular/forms'

@NgModule({
    imports: [
        CommonModule,
        BreadcrumbsModule.forRoot({
            postProcess: null,
            applyDistinctOn: 'text',
        }),
        ListModule,
        CustomModalModule,
        NgSelectModule,
        ReactiveFormsModule,
        ComitesComponent,
        ComitesMembreComponent,
        ComiteListComponent,
    ],
    providers: [],
    exports: [],
})
export class ComitesModule {}
