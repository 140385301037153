import {Injectable} from '@angular/core'
import {PaginationDto} from 'src/app/shared/models/paginationDto'
import {CollaborateurGlobalDto} from 'src/app/shared/models/collaborateursDto'
import {Store} from "../../../shared/models/store";

export interface SortOrSearchObject {
    field: string
    value: any
}

export interface EquipeGlobaleStateModel {
    loading: boolean
    errorSystem: boolean
    equipeGlobale: {
        initialList?: Array<CollaborateurGlobalDto>
        searchFields?: Array<SortOrSearchObject>
        searchResult?: Array<CollaborateurGlobalDto>
        sort?: SortOrSearchObject
        pagination: PaginationDto
    }
}

export const PAGE_SIZE = 20

@Injectable({providedIn: 'root'})
export class EquipeGlobaleState {
    store = new Store<EquipeGlobaleStateModel>({
        loading: false,
        errorSystem: false,
        equipeGlobale: {
            initialList: [],
            searchFields: [],
            searchResult: [],
            sort: {
                field: 'nomSuperieurHierarchique',
                value: 'desc',
            },
            pagination: {
                pageNumber: 0,
                pageSize: PAGE_SIZE,
                totalPages: 0,
                totalElements: 0,
            },
        },
    })
    static readonly getEquipeGlobaleSortingParams = (state: EquipeGlobaleStateModel) => state.equipeGlobale?.sort
    static readonly getEquipeGlobaleStateLoading = (state: EquipeGlobaleStateModel) => state.loading
    static readonly getEquipeGlobaleSearchResults = (state: EquipeGlobaleStateModel) => state.equipeGlobale?.searchResult
    static readonly getEquipeGlobalNumber = (state: EquipeGlobaleStateModel) => state.equipeGlobale?.initialList ? state.equipeGlobale?.initialList?.length : 0
    static readonly getSearch = (state: EquipeGlobaleStateModel) => state.equipeGlobale
    static readonly getFilters = (state: EquipeGlobaleStateModel) => state.equipeGlobale.searchFields
    static readonly getErrorSystem = (state: EquipeGlobaleStateModel) => state.errorSystem
    static readonly getSearchResultPagination = (state: EquipeGlobaleStateModel) => state.equipeGlobale?.pagination

}
