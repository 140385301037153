<span class="checkbox-container">
  <label class="checkbox">
    <span class="checkbox__input">
      <input
        (blur)="onBlur($event)"
        (change)="onChanged($event)"
        [checked]="isChecked"
        [disabled]="disabled"
        type="checkbox"
      />
      <span class="checkbox__control">
        <svg
          id="Capa_1"
          style="enable-background: new 0 0 438.215 438.215"
          version="1.1"
          viewBox="0 0 438.215 438.215"
          x="0px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            [ngStyle]="checkedStyle"
            d="M419.478,57.6c-4.204-10.371-10.365-15.77-22.041-23.931c-10.207-7.128-25.829-3.874-34.228,4.417
       c-43.465,42.895-85.078,87.691-123.244,135.401c-18.552,23.19-45.458,50.994-61.826,80.388
       c-7.892-6.538-15.687-13.074-22.879-19.789c-24.005-22.419-50.45-49.585-80.482-64.037c-24.829-11.947-43.577-1.082-59.326,19.564
       c-4.649,6.098-10.083,16.253-10.045,25.038c-6.33,6.75-8.076,17.693,0.111,27.766c52.981,65.151,118.587,115.962,188.124,162.255
       c9.269,6.171,26.848,4.903,34.094-4.398c74.598-95.779,145.574-201.187,206.4-306.092C444.352,76.56,433.857,61.88,419.478,57.6z"
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>

        <!-- OTHER SVG Models -->

        <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 26 26">
            <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"/>
          </svg> -->
        <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
          <path fill="#444444" d="M7.5 14.4c-0.8-0.8-0.8-2 0-2.8s2-0.8 2.8 0l0.6 0.6 1.9-2.1c-0.7-0.4-1.3-0.4-2-0.4-0.7-0.1-1.4-0.3-1.4-0.9s0.8-0.4 1.4-1.5c0 0 2.7-7.3-2.9-7.3-5.5 0-2.8 7.3-2.8 7.3 0.6 1 1.4 0.8 1.4 1.5s-0.7 0.7-1.4 0.8c-1.1 0.1-2.1-0.1-3.1 1.7-0.6 1.1-0.9 4.7-0.9 4.7h8l-1.6-1.6z"></path>
          <path fill="#444444" d="M12.8 16h2.1c0 0-0.1-0.9-0.2-2l-1.9 2z"></path>
          <path fill="#444444" d="M11 16c-0.3 0-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l1.3 1.3 3.3-3.6c0.4-0.4 1-0.4 1.4-0.1 0.4 0.4 0.4 1 0.1 1.4l-4 4.3c-0.3 0.3-0.5 0.4-0.8 0.4 0 0 0 0 0 0z"></path>
          </svg> -->

        <!-- <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
          <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg> -->
      </span>
    </span>
    <span class="radio__label">{{ label }}</span>
  </label>
</span>
