import {inject, Injectable} from '@angular/core'
import {ErrorDto} from "../../../models/errorDto";
import {ToastState, TypeToast} from "../state/toast.state";
import {ToastrService} from "ngx-toastr";

@Injectable({providedIn: 'root'})
export class ToastDomainService {
    private readonly toastState = inject(ToastState)
    private readonly toastrService = inject(ToastrService)


    toasterOptions() {
        return this.toastState.store.selectSnapshot(ToastState.getToasterOptions)
    }

    addToastFromErrorDto(err: ErrorDto) {

        return this.addToastMessage(
            TypeToast.danger,
           'Erreur ' + err.status,
            err.errorCode || '',
            err.message.toString())
    }

    addToastMessage(type: TypeToast, title: string, description: string, errorReference: string = '') {
        const defaultOptions = this.toasterOptions()

        if (errorReference) {
            description += errorReference
        }

        switch (type) {
            case TypeToast.danger:
                this.toastrService.error(description, title, defaultOptions)
                break
            case TypeToast.success:
                this.toastrService.success(description, title, defaultOptions)
                break
            case TypeToast.warning:
                this.toastrService.warning(description, title, defaultOptions)
                break
            case TypeToast.info:
                this.toastrService.info(description, title, defaultOptions)
                break
        }
    }

    removeToastMessage() {
        this.toastrService.clear()
    }


}
