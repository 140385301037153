import {Component, inject} from "@angular/core";
import {RouterOutlet} from "@angular/router";
import {HeaderComponent} from "./core/ui/header/header.component";
import {FooterComponent} from "./core/ui/footer/footer.component";
import {DataImmuablesDomainService} from "./core/domain/data-immuables.domain";
import {UserDomainService} from "./core/domain/user.domain";
import {ReferenceService} from "./core/data-access/http/reference.service";
import {AsyncPipe} from '@angular/common'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent, AsyncPipe],
})
export class AppComponent {
    title = 'Manager hiérarchique'

    private dataImmuablesDomain = inject(DataImmuablesDomainService)
    private userDomainService = inject(UserDomainService)

    constructor() {
        this.dataImmuablesDomain.setDeviceInformations()
        this.userDomainService.initUser().subscribe()
    }

}
