<div class="container position-relative portailrh-container-page">
  <div class="row">
    <div class="col-12">
      <h2 class="mb-4">Sélectionner un comité</h2>
    </div>
  </div>
  <app-filters [comiteListComponent]="comiteList" provenance="comites-membre"></app-filters>
  <app-comite-list #comiteList [withSuppression]="true" provenance="comites-membre"></app-comite-list>

    <div *ngIf="comiteList.codeComite !== undefined && comiteList.codeComite !== ''" class="container position-relative">
      <div class="row mb-4 mt-4">
        <h2 class="mb-4">Ajouter un collaborateur</h2>
      </div>

      <app-filters provenance="comites-membre-collabs"></app-filters>
      <app-list [comiteListComponent]="comiteList" provenance="comites-membre-collabs"></app-list>
    </div>


</div>
