import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs'
import { OrganigrammeComponent } from './feature/organigramme/organigramme.component'
import {ExporterDelegationsComponent} from "./feature/exporter-delegations/exporter-delegations.component";

@NgModule({
    imports: [
        CommonModule,
        BreadcrumbsModule.forRoot({
            postProcess: null,
            applyDistinctOn: 'text',
        }),
        OrganigrammeComponent,
        ExporterDelegationsComponent,
    ],
    providers: [],
    exports: [],
})
export class OrganigrammeModule {}
