import {Injectable} from '@angular/core'
import {UserDto} from "src/app/shared/models/userDto";
import {Store} from "../../../shared/models/store";

export interface UserStateModel {
    loading?: boolean
    isAuthenticated: boolean
    user?: UserDto
}

@Injectable({providedIn: 'root'})
export class UserState {
    store = new Store<UserStateModel>({
        loading: false,
        isAuthenticated: false,
        user: {
            id: 0,
            nomCollaborateur: '',
            prenomCollaborateur: '',
            matriculeCollaborateur: '',
            missionCollaborateur: '',
            profilCodeCollaborateur: '',
            profilLibelleCollaborateur: '',
            afficheabscisse: false,
            modifcationcomite: false,
            afficheboutonsave: false,
            droits: undefined,
            entite: undefined,
            emploi: undefined,
            relation: undefined,
            comite: undefined,
            manager: false,
            coman: false,
            assistante: false
        },
    })

    static readonly getUser = (state: UserStateModel) => state

}
