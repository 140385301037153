import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable, of } from 'rxjs'
import { filter, tap } from 'rxjs/operators'
import { PaginationDto } from 'src/app/shared/models/paginationDto'
import { NgClass, NgFor, NgIf } from '@angular/common'

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass],
})
export class PaginationComponent implements OnInit {
    @Input() pagination$: Observable<PaginationDto> = of({
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        totalElements: 0,
    })
    public pagination: PaginationDto | undefined
    public totalPages: number = 0
    @Output() backFromPagination = new EventEmitter<any>()

    constructor() {}

    ngOnInit(): void {
        //Called after every check of the component's view. Applies to components only.
        //Add 'implements AfterViewChecked' to the class.
        this.pagination$
            .pipe(
                filter((pagination) => pagination !== undefined),
                tap((pagination) => {
                    this.pagination = pagination
                    this.totalPages = pagination.totalPages
                })
            )
            .subscribe()
    }

    selectPage(pageNumber: string | number) {
        const actualPageNumber = this.pagination?.pageNumber || 0
        const actualTotalPages = this.pagination?.totalPages || 0
        let nextPage: number = 0
        switch (pageNumber) {
            case 'NEXT':
                actualPageNumber + 1 >= actualTotalPages
                    ? (nextPage = actualPageNumber)
                    : (nextPage = actualPageNumber + 1)
                break
            case 'PREV':
                actualPageNumber === 0 ? (nextPage = 0) : (nextPage = actualPageNumber - 1)
                break
            case 'LAST':
                nextPage = actualTotalPages
                break
            case 'FIRST':
                nextPage = 0
                break
            default:
                nextPage = +pageNumber
                break
        }
        this.backFromPagination.emit(nextPage)
    }
}
