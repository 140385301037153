<div class="container-fluid">
    <div id="legent-content-message">
      Les collaborateurs non rattachés à un manager ou à un manager parti ne sont pas affichés.
    </div>
    <div id="legent-content-bloc">
      <div id="legent-content">
        <div *ngFor="let item of comiteOrganigramme" style="width: 100%;">
          <div [ngStyle]="{'background-color':item.codeCouleur}" style="width: 16px;height:16px; display: inline-block; border-radius: 15px;"></div>
          {{item.codeComite}}
        </div>
      </div>
    </div>

    <form class="boc-edit-form light olivia" id="editForm">
      <div class="boc-edit-form-header">
        <div class="boc-edit-form-fields-inner">
          <div class="boc-form-fieldset">
            <div class="boc-form-field-nm">
              <div class="boc-edit-form-avatar" id="boc-avatar">
                <img id="urlphoto" src="" style="width: 100%;height:100%;border-radius: 50%;">
              </div>
              <div class="boc-input" data-boc-input="">
                <input class="textnm-titre" id="prenomNom" name="_prenomNom" readonly="readonly" type="text" />
              </div>
              <div class="boc-input" data-boc-input="">
                <input class="textnm-libelle-delegataire" id="libelleDelegataire" name="delegataire" readonly="readonly" type="text" />
              </div>
              <button class="btn" id="cancel" type="button">
                <svg class="boc-edit-form-close" data-edit-from-close="" viewBox="0 0 20 20">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                        style="fill:#ffffff;">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="boc-edit-form-fields">
        <div class="boc-edit-form-fields-inner">
          <div class="boc-form-fieldset">
            <div class="boc-form-field-comite">
              <label class="hasval" for="_comite">Comités</label> .
              <div *ngFor="let item of comite; let i = index" class="boc-field-comite">
                <div [ngStyle]="{'background-color':item.codeCouleur}" style="width: 16px;height:16px; display: inline-block; border-radius: 15px;"></div>
                <label class="label-comite" for="_lcomite">{{item.codeComite}}</label>
                <input [ngClass]=" (user$ | async)?.user?.modifcationcomite ? 'checkmark': 'check-disabled'" id="{{ 'comite' + i }}" name="_lcomite" type="checkbox" value=">{{item.codeComite}}" />
              </div>
            </div>
            <div class="boc-form-field-nm">
              <div class="boc-input" data-boc-input="">
                <label class="hasval-readonly" for="_emploi">Emploi C’est mon avenir</label>
                <input autocomplete="off" class="textnm-petit" id="emploi" name="_emploi" readonly="readonly" type="text">
              </div>
            </div>
            <div id="classMission">
              <div class="boc-input" data-boc-input="">
                <label class="hasval" for="_missionCollaborateur" id="labelmissionCollaborateur">Fonction</label>
                <input autocomplete="off" id="missionCollaborateur" maxlength="100" name="_missionCollaborateur" type="text">
              </div>
            </div>

            <div id="classPerimetre">
              <div class="boc-input" data-boc-input="">
                <label class="hasval-readonly" for="_perimetreCollaborateur" id="labelperimetreCollaborateur">Périmètre d’affectation</label>
                <input autocomplete="off" id="perimetreCollaborateur" maxlength="100" name="_perimetreCollaborateur" type="text">
              </div>
            </div>
            <div id="classDelegataire">
              <div class="boc-input-delegataire" id="blocDelegataire">
                <div>
                  <label class="label-delegataire" for="_ldelegataire" id="labeldelegataireCollaborateur">Délégataire (D)</label>
                </div>
                <div class="bloc-radio-delegataire">
                  <!--
                  <input type="checkbox" name="_ldelegataire" id="delegataire" [ngClass]=" (user$ | async)?.user?.modifcationcomite ? 'checkmark': 'check-disabled'"/>
                  -->
                  <label class="radio-text-delegataire" for="delegataire_oui" id="labelDelegataire_oui">Oui</label>
                  <input class="radiomark" id="delegataire_oui" name="delegataire" type="radio">

                  <label class="radio-text-delegataire" for="delegataire_non" id="labelDelegataire_non">Non</label>
                  <input class="radiomark" id="delegataire_non" name="delegataire" type="radio">
                </div>
              </div>
            </div>
            <div class="container" id="classUrlDelegataire">
              <div class="row">
                <div class="col-sm-10">
                  <div>
                    <div class="boc-input " data-boc-input="">
                      <label class="hasval-readonly" for="_lurldelegataire" id="labelUrlDelegataire">Lien délégation</label>
                      <input autocomplete="off" id="urlDelegataire" name="_urlDelegataire" type="text">
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <img alt="Click!" id="clickUrlDelegataire" src="./assets/images/icon_doc_link.png">
                </div>

              </div>
            </div>
            <div class="boc-form-field-nm">
              <div class="boc-form-field-nm" style="display: none;">
                <input id="id" name="_id" readonly="readonly" type="text">
                <input id="idUtilisateurEnCours" name="_idUtilisateurEnCours" readonly="readonly" type="text">
                <input id="afficheabscisse" name="_afficheabscisse" readonly="readonly" type="text">
                <input id="modifcationcomite" name="_modifcationcomite" readonly="readonly" type="text">
                <input id="afficheboutonsave" name="_afficheboutonsave" readonly="readonly" type="text">
                <input id="abscisse" name="_abscisse" readonly="readonly" type="text">
                <input id="title" name="_title" readonly="readonly" type="text">
                <input id="delegataire" name="_delegataire" readonly="readonly" type="text">
              </div>
              <div class="boc-input" data-boc-input="">
                <label class="hasval-readonly" for="_entite">Entité de rattachement</label>
                <input autocomplete="off" class="textnm-petit" id="entite" maxlength="256" name="_entite" readonly="readonly" type="text">
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <div [ngStyle]="{ 'display': (user$ | async)?.user?.afficheabscisse ? 'block': 'none'}" class="boc-form-field-nm-abscisse">
                    <div class="boc-input" data-boc-input="">
                      <label class="hasval-readonly" for="_abscisseemploi">Ranking poste</label>
                      <input autocomplete="off" class="textnm-petit" id="abscisseemploi" name="_abscisseemploi" readonly="readonly" type="text">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div [ngStyle]="{ 'display': (user$ | async)?.user?.afficheabscisse ? 'block': 'none'}" class="boc-form-field-abscisse">
                    <div class="boc-input" data-boc-input="">
                      <label class="hasval-readonly" for="_abscisseCollaborateur" id="labelabscisseCollaborateur">Ranking salarié</label>
                      <select aria-label=".form-select-lg example" class="form-select form-select-lg mb-3" id="abscisseCollaborateur" name="_abscisseCollaborateur">
                        <option selected>Sélectionner un ranking</option>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                        <option value="110">110</option>
                        <option value="120">120</option>
                        <option value="130">130</option>
                        <option value="140">140</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="boc-form-fieldset" id="saveBouton">
            <div class="boc-form-field" style="min-width: initial;">
              <button class="boc-button" data-edit-from-save="" id="save" type="button">Sauvegarder</button>
            </div>
          </div>
          <div class="boc-form-field-erreur" id="messageObligatoire">
            <div class="boc-input" data-boc-input="">
              <input autocomplete="off" class="textnm-petit-erreur" id="messageErreur" name="_messageErreur" readonly="readonly" type="text" value="Le périmètre d'affectation est obligatoire">
            </div>
          </div>
        </div>
      </div>
    </form>
    <div id="tree"></div>
    <svg>
      <defs>
        <style>
            #myteam_gradient {
                --color-stop-1: #FFFFFF;
                --color-stop-2: #F0ECE4;
                --opacity-stop: 2;
            }
        </style>
        <linearGradient id="myteam_gradient" x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stop-color="var(--color-stop-1)" stop-opacity="var(--opacity-stop)"></stop>
          <stop offset="100%" stop-color="var(--color-stop-2)" stop-opacity="var(--opacity-stop)"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
