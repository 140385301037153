import { Component, OnInit } from '@angular/core'
import { ListComponent } from 'src/app/shared/ui/list/list.component'
import { FiltersComponent } from 'src/app/shared/ui/filters/filters.component'
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs'
@Component({
    selector: 'app-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.scss'],
    standalone: true,
    imports: [
        BreadcrumbsModule,
        FiltersComponent,
        ListComponent,
    ],
})
export class ManagerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
