import {PaginationDto} from 'src/app/shared/models/paginationDto'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {combineLatest, Observable, of, Subject} from 'rxjs'
import {filter, switchMap, takeUntil} from 'rxjs/operators'
import {AsyncPipe, NgIf} from '@angular/common'
import {FiltersComponent} from "../../../shared/ui/filters/filters.component";
import {DataTableColsDescription, DataTableComponent, DataTableOptions} from "../../../shared/ui/data-table/data-table.component";
import {PaginationComponent} from "../../../shared/ui/pagination/pagination.component";
import {CollaborateurGlobalDto} from "src/app/shared/models/collaborateursDto";
import {EquipeGlobaleDomainService} from "../../domain/equipeglobale.domain";

@Component({
    selector: 'app-equipe-globale',
    templateUrl: './equipe-globale.component.html',
    styleUrls: ['./equipe-globale.component.scss'],
    standalone: true,
    imports: [NgIf, FiltersComponent, DataTableComponent, PaginationComponent, AsyncPipe],
})
export class EquipeGlobaleComponent implements OnInit, OnDestroy {
    // Show sort buttons and head for first array
    public tableOptionsEquipeGlobale: DataTableOptions
    public equipeGlobalePaginated$!: Observable<Array<CollaborateurGlobalDto>>
    public resultsNumber: number = 0
    private equipeGlobaleDomainService = inject(EquipeGlobaleDomainService)
    equipeGlobale$: Observable<Array<CollaborateurGlobalDto>> = this.equipeGlobaleDomainService.equipeGlobaleSearchResults$
    equipeGlobalNumber$: Observable<number> = this.equipeGlobaleDomainService.equipeGlobalNumber$ //TODO weird
    equipeGlobaleSortingParams$: Observable<any> = this.equipeGlobaleDomainService.equipeGlobaleSortingParams$
    equipeGlobaleLoading$: Observable<boolean> = this.equipeGlobaleDomainService.equipeGlobaleStateLoading$
    pagination$: Observable<PaginationDto> = this.equipeGlobaleDomainService.searchResultPagination$
    private unsubscribe$ = new Subject<void>()

    constructor() {
        // Description of table "Equipe Globale" (rows and cols propoerties ie name, corresponding field, and field to sort on)
        const colNamesEquipeGlobale: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: 'nomEmploi',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Direction / agence / service',
                fields: ['nomEntiteAffectation'],
                sortField: 'nomEntiteAffectation',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Manager actuel',
                fields: ['nomSuperieurHierarchique', 'prenomSuperieurHierarchique'],
                sortField: 'nomSuperieurHierarchique',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: "Dans l'équipe depuis",
                fields: ['dateDebutEquipe'],
                sortField: 'dateDebutEquipe',
                className: 'text-center ',
                tooltip: '',
            },
        ]

        this.tableOptionsEquipeGlobale = {
            showSorting: true,
            showHead: true,
            headClassName: 'light-head-table',
            noData: 'Aucun collaborateur',
            containerClassName: '',
            cols: colNamesEquipeGlobale,
        }

        // Get list of all collabs, on first load.
        this.equipeGlobaleDomainService.searchEquipeGlobale()
    }

    ngOnInit(): void {
        this.equipeGlobaleDomainService.searchReset()
        this.equipeGlobalePaginated$ = combineLatest([
            this.equipeGlobale$,
            this.pagination$,
            this.equipeGlobaleLoading$,
            this.equipeGlobalNumber$,
        ]).pipe(
            takeUntil(this.unsubscribe$),
            filter((results: any) => results[2].loading !== true && results[0]),
            switchMap((results: any) => {
                const monEquipe = results[0]
                const pagination = results[1]
                this.resultsNumber = results[3]

                return of(
                    monEquipe.slice(
                        pagination.pageNumber * pagination.pageSize,
                        (pagination.pageNumber + 1) * pagination.pageSize
                    )
                )
            })
        )
        this.equipeGlobalePaginated$.subscribe()
    }

    /**
     * Pagination
     */
    public changePage(pageNumber: number) {
        this.equipeGlobaleDomainService.paginate(pageNumber)
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    public sortDataOn(event: any) {
        this.equipeGlobaleDomainService.sortSearch(event)
    }
}
