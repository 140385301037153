<div class="container mt-3">
  <div class="row">
    <div *ngIf="!majManager" class="col-12 my-3">
        <div class="page-information text-center">
            <div class="page-information-commentaire">
                <i class="bi bi-info-circle-fill"></i>
                La date de début ne peut être antérieure au 1er jour du mois en cours et ne peut être postérieure à 40 jours.
            </div>
        </div>
    </div>
    <div class="col-12">
      <app-data-table
        (rowActionEvent)="updateCollabsDate($event)"
        [data$]="selectedCollaborateurs$"
        [tableLoading$]="collaborateursLoading$"
        [tableOptions]="tableOptionsAddCollab"
      >
      </app-data-table>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">&nbsp;
      <span *ngIf="validateButtonIsDisabled === true" class="text-danger">{{ validateButtonIsDisabledLabel }}</span>
    </div>
  </div>
  <div
    *ngIf="
      (collaborateursLoading$ | async) === false &&
      updatedCollabs &&
      updatedCollabs.length > 0
    "
    class="row"
  >
    <div class="col-md-6 offset-md-6 col-sm-12 text-end">
      <button
        (click)="onValidateDates()"
        [disabled]="validateButtonIsDisabled"
        class="btn btn-success"
        type="button"
      >
        <i class="bi bi-check"></i> Valider
      </button>
    </div>
  </div>
</div>
