import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {ErrorDto} from "src/app/shared/models/errorDto";
import {ToastDomainService} from "../../shared/ui/toast/domain/toast.domain";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private toastDomainService = inject(ToastDomainService)

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((errorResponse) => {
                console.log("erreur", errorResponse.error)
                let error: ErrorDto
                try {
                    error = JSON.parse(errorResponse.error)
                } catch (e) {
                    this.toastDomainService.addToastFromErrorDto(errorResponse.error)
                }

                if (errorResponse && error) {
                    this.toastDomainService.addToastFromErrorDto(error)
                }
                return throwError(errorResponse)
            })
        )
    }
}
