import { AuthConfig } from 'angular-oauth2-oidc'

export const authConfig: AuthConfig = {
    redirectUri: window.location.origin,
    // Open id
    responseType: 'code',
    oidc: true,
    scope: 'openid',

    // Divers
    resource: window.location.origin,
    showDebugInformation: true,
    disablePKCE: true,
}
