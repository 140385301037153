import {inject, Injectable} from '@angular/core'
import {catchError, filter, map, switchMap, take, tap} from "rxjs/operators";
import {CollaborateurDto} from "../../shared/models/collaborateursDto";
import {CollaborateursState, SortOrSearchObject} from "../data-access/state/collaborateurs.state";
import {forkJoin, Observable, of} from "rxjs";
import {PaginationDto} from "../../shared/models/paginationDto";
import {CollaborateurBackDto, CollaborateurBackFullDto} from "../../shared/models/collaborateursBackDto";
import {environment} from "../../../environments/environment";
import * as moment from "moment/moment";
import {RelationDto} from "../../shared/models/relationDto";
import {UserDomainService} from "../../core/domain/user.domain";
import {CollaborateursService} from "../../shared/data-access/http/collaborateurs.service";
import {CommonService} from "../../shared/data-access/http/common.service";
import {Router} from "@angular/router";

const MIN_LENGTH = 3

@Injectable({providedIn: 'root'})
export class CollaborateurDomainService {
    private readonly collaborateurState = inject(CollaborateursState)
    monEquipeActuelleSorted$ = this.collaborateurState.store.select(CollaborateursState.getMonEquipeActuelleSorted)
    monEquipeAVenir$ = this.collaborateurState.store.select(CollaborateursState.getMonEquipeAVenir)
    monEquipeSortingParams$ = this.collaborateurState.store.select(CollaborateursState.getMonEquipeSortingParams)
    collaborateurStateLoading$ = this.collaborateurState.store.select(CollaborateursState.getCollaborateurStateLoading)
    collaborateurSearchResults$ = this.collaborateurState.store.select(CollaborateursState.getCollaborateurSearchResults)
    monEquipeSearchResultsSortingParams$ = this.collaborateurState.store.select(CollaborateursState.getMonEquipeSearchResultsSortingParams)
    searchCollabs$ = this.collaborateurState.store.select(CollaborateursState.getSearchCollabs)
    filters$ = this.collaborateurState.store.select(CollaborateursState.getFilters)
    selectedCollaborateurs$ = this.collaborateurState.store.select(CollaborateursState.getSelectedCollaborateurs)
    errorSystem$ = this.collaborateurState.store.select(CollaborateursState.getErrorSystem)
    searchResultPagination$ = this.collaborateurState.store.select(CollaborateursState.getSearchResultPagination)
    private readonly collabService = inject(CollaborateursService)
    private readonly commonService = inject(CommonService)
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private matricule: string = ''

    private readonly router = inject(Router)

    monEquipe() {
        return this.collaborateurState.store.selectSnapshot(CollaborateursState.getMonEquipe)
    }

    monEquipeSortingParams() {
        return this.collaborateurState.store.selectSnapshot(CollaborateursState.getMonEquipeSortingParams)
    }

    searchCollabs() {
        return this.collaborateurState.store.selectSnapshot(CollaborateursState.getSearchCollabs)
    }

    selectedCollaborateurs() {
        return this.collaborateurState.store.selectSnapshot(CollaborateursState.getSelectedCollaborateurs)
    }

    setLoading(loading: boolean) {
        this.collaborateurState.store.update((state) => ({
            ...state,
            loading: loading,
        }))

        return of(void 0)
    }

    setErrorSystem(inError: boolean) {
        this.collaborateurState.store.update((state) => ({
            ...state,
            errorSystem: inError,
        }))
    }

    loadingMonEquipe() {
        let pagination: PaginationDto // Ici, la pagination est gérée côté front
        this.collaborateurState.store.update((state) => ({
            ...state,
            loading: true,
        }))

        return this.setLoading(true).pipe(
            switchMap((_) => this.collabService.getMonEquipe()),
            // Reformattage des données back en données pour le front(simplification et recherche de la relation actuelle)
            // => Passage du format CollaborateurBackDto au format CollaborateurDto
            map((collabsContent): Array<CollaborateurDto> => {
                const collabsBack = collabsContent.content
                pagination = {
                    pageNumber: collabsContent.pageable.pageNumber,
                    pageSize: collabsContent.pageable.pageSize,
                    totalPages: collabsContent.totalPages,
                    totalElements: collabsContent.totalElements,
                }
                return collabsBack.map((collabB: CollaborateurBackDto) => this.enrichTeamCollab(collabB))
            }),
            // Merge des collaborateurs selected du store, s'il y en a.
            map((collabs: Array<CollaborateurDto>): Array<CollaborateurDto> => {
                if (collabs && this.selectedCollaborateurs()?.length > 0) {
                    const collabsToMerge = this.selectedCollaborateurs().filter((collab) => collab.datefilled === true)
                    return [...collabs, ...collabsToMerge]
                }
                return collabs
            }),
            // Remplissage des datas collaborateurs actuels et collaborateurs a venir.
            tap((collabs: any) => {
                let aVenir: Array<CollaborateurDto> = []
                const actuel: Array<CollaborateurDto> = []

                if (collabs !== undefined) {
                    collabs.map((collab: CollaborateurDto) => {
                        if (collab.mouvementType === 'actuel') {
                            actuel.push(collab)
                        } else {
                            aVenir.push(collab)
                            if (collab?.mouvement && collab?.mouvement === 'Out') {
                                actuel.push(collab)
                            }
                        }
                    })
                }

                // Tri des aVenir descendant
                if (aVenir.length > 0) {
                    aVenir = this.commonService.sortList(aVenir, 'mouvementDateEffet', 'desc')
                }

                this.collaborateurState.store.update((state) => ({
                    ...state,
                    loading: false,
                    monEquipe: {
                        data: collabs,
                        monEquipeActuelle: actuel,
                        monEquipeActuelleSorted: actuel,
                        monEquipeAVenir: aVenir,
                        pagination: pagination,
                    },
                }))

                if (this.monEquipeSortingParams()) {
                    this.sortMonEquipe(this.monEquipeSortingParams())
                }

            })
        )
    }

    sortMonEquipe(sortOrSearchObject: SortOrSearchObject) {
        const collabs = this.monEquipe()
        const sortObject = sortOrSearchObject
        if (
            !sortObject?.field ||
            (sortObject?.value?.toLowerCase() !== 'asc' && sortObject?.value?.toLowerCase() !== 'desc')
        ) {
            return
        }

        this.collaborateurState.store.update((state) => ({
            ...state,
            loading: true,
        }))
        const sortedCollabs = this.commonService.sortList(collabs.monEquipeActuelle, sortObject.field, sortObject.value)
        const newCollabs = {
            ...collabs,
            monEquipeActuelleSorted: sortedCollabs,
            sort: {
                field: sortObject.field,
                value: sortObject.value,
            },
        }

        this.collaborateurState.store.update((state) => ({
            ...state,
            loading: false,
            monEquipe: newCollabs,
        }))
    }

    setFilters(searchField: string, searchValue: string | boolean) {
        const newFilter = {field: searchField, value: searchValue}
        const searchCollabs = this.searchCollabs()
        const oldArr =
            searchCollabs?.searchFields && (searchCollabs?.searchFields).length > 0 ? searchCollabs?.searchFields : []
        let updatedSearchFields: any
        if (searchValue !== '') {
            updatedSearchFields = this.mergeArrayOfObjects(oldArr, [newFilter], 'field')
        } else {
            updatedSearchFields = oldArr.filter((item) => item.field !== searchField)
        }

        this.collaborateurState.store.update((state) => ({
            ...state,
            searchCollabs: {
                ...searchCollabs,
                searchFields: updatedSearchFields,
                pagination: {
                    pageNumber: 0,
                    pageSize: 0,
                    totalPages: 0,
                    totalElements: 0,
                },
                sort: {
                    field: 'nomCollaborateur',
                    value: 'asc',
                },
            },
        }))

        return this.searchCollaborateurs()
    }

    searchReset() {
        const searchCollabs = this.searchCollabs()
        const resetSearchCollabs = {
            ...searchCollabs,
            searchFields: undefined as any,
            searchResult: undefined as any,
            searchResultSorted: undefined as any,
        }

        this.collaborateurState.store.update((state) => ({
            ...state,
            searchCollabs: resetSearchCollabs,
        }))
    }

    toggleSelectCollabInSearch(matricule: string, selected: boolean) {
        const searchCollabs = this.searchCollabs()
        let searchResult = searchCollabs?.searchResult
        if (searchResult && searchResult.length > 0) {
            let myCollab = searchResult.find(
                (collab: CollaborateurDto) => collab.matriculeCollaborateur === matricule
            )
            if (myCollab) {
                myCollab = {...myCollab, selected: selected}
                searchResult = this.mergeArrayOfObjects(searchResult, [myCollab], 'matriculeCollaborateur')
            }

            this.collaborateurState.store.update((state) => ({
                ...state,
                searchCollabs: {...searchCollabs, searchResult},
            }))
        }
    }

    sortSearch(sortObject: { field: string, value: string }) {
        const searchCollabs = this.searchCollabs()
        if (
            !sortObject.field ||
            (sortObject.value?.toLowerCase() !== 'asc' && sortObject.value?.toLowerCase() !== 'desc')
        ) {
            return void 0
        }

        this.collaborateurState.store.update((state) => ({
            ...state,
            searchCollabs: {...searchCollabs, sort: sortObject},
        }))
        return this.searchCollaborateurs()
    }

    setSelectedCollabs(collabs: Array<CollaborateurDto>) {
        const selectedCollaborateurs = this.selectedCollaborateurs()
        const toUpdate = collabs.filter((item) => item.selected === true)
        this.collaborateurState.store.update((state) => ({
            ...state,
            selectedCollaborateurs: this.mergeArrayOfObjects(
                selectedCollaborateurs,
                toUpdate,
                'matriculeCollaborateur'
            ),
        }))

        this.compareSelectedAndResults()
        return of(void 0)
    }

    addCollab(collab: CollaborateurDto) {
        const selectedCollaborateurs = this.selectedCollaborateurs()
        this.collaborateurState.store.update((state) => ({
            ...state,
            selectedCollaborateurs: [...selectedCollaborateurs, collab],
        }))

        return collab.matriculeCollaborateur
    }

    addSelectedCollabsInMyTeamAction() {
        const selectedCollaborateurs = this.selectedCollaborateurs()

        return this.setLoading(true).pipe(
            switchMap((_) => this.loadUserInfo()),
            switchMap((_) => of(selectedCollaborateurs)),
            switchMap((selectedCollaborateurs: Array<CollaborateurDto>) => {
                const obs$ = selectedCollaborateurs.map((collab: CollaborateurDto) => {
                    const newRelation: any = {
                        matriculeCollaborateur: collab.matriculeCollaborateur,
                        matriculeManagerHierarchique: this.matricule,
                        dateDebut: moment(collab.dateDebutEquipe, 'DD/MM/YYYY').format(),
                    }

                    return this.collabService.addCollaborateur(newRelation).pipe(
                        catchError((err) => {
                            this.setLoading(false)
                            this.setErrorSystem(true)
                            return of(err)
                        })
                    )
                })
                return forkJoin(obs$)
            }),

            /* SFE 2024-07 A CHANGER ET APPELER VALIDATEURS */

            tap(() =>

                this.router.navigate(['/valideurs', 'changement-manager'])),


             //switchMap((_) => this.resetSelectedCollabs()), // reset collabs selected

             //switchMap((_) => this.loadingMonEquipe()) // reload myteam
        )
    }

    removeCollab(matriculeCollaborateur: string) {
        const selectedCollaborateurs = this.selectedCollaborateurs()
        this.collaborateurState.store.update((state) => ({
            ...state,
            selectedCollaborateurs: selectedCollaborateurs.filter(
                (collab) => collab.matriculeCollaborateur !== matriculeCollaborateur
            ),
        }))
    }

    resetSelectedCollabs() {
        this.collaborateurState.store.update((state) => ({
            ...state,
            selectedCollaborateurs: [],
        }))
        return of(void 0)
    }

    resetDateFilledForMonEquipeAvenir() {
        const newMonEquipeActuelleAvenir = this.collaborateurState.store.selectSnapshot(CollaborateursState.getMonEquipeAVenir)
        newMonEquipeActuelleAvenir.forEach(monEquipeCollaborateurAvenir => monEquipeCollaborateurAvenir.datefilled = false)

        this.collaborateurState.store.update((state) => ({
            ...state,
            monEquipe: {...state.monEquipe,monEquipeAVenir:newMonEquipeActuelleAvenir
        }}))

        return of(void 0)
    }

    resetDateFilledForMonEquipe() {
        const newMonEquipeActuelle = this.collaborateurState.store.selectSnapshot(CollaborateursState.getMonEquipeActuelleSorted)
        newMonEquipeActuelle.forEach(monEquipeCollaborateur => monEquipeCollaborateur.datefilled = false)

        this.collaborateurState.store.update((state) => ({
            ...state,
            monEquipe: {...state.monEquipe,monEquipeActuelle:newMonEquipeActuelle
            }}))

        return of(void 0)
    }

    paginate(pageNumber: number, storeType: string) {
        if (!storeType || (storeType !== 'searchCollabs' && storeType !== 'monEquipe')) {
            return void 0
        }
        if (storeType === 'searchCollabs') {
            const searchCollabs = this.searchCollabs()
            const pagination = searchCollabs?.pagination
            if (pagination) {

                this.collaborateurState.store.update((state) => ({
                    ...state,
                    searchCollabs: {
                        ...searchCollabs,
                        pagination: {...pagination, pageNumber: pageNumber},
                    },
                }))
            }
        } else {
            const monEquipe = this.monEquipe()
            const pagination = monEquipe?.pagination
            if (pagination) {

                this.collaborateurState.store.update((state) => ({
                    ...state,
                    monEquipe: {
                        ...monEquipe,
                        pagination: {...pagination, pageNumber: pageNumber},
                    },
                }))
            }
        }
        return this.searchCollaborateurs()
    }

    private searchCollaborateurs() {
        const searchCollabs = this.searchCollabs()
        const searchFields = searchCollabs?.searchFields

        // Remove fields.length < 3 characters
        const searchFieldsToSend: Array<SortOrSearchObject> = []
        searchFields?.forEach((item) => {
            if (
                (item.field === 'collaborateur' || item.field === 'managerHierarchique') &&
                item.value.length >= MIN_LENGTH
            ) {
                searchFieldsToSend.push(item)
            }
            if (item.field !== 'collaborateur' && item.field !== 'managerHierarchique') {
                if (item.field !== 'pasDeManager' || (item.field === 'pasDeManager' && item.value === true)) {
                    searchFieldsToSend.push(item)
                }
            }
        })

        if (searchFieldsToSend && searchFieldsToSend.length > 0) {
            if (localStorage.getItem('majManager') === 'true') {
                searchFieldsToSend.push({field: 'searchForManager', value: true})
            }
            this.collaborateurState.store.update((state) => ({
                ...state,
                loading: true,
            }))
            return this.collabService
                .searchCollaborateurs(
                    environment.collaborateurApi,
                    searchFieldsToSend,
                    this.searchCollabs()?.pagination,
                    this.searchCollabs()?.sort
                )
                .pipe(
                    take(1),
                    map((data: CollaborateurBackFullDto) => {
                        let results: Array<CollaborateurDto> = []

                        if (data?.content) {
                            results = data.content.map((item: CollaborateurBackDto) => this.enrichSearchCollab(item))
                        }
                        const pagination = {
                            totalPages: data?.totalPages,
                            totalElements: data?.totalElements,
                            pageSize: data?.pageable.pageSize,
                            pageNumber: data?.pageable.pageNumber,
                        }
                        const newSearchCollabs = {
                            ...searchCollabs,
                            searchResult: results,
                            searchResultSorted: results,
                            pagination: pagination,
                        }

                        this.collaborateurState.store.update((state) => ({
                            ...state,
                            loading: false,
                            searchCollabs: newSearchCollabs,
                        }))
                        return newSearchCollabs
                    }),
                    tap((_: any) => {
                        this.compareSelectedAndResults()
                    })
                )
        } else {
            const resetSearchCollabs = {
                ...searchCollabs,
                searchFields: undefined as any,
                searchResult: undefined as any,
                searchResultSorted: undefined as any,
            }

            this.collaborateurState.store.update((state) => ({
                ...state,
                loading: false,
                searchCollabs: resetSearchCollabs,
            }))
        }

        return of(void 0)
    }

    private compareSelectedAndResults() {
        const searchCollabs = this.searchCollabs()
        const selectedCollabs = this.selectedCollaborateurs()

        if (
            !!searchCollabs &&
            searchCollabs?.searchResult &&
            searchCollabs?.searchResult.length > 0 &&
            !!selectedCollabs &&
            selectedCollabs?.length > 0
        ) {
            const enrichedSearchResult: Array<CollaborateurDto> = searchCollabs?.searchResult.map((collab) => {
                const found = selectedCollabs.find(
                    (item: any) => item.matriculeCollaborateur === collab.matriculeCollaborateur
                )
                if (!!found) {
                    return found
                } else {
                    return collab
                }
            })
            const newSearchCollabs = {
                ...searchCollabs,
                searchResult: enrichedSearchResult,
            }

            this.collaborateurState.store.update((state) => ({
                ...state,
                searchCollabs: newSearchCollabs,
            }))
        }
    }

    /**
     * Initialisation objet collab à partir des données du back
     */
    private initCollabObject(collabB: CollaborateurBackDto): CollaborateurDto {
        return {
            matriculeCollaborateur: collabB.matriculeCollaborateur,
            nomCollaborateur: collabB.nomCollaborateur,
            prenomCollaborateur: collabB.prenomCollaborateur,
            codeEntiteAffectation: collabB.entite?.codeEntiteAffectation ? collabB.entite.codeEntiteAffectation : '',
            nomEntiteAffectation: collabB.entite?.nomEntiteAffectation ? collabB.entite.nomEntiteAffectation : '',
            codeEmploi: collabB.emploi?.codeEmploi ? collabB.emploi.codeEmploi : '',
            nomEmploi: collabB.emploi?.nomEmploi ? collabB.emploi.nomEmploi : '',
            dateDebutEquipe: '',
            dateDebutEquipeAffichage: '',
            dateFinEquipe: '',
            matriculeSuperieurHierarchique: '',
            nomSuperieurHierarchique: 'Sans manager',
            prenomSuperieurHierarchique: '',
            dateModification: new Date(),
            hasFuturManager: false,
            manager: false,
            selected: false,
            datefilled: false,
            mouvementType: '',
            mouvementDateEffet: '',
            mouvement: '',
            mouvementFutureRelation: undefined,
        }
    }

    /**
     * Merge 2 arrays of object on property p
     * @param a : array of objects A (some items could be overwritten by array B)
     * @param b : array of objects B
     * @param p : field name on which filter is applied
     * @returns array merged
     */
    private mergeArrayOfObjects = (a: Array<any>, b: Array<any>, p: string) =>
        a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b)

    /**
     * enrichTeamCollab : collab is mapped to "easier handling" format :
     *  Oon affiche les collaborateurs de l'utilisateur : (equipe directe), on cherche la dernière relation en cours
     * @param collabB from Back <CollaborateurBackDto>
     * @returns : collab for store <CollaborateurDto>.
     */
    private enrichTeamCollab(collabB: CollaborateurBackDto): CollaborateurDto {
        let collab = this.initCollabObject(collabB)
        let relation!: RelationDto | undefined

        if (!!collabB?.relation?.length) {
            relation = collabB.relation.find((rel) => !rel.dateFin)

            if (!relation) {
                relation = collabB.relation.find(
                    (rel) =>
                        rel.dateFin &&
                        moment(rel.dateFin).isValid() &&
                        moment(rel.dateFin).isSameOrAfter(moment().startOf('day'), 'day')
                )
            }
        }
        if (!!relation) {
            collab.dateDebutEquipe = moment(relation.dateDebut).isValid()
                ? moment(relation.dateDebut).format('DD/MM/YYYY')
                : ''
            collab.dateFinEquipe = moment(relation.dateFin).isValid()
                ? moment(relation.dateFin).format('DD/MM/YYYY')
                : ''
            collab.matriculeSuperieurHierarchique = relation.managerHierarchique.matriculeManagerHierarchique
            collab.nomSuperieurHierarchique = relation.managerHierarchique.nomManagerHierarchique
            collab.prenomSuperieurHierarchique = relation.managerHierarchique.prenomManagerHierarchique
            collab.dateModification = relation.dateModif
        }

        // Enrichissement du collab avec les informations de mouvement actuel ou futur.
        const dateDebutCollab =
            !!collab.dateDebutEquipe && collab.dateDebutEquipe !== 'Invalid date'
                ? moment(collab.dateDebutEquipe, 'DD/MM/YYYY')
                : false
        const dateFinCollab =
            !!collab.dateFinEquipe && collab.dateFinEquipe !== 'Invalid date'
                ? moment(collab.dateFinEquipe, 'DD/MM/YYYY')
                : false
        const now = moment().startOf('day')

        // if dateFinEquipe exists : compare with now. If < 30 days => mouvment=sortie. mouvementDateEffet=dateSortie + 1
        if (!!dateFinCollab) {
            if (dateFinCollab.diff(now, 'day') >= 0 && dateFinCollab.diff(now, 'day') <= 30) {
                collab = {
                    ...collab,
                    mouvementType: 'aVenir',
                    mouvementDateEffet: dateFinCollab.format('DD/MM/YYYY'),
                    mouvement: 'Out',
                }
            } else {
                if (dateFinCollab.diff(now, 'day') > 30) {
                    collab = {
                        ...collab,
                        mouvementType: 'actuel',
                        mouvementDateEffet: '',
                        mouvement: '',
                    }
                }
            }
        } else {
            // no dateFinEquipe.
            if (dateDebutCollab && dateDebutCollab.diff(now, 'day') > 0 && dateDebutCollab.diff(now, 'day') <= 30) {
                collab = {
                    ...collab,
                    mouvementType: 'aVenir',
                    mouvementDateEffet: dateDebutCollab.format('DD/MM/YYYY'),
                    mouvement: 'In',
                }
            } else {
                if (dateDebutCollab && dateDebutCollab.diff(now, 'day') <= 0) {
                    collab = {
                        ...collab,
                        mouvementType: 'actuel',
                        mouvementDateEffet: '',
                        mouvement: '',
                    }
                } else {
                    // Si le collab vient d'être ajouté, on l'affiche
                    if (collab?.datefilled) {
                        collab = {
                            ...collab,
                            mouvementType: 'aVenir',
                            mouvementDateEffet: dateDebutCollab ? dateDebutCollab.format('DD/MM/YYYY') : '',
                            mouvement: 'In',
                        }
                    } else {
                        collab = {
                            ...collab,
                            mouvementType: 'actuel',
                            mouvementDateEffet: '',
                            mouvement: '',
                        }
                    }
                    // Sinon il n'apparait pas (au dela de 30 jours)
                }
            }
        }
        return collab
    }

    /**
     * enrichSearchCollab : collab is mapped to "easier handling" format :
     * Recherche de la relation à la date du jour
     *  - On cherche en premier dans les relations celles qui n'ont pas de fin. Et on regarde si la date de début est dépassée (relation en cours)
     *  - si pas trouvé, (eventualité d'une relation à venir commencant dans le futur), on cherche la relation bornée (date deb et fin) dont la date du jour appartient à l'intervalle
     * @param collabB from Back <CollaborateurBackDto>
     * @returns : collab for store <CollaborateurDto>.
     */
    private enrichSearchCollab(collabB: CollaborateurBackDto): CollaborateurDto {
        let collab = this.initCollabObject(collabB)
        let relation!: RelationDto | undefined

        if (!!collabB?.relation?.length) {
            const relationW = collabB.relation.find((rel) => !rel.dateFin)
            if (relationW && moment(relationW.dateDebut).isSameOrBefore(moment().startOf('day'))) {
                relation = relationW
            } else {
                if (relationW) {
                    // relation qui démarre dans le futur trouvée, on alimente mouvementFutureRelation
                    collab = {
                        ...collab,
                        mouvementFutureRelation: relationW,
                        hasFuturManager: true,
                        manager: true,
                    }
                }
                relation = collabB.relation.find(
                    (rel) =>
                        moment(rel.dateDebut).isSameOrBefore(moment().startOf('day')) &&
                        moment(rel.dateFin).isSameOrAfter(moment().startOf('day'))
                )
            }
        } else {
            // n'a jamais eu de manager.
        }

        if (!!relation) {
            collab.dateDebutEquipe = moment(relation.dateDebut).isValid()
                ? moment(relation.dateDebut).format('DD/MM/YYYY')
                : ''
            collab.dateFinEquipe = moment(relation.dateFin).isValid()
                ? moment(relation.dateFin).format('DD/MM/YYYY')
                : ''
            collab.dateDebutEquipeAffichage = collab.dateDebutEquipe
            collab.matriculeSuperieurHierarchique = relation.managerHierarchique.matriculeManagerHierarchique
            collab.nomSuperieurHierarchique = relation.managerHierarchique.nomManagerHierarchique
            collab.prenomSuperieurHierarchique = relation.managerHierarchique.prenomManagerHierarchique
            collab.dateModification = relation.dateModif
        }
        return collab
    }

    private loadUserInfo(): Observable<string> {
        if (this.matricule === '') {
            return this.user$.pipe(
                filter((user: any) => user.isAuthenticated === true),
                take(1),
                switchMap((user) => {
                    this.matricule = user.user?.matriculeCollaborateur
                    return of(this.matricule)
                })
            )
        } else {
            return of(this.matricule)
        }
    }

    public updateManagerCollaborateur(matriculeCollaborateur: string, matriculeSuperieurHierarchique: string, dateEffet: string){

        const newRelation = {
            matriculeCollaborateur: matriculeCollaborateur,
            matriculeManagerHierarchique: matriculeSuperieurHierarchique,
            dateDebut: dateEffet
        }

        return this.collabService.addCollaborateur(newRelation).pipe(tap(( ) => {

                const relationCollaborateurs = this.collaborateurState.store.selectSnapshot(
                    CollaborateursState.getSelectedCollaborateurs)

                const indexRelationAmodifier = relationCollaborateurs.findIndex(
                    relation => relation.matriculeCollaborateur === matriculeCollaborateur
                )

                const relationAmodifier = relationCollaborateurs[indexRelationAmodifier]

                relationAmodifier.matriculeSuperieurHierarchique = matriculeSuperieurHierarchique
                relationAmodifier.dateDebutEquipe = dateEffet

                this.collaborateurState.store.update(
                    state => ({
                        ...state, selectedCollaborateurs: state.selectedCollaborateurs.map((relation, i) =>{
                            if(i==indexRelationAmodifier){
                                return relationAmodifier
                            }
                            return relation
                        })
                    })
                )
            }
        ))
    }
}

