import {BehaviorSubject, distinctUntilChanged, map, Observable} from 'rxjs'

export class Store<T> {
    private state$: BehaviorSubject<Readonly<T>>

    constructor(initialState: T) {
        this.state$ = new BehaviorSubject<Readonly<T>>(initialState)
    }

    public select<U>(selectFn: (state: T) => U): Observable<U> {
        return this.state$.asObservable().pipe(
            map((value) => selectFn(value)),
            distinctUntilChanged(),
        )
    }

    public selectSnapshot<U>(selectFn: (state: T) => U): U {
        return selectFn(this.state$.value)
    }

    public update(reduceFn: (state: T) => T): void {
        this.state$.next(reduceFn(this.state$.getValue()))
    }
}
