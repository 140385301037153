import {PaginationDto} from "src/app/shared/models/paginationDto"
import {CollaborateurDto} from "src/app/shared/models/collaborateursDto"
import {Injectable} from '@angular/core'
import {Store} from "../../../shared/models/store";

export interface SortOrSearchObject {
    field: string
    value: any
}

export interface CollaborateursStateModel {
    loading: boolean
    errorSystem: boolean
    monEquipe: {
        data?: Array<CollaborateurDto>
        monEquipeActuelle?: Array<CollaborateurDto>
        monEquipeActuelleSorted?: Array<CollaborateurDto>
        monEquipeAVenir?: Array<CollaborateurDto>
        sort?: SortOrSearchObject
        pagination: PaginationDto
    }
    searchCollabs: {
        searchFields?: Array<SortOrSearchObject>
        searchResult?: Array<CollaborateurDto>
        sort?: SortOrSearchObject
        pagination: PaginationDto
    }
    selectedCollaborateurs: Array<CollaborateurDto>
}

const PAGE_SIZE = 20

@Injectable({providedIn: 'root'})
export class CollaborateursState {
    store = new Store<CollaborateursStateModel>({
        loading: false,
        errorSystem: false,
        monEquipe: {
            data: undefined,
            monEquipeActuelle: undefined,
            monEquipeActuelleSorted: undefined,
            monEquipeAVenir: undefined,
            sort: {
                field: 'nomCollaborateur',
                value: 'desc',
            },
            pagination: {
                pageNumber: 0,
                pageSize: PAGE_SIZE,
                totalPages: 0,
                totalElements: 0,
            },
        },
        searchCollabs: {
            searchFields: [],
            searchResult: [],
            sort: {
                field: 'nomCollaborateur',
                value: 'asc',
            },
            pagination: {
                pageNumber: 0,
                pageSize: PAGE_SIZE,
                totalPages: 0,
                totalElements: 0,
            },
        },
        selectedCollaborateurs: [],
    })

    static readonly getMonEquipe = (state: CollaborateursStateModel) => state?.monEquipe
    static readonly getMonEquipeActuelleSorted = (state: CollaborateursStateModel) => state?.monEquipe?.monEquipeActuelleSorted
    static readonly getMonEquipeAVenir = (state: CollaborateursStateModel) => state?.monEquipe?.monEquipeAVenir
    static readonly getMonEquipeSortingParams = (state: CollaborateursStateModel) => state.monEquipe?.sort
    static readonly getCollaborateurStateLoading = (state: CollaborateursStateModel) => state.loading
    static readonly getCollaborateurSearchResults = (state: CollaborateursStateModel) => state.searchCollabs?.searchResult
    static readonly getMonEquipeSearchResultsSortingParams = (state: CollaborateursStateModel) => state.searchCollabs?.sort
    static readonly getSearchCollabs = (state: CollaborateursStateModel) => state.searchCollabs
    static readonly getFilters = (state: CollaborateursStateModel) => state.searchCollabs.searchFields
    static readonly getSelectedCollaborateurs = (state: CollaborateursStateModel) => state.selectedCollaborateurs
    static readonly getErrorSystem = (state: CollaborateursStateModel) => state.errorSystem
    static readonly getSearchResultPagination = (state: CollaborateursStateModel) => state.searchCollabs?.pagination

}
