import {
    AfterContentChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import * as moment from 'moment'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { frLocale } from 'ngx-bootstrap/locale'
import { NiceCheckboxComponent } from '../nice-checkbox/nice-checkbox.component'
import { NgClass, NgIf } from '@angular/common'

defineLocale('fr', frLocale) // for datepicker

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NiceCheckboxComponent, BsDatepickerModule],
})
export class ActionButtonComponent implements OnInit, AfterContentChecked {
    @Input() data: any = undefined
    @Output() onActionButtonEvent = new EventEmitter<any>()
    @ViewChild('dp', { static: false }) dp!: ElementRef
    public locale: string = 'fr'
    public minDateDatePicker!: Date
    public maxDateDatePicker!: Date
    public datePickerIsOpen: boolean = false
    public dateError!: boolean
    public datePickerIsDisabled: boolean = true
    public dateValue!: Date | null

    constructor(private localeService: BsLocaleService) {
        // Language used for datepicker :
        this.localeService.use(this.locale)
        // Date min = 1e jour du mois en cours.
        this.minDateDatePicker = moment().startOf('month').toDate()
        // limite du choix à 40 jours  arrondi fin de mois dans le futur
        this.maxDateDatePicker = moment().add(40, 'days').endOf('month').toDate()
    }

    ngOnInit(): void {}

    ngAfterContentChecked(): void {
        if (this.data.action === 'datePickerDate') {
            this.datePickerIsDisabled = false
            this.dateValue = this.data.actionFieldValue
                ? moment(this.data.actionFieldValue, 'DD/MM/YYYY').toDate()
                : null
        }
    }

    public clickButton(obj: any) {
        this.onActionButtonEvent.emit(this.data)
    }

    /**
     * return updated object to parent after changing checkbox value.
     */
    public clickCheckBox(event: any) {
        this.data.actionFieldValue = event.target.checked
        this.onActionButtonEvent.emit(this.data)
    }

    /**
     * return object with datePicker's selected date
     * @param event : date selected (Date)
     */
    public sendSelectedDate(event: any) {
        // date is in good format ?
        const date = moment(event)
        const minDate = moment(this.minDateDatePicker)
        if (date.isValid() && date.isSameOrAfter(minDate) && date.isSameOrBefore(this.maxDateDatePicker)) {
            this.dateError = false
            const newDate = date.format('DD/MM/YYYY')
            this.data.actionFieldValue = newDate
            this.data.error = undefined
            this.onActionButtonEvent.emit(this.data)
        } else {
            this.dateError = true
            if (!date.isValid()) {
                this.data.error = {
                    status: 'INVALID_DATE',
                    label: 'Mauvais format du champ date',
                }
            }
            if (!date.isSameOrAfter(minDate)) {
                this.data.error = {
                    status: 'INVALID_DATE',
                    label: `Date inférieure à la date minimum possible (${moment(this.minDateDatePicker).format(
                        'DD/MM/YYYY'
                    )})`,
                }
            }
            if (!date.isSameOrBefore(this.maxDateDatePicker)) {
                this.data.error = {
                    status: 'INVALID_DATE',
                    label: `Date supérieure à la date maximum possible (${moment(this.maxDateDatePicker).format(
                        'DD/MM/YYYY'
                    )})`,
                }
            }
            this.onActionButtonEvent.emit(this.data)
        }
    }

    openDatepicker() {
        this.dp.nativeElement.click()
    }
}
