import { Component } from '@angular/core';
import {BreadcrumbsModule} from "@exalif/ngx-breadcrumbs";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [BreadcrumbsModule, RouterLink],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {

}
