import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private http: HttpClient) {
  }

  getVersionBack(): Observable<string> {
    const path = `${environment.baseUrl}/version`
    return this.http.get(path, { responseType: 'text' })
  }

}
