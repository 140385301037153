<div class="container position-relative portailrh-container-page">
  <div class="row mb-3">
    <div class="col-8 align-self-center">
      <h2>
        Mes collaborateurs actuels
        <ng-container *ngIf="!(collaborateursLoading$ | async)">
          <ng-container *ngIf="collabsNumber === 0">
            (aucun actuellement)
          </ng-container>
          <ng-container *ngIf="collabsNumber === 1">
            (1 personne)
          </ng-container>
          <ng-container *ngIf="collabsNumber > 1">
            ({{ collabsNumber }} personnes)
          </ng-container>
          :
        </ng-container>
      </h2>
    </div>
    <div class="col-4 text-end align-self-center">
      <div class="row">
        <div class="col-12 mobile-padding-0">
          <button
            (click)="showModalAddCollaborateurs()"
            *ngIf="showValidateButton"
            class="btn btn-success btn-validate blink-success pastille me-2"
            type="button"
          >
            <img src="assets/images/floppy-disk.svg" />
            <span class="d-none d-md-inline"> Valider</span>
          </button>

          <button
            (click)="addNewCollaborateur()"
            class="btn btn-info pastille"
            type="button"
          >
            <i class="bi bi-person-plus-fill"></i>
            <span class="d-none d-md-inline"> Ajouter</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col">
      <div class="container-table">
        <app-data-table
          (rowActionEvent)="clickOnRemoveCollaborateur($event)"
          (sortEvent)="sortDataOn($event)"
          [data$]="monEquipeActuelleSorted$"
          [scrollTo]="matriculeToScrollTo"
          [tableLoading$]="collaborateursLoading$"
          [tableOptions]="tableOptionsMonEquipe"
          [tableSortInit]="monEquipeSortingParams$ | async"
        >
        </app-data-table>
      </div>
    </div>
  </div>

  <ng-container *ngIf="monEquipeAVenir$">
    <div class="row mb-3">
      <div class="col-12">
        <h2>Mouvements à venir:</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container-table">
          <app-data-table
            (rowActionEvent)="clickOnRemoveCollaborateur($event)"
            [data$]="monEquipeAVenir$"
            [scrollTo]="matriculeToScrollTo"
            [tableLoading$]="collaborateursLoading$"
            [tableOptions]="tableOptionsAVenir"
          >
          </app-data-table>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Bouton valider en bas si nécessaire.-->
  <div class="row mb-5">
    <div class="col-8"></div>
    <div class="col-4 text-end align-self-center">
      <div class="row">
        <div class="col-12">
          <button
            (click)="showModalAddCollaborateurs()"
            *ngIf="showValidateButton"
            class="btn btn-validate blink-success btn-success pastille"
            type="button"
          >
            <img src="assets/images/floppy-disk.svg" />
            <span class="d-none d-md-inline"> Valider</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modale de confirmation de suppression de collaborateur -->
  <app-custom-modal
    (modalStatusBack)="confirmRemoveModalIsVisible = false"
    [modalIsVisible]="confirmRemoveModalIsVisible"
    [modalTitle]="modalTitleRemove"
    modalSize="modal-xs"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>
            Souhaitez-vous retirer le collaborateur "{{ nomCollaborateur }}
            {{ prenomCollaborateur }}" de votre sélection ?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-start">
          <button
            (click)="confirmRemoveModalIsVisible = false"
            class="btn btn-outline-danger"
            type="button"
          >
            Annuler
          </button>
        </div>
        <div class="col-6 text-end">
          <button
            (click)="removeCollab(this.collabMatriculeToRemove)"
            class="btn btn-success"
            type="button"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </app-custom-modal>

  <!-- Modale de confirmation de validation définitive d'ajout de collaborateur -->
  <app-custom-modal
    (modalStatusBack)="confirmAddModalIsVisible = false"
    [modalIsVisible]="confirmAddModalIsVisible"
    [modalTitle]="modalTitleAdd"
    modalSize="modal-xs"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="collabsSelectedName?.length">
            <ul>
              <li *ngFor="let collab of collabsSelectedName">
                {{ collab }}
              </li>
            </ul>
          </ng-container>
        </div>
        <div class="col-12">
          <p class="alert alert-info text-center" role="info">
            <i class="bi bi-exclamation-triangle"></i> Cette opération n'est pas
            réversible.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-start">
          <button
            (click)="confirmAddModalIsVisible = false"
            class="btn btn-outline-danger"
            type="button"
          >
            Annuler
          </button>
        </div>
        <div class="col-6 text-end">
          <button
            (click)="validateAddCollaborateurs()"
            class="btn btn-success"
            type="button"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </app-custom-modal>
</div>
