import { Component } from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {BreadcrumbsModule} from "@exalif/ngx-breadcrumbs";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-user-not-authenticated',
    standalone: true,
    imports: [BreadcrumbsModule, RouterLink],
    templateUrl: './user-not-authenticated.component.html',
    styleUrl: './user-not-authenticated.component.scss'
})
export class UserNotAuthenticatedComponent {
    constructor(public oAuthService: OAuthService) {}

    logout() {
        this.oAuthService.logOut({
            client_id: this.oAuthService.clientId,
            redirect_uri: this.oAuthService.redirectUri,
            response_type: 'code',
        })
    }

}
