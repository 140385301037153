<div id="footer-secondaire-lg">
    <div class="d-flex flex-column align-items-center">
        <div class="my-3 footer-secondaire-title">
            MYTEAM
        </div>
        <div class="my-1 text-center version-color">
            Version {{version}} - © Léon Grosse - 2024
        </div>
        <div class="my-1 text-center version-color">
           Version back {{versionBack}}
        </div>
    </div>
</div>
