import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core'
import * as moment from 'moment/moment'
import {TypeToast} from 'src/app/shared/ui/toast/state/toast.state'
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {DelegationService} from 'src/app/shared/data-access/http/delegation.service'
import {CsvService} from 'src/app/shared/data-access/http/csv.service'
import {ToastrService} from 'ngx-toastr'
import {saveAs} from 'file-saver'
import {DelegationDto} from 'src/app/shared/models/delegationDto'
import {DatePipe, NgClass, NgFor, NgIf} from '@angular/common'
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs'
import {DataImmuablesDomainService} from "../../../core/domain/data-immuables.domain";
import {ToastDomainService} from "../../../shared/ui/toast/domain/toast.domain";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

@Component({
    selector: 'app-exporter-delegations',
    templateUrl: './exporter-delegations.component.html',
    styleUrls: ['./exporter-delegations.component.scss'],
    standalone: true,
    imports: [
        BreadcrumbsModule,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgIf,
        NgFor,
        DatePipe,
        BsDatepickerModule,
    ],
})
export class ExporterDelegationsComponent implements OnInit {

    @ViewChild('dp', { static: false }) dp!: ElementRef

    public dateDelegationForm: FormGroup = this.fb.group({
        dateDelegation: [null, Validators.required],
    })
    public dateDelegationFormIsSubmitting: boolean = false
    public exportIsSubmitting: boolean = false

    dateDelegation: string | undefined
    delegations: DelegationDto[] = []
    maxManagersLength: number = 0
    additionalColumns: number[] = []
    private dataImmuablesDomain = inject(DataImmuablesDomainService)
    private toastDomainService = inject(ToastDomainService)

    public startManagerCalendar = this.getStartManagerCalendar()
    public endManagerCalendar = this.getEndManagerCalendar()

    constructor(
        private fb: FormBuilder,
        private delegationService: DelegationService,
        private csvService: CsvService,
        private toastr: ToastrService
    ) {
        this.dataImmuablesDomain.setComite().subscribe()
    }

    ngOnInit(): void {
    }

    openDatepicker() {
        this.dp.nativeElement.click()
    }

    validerDateDelegation(): void {
        this.dateDelegationFormIsSubmitting = true
        this.dateDelegation = moment(this.dateDelegationForm.get('dateDelegation')?.value).format('YYYY-MM-DD')

        this.delegationService.listerDelegations(this.dateDelegation).subscribe((listerDelegationResponse) => {
            this.delegations = listerDelegationResponse
            this.maxManagersLength = Math.max(
                ...this.delegations.map((delegation) =>
                    delegation.managersHierarchique ? delegation.managersHierarchique.length : 0
                )
            )
            this.additionalColumns = Array.from({length: this.maxManagersLength}, (_, i) => i + 1)

            const dateDelegationToaster = moment(this.dateDelegationForm.get('dateDelegation')?.value).format(
                'DD/MM/YYYY'
            )
            this.dateDelegationFormIsSubmitting = false
            this.toastDomainService.addToastMessage(
                TypeToast.success,
                'Affichage Délégation',
                `L'affichage des délégations en date du ${dateDelegationToaster} a été effectué`
            )
        })
    }

    exporterCSV(): void {
        this.exportIsSubmitting = true
        if (this.dateDelegation !== undefined) {
            this.delegationService.getCsv(this.dateDelegation).subscribe({
                next: (response) => {
                    if (response && response.headers) {
                        const filename = 'export-delegations_' + this.dateDelegation + '.csv'
                        const body: Blob | null = response.body
                        if (body != null) {
                            saveAs(body, filename)
                            this.toastr.success('Délégations Exportées au format CSV')
                        } else {
                            this.toastr.error("Erreur lors de l'export CSV des délégations")
                        }
                        this.exportIsSubmitting = false
                    }
                },
                error: () => {
                    this.toastr.error("Erreur lors de l'export CSV des délégations")
                    this.exportIsSubmitting = false
                },
            })
        }
    }

    private getStartManagerCalendar(): Date {
        const today = new Date()
        return new Date(today.getFullYear() - 3, today.getMonth(), 1)
    }

    private getEndManagerCalendar(): Date {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth() + 2, 0)
    }

}
