import {environment} from 'src/environments/environment'
import {filter, takeUntil, tap} from 'rxjs/operators'
import {Observable, Subject} from 'rxjs'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'
import {AsyncPipe, NgIf, SlicePipe} from '@angular/common'
import {UserStateModel} from "../../data-access/state/user.state";
import {UserDomainService} from "../../domain/user.domain";

@Component({
    selector: 'app-header-user',
    templateUrl: './header-user.component.html',
    styleUrls: ['./header-user.component.scss'],
    standalone: true,
    imports: [NgIf, BsDropdownModule, NgxSpinnerComponent, AsyncPipe, SlicePipe],
})
export class HeaderUserComponent implements OnInit, OnDestroy {
    public idSpinnerHeaderUser = 'idSpinnerHeaderUser'
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private unsubscribe$ = new Subject<void>()

    constructor(private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        this.spinner.show(this.idSpinnerHeaderUser, environment.ngxSpinnerOptions)
        this.user$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((user: UserStateModel) => user.loading === false),
                tap((_) => this.spinner.hide(this.idSpinnerHeaderUser))
            )
            .subscribe()
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }
}
