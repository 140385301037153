import { NgModule } from '@angular/core'
import { ListComponent } from './list.component'
import { CommonModule } from '@angular/common'

import { CustomModalModule } from '../custom-modal/custom-modal.module'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'

@NgModule({
    imports: [CommonModule, CustomModalModule, NgSelectModule, FormsModule, ReactiveFormsModule, ListComponent],
    exports: [ListComponent],
})
export class ListModule {}
