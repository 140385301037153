import {Routes} from '@angular/router'
import {AppLayoutComponent} from "./core/feature/app-layout/app-layout.component";
import {NotFoundComponent} from "./core/feature/not-found/not-found.component";
import {UserNotAuthenticatedComponent} from "./core/feature/user-not-authenticated/user-not-authenticated.component";

export const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: ''                      , redirectTo: '/mon-equipe', pathMatch: "full"},
            { path: 'mon-equipe',
                loadChildren: () => import('./mon-equipe/feature/equipe.routes').then((m) => m.routes),
            },
            { path: 'managers',
                loadChildren: () => import('./manager/feature/manager.routes').then((m) => m.routes),
            },
            { path: 'valideurs',
                loadChildren: () => import('./valideurs/feature/valideurs.routes').then((m) => m.valideursRoutes),
            },
            {path: 'organigramme',
                loadChildren: () => import('./organigramme/feature/organigramme.routes').then((m) => m.routes),
            },
            { path: 'comites',
                loadChildren: () => import('./comites/feature/comites.routes').then((m) => m.routes),
            },
            { path: 'not-found'             , component: NotFoundComponent},
            { path: 'user-not-authenticated', component: UserNotAuthenticatedComponent },
            { path: '**'                    , redirectTo: 'not-found'},
        ]
    }
]
