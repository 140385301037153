import {OAuthService} from 'angular-oauth2-oidc'
import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import * as moment from 'moment'
import {SortOrSearchObject} from 'src/app/mon-equipe/data-access/state/collaborateurs.state'
import {CollaborateurGlobalDto} from 'src/app/shared/models/collaborateursDto'

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private http: HttpClient, private oauthService: OAuthService) {
    }

    /**
     * Merge 2 arrays of object on property p
     * @param a : array of objects A (some items could be overwritten by array B)
     * @param b : array of objects B
     * @param p : field name on which filter is applied
     * @returns array merged
     */
    public mergeArrayOfObjects = (a: Array<any>, b: Array<any>, p: string) =>
        a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b)

    /**
     * Is item of dataFormatted typeof Object ?
     * (if yes : it is an action)
     */
    public isObject(val: any): boolean {
        return typeof val === 'object' && val !== null
    }

    /**
     * Sort a list of objects :
     * @param list of objects
     * @param sortField name of key. Date is accepted but should be YYYY-MM-DD
     * @param sortValue ASC | DESC
     * Option for secondary param :
     * @param sortField2 name of secondary key.No date accepted
     * @param sortValue2 ASC | DESC
     **/
    public sortList(
        list: Array<any> | undefined,
        sortField: string,
        sortValue: string,
        sortField2?: string,
        sortValue2?: string
    ) {
        // check if list is not empty

        if (!list || list.length === 0) {
            return undefined
        }
        // check if key (sortfield) exists
        if (typeof list[0] !== 'object' || list[0] === null) {
            return list
        }
        if (!!sortField !== true || !(sortField in list[0])) {
            return list
        }
        let newList: any
        if (sortValue.toLowerCase() === 'desc') {
            newList = list.slice().sort((a, b) => {
                if (!moment(a[sortField], 'DD/MM/YYYY').isValid()) {
                    if (a[sortField] === b[sortField] && !!sortField2) {
                        if (sortValue2?.toLowerCase() === 'asc') {
                            return a[sortField2] > b[sortField2] ? -1 : 1
                        } else {
                            return a[sortField2] < b[sortField2] ? -1 : 1
                        }
                    }
                    return a[sortField] < b[sortField] ? -1 : 1
                } else {
                    return moment(a[sortField], 'DD/MM/YYYY') < moment(b[sortField], 'DD/MM/YYYY') ? -1 : 1
                }
            })
        }

        if (sortValue.toLowerCase() === 'asc') {
            newList = list.slice().sort((a, b) => {
                if (!moment(a[sortField], 'DD/MM/YYYY', true).isValid()) {
                    if (a[sortField] === b[sortField] && !!sortField2) {
                        if (sortValue2?.toLowerCase() === 'asc') {
                            return a[sortField2] > b[sortField2] ? -1 : 1
                        } else {
                            return a[sortField2] < b[sortField2] ? -1 : 1
                        }
                    }
                    return a[sortField] > b[sortField] ? -1 : 1
                } else {
                    return moment(a[sortField], 'DD/MM/YYYY') > moment(b[sortField], 'DD/MM/YYYY') ? -1 : 1
                }
            })
        }
        return newList
    }

    /**
     * Search in list of objects : on a field / a value
     * => search case insensitive
     * => accents insensitive (accents are removed before searching)
     * @param : list : list of objects
     * @param : searchField : SortOrSearchObject
     **/
    public searchInList(
        list: Array<any> | undefined,
        searchField: SortOrSearchObject
    ): Array<CollaborateurGlobalDto> | undefined {
        if (!list || list.length === 0) {
            console.warn('[ERROR][searchInList in Service common.service] : empty list')
            return undefined
        }
        if (!searchField) {
            return list
        }
        const result = list.filter((obj) => {
            // Si code entité affectation recherché, on cherche sur la valeur exacte.
            if (searchField.field === 'codeEntiteAffectation') {
                return obj[searchField.field] === searchField.value
            } else {
                return (
                    obj[searchField.field]
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                            searchField.value
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                        ) !== -1
                )
            }
        })

        return result
    }
}
